import React from "react";
import { Form } from "react-bootstrap";

const OfferNaming = ({ values, errors, touched, handleChange, handleBlur }) => {
  const cc = require("currency-codes");
  const currencies = cc.data;
  return (
    <React.Fragment>
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.name && errors.name)}
        />
        {!!touched.name && (
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Currency</Form.Label>
        <Form.Select
          name="currency"
          value={values.currency}
          onBlur={handleBlur}
          onChange={handleChange}
          isInvalid={Boolean(touched.currency && errors.currency)}
        >
          <option value="">Select currency</option>
          {currencies.map((currencyObj) => (
            <option key={currencyObj.code} value={currencyObj.code}>
              {currencyObj.code} - {currencyObj.currency}
            </option>
          ))}
        </Form.Select>
        {!!touched.currency && (
          <Form.Control.Feedback type="invalid">
            {errors.currency}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Payment Type</Form.Label>
        <Form.Select
          name="payment_type"
          value={values.payment_type}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.payment_type && errors.payment_type)}
        >
          <option value="">Select Payment Type</option>
          <option value="Prepaid">Prepaid</option>
          <option value="Postpaid">Postpaid</option>
        </Form.Select>
      </Form.Group>
    </React.Fragment>
  );
};

export default OfferNaming;
