import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Accordion, Button, Container, Form } from "react-bootstrap";
import { Row, Col, Table } from "react-bootstrap";
import OffersList from "./OffersTable";
import { useTranslation } from "react-i18next";
import {
  getAvailableOffers,
  getCatalogOffers,
  getOffers,
} from "../../redux/slices/offers";
import Loader from "../../components/spinners/Loader";
import NotyfContext from "../../contexts/NotyfContext";
import useWindowSize from "../../hooks/useWindowSize";
import OfferForm from "./OfferForm";
import { useWhitelabel } from "../../contexts/WhitelabelContext";
import { FaPlus } from "react-icons/fa";

const Offers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const offers = useSelector((state) => state.offers.catalogOffers);
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [isLoading, setIsLoading] = useState(true);
  const notyf = useContext(NotyfContext);
  const [activeUser, setActiveUser] = useState({});
  const [showTerminated, setShowTerminated] = useState(false);
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  const [showNewOfferModal, setShowNewOfferModal] = useState(false);
  const { backgroundColor, fontColor } = useWhitelabel();

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchOffers = async () => {
      await dispatch(getCatalogOffers({ showTerminated: showTerminated }))
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (activeUser.id && activeUser.role === "admin") {
      fetchOffers();
    }
  }, [dispatch, activeUser, showTerminated]);

  if (isLoading) {
    return <Loader />;
  } else {
    if (offers.length > 0) {
      return (
        <React.Fragment>
          <Helmet title="Offers" />
          <Container fluid className="p-0">
            <h1 className="h3 mb-3">Offers</h1>
            <Row>
              <Col md="12">
                <OffersList
                  data={offers}
                  showTerminated={showTerminated}
                  setShowTerminated={setShowTerminated}
                />
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Helmet title="Offers" />
          <Container fluid className="p-0">
            <h1 className="h3 mb-3">{t(`Offers`)}</h1>
            {isDesktop && (
              <div className="mb-3 d-flex justify-content-end align-items-center">
                <Form.Check
                  type="checkbox"
                  className="me-3"
                  checked={showTerminated}
                  label="Show Terminated?"
                  onChange={(e) => setShowTerminated(e.target.checked)}
                  style={{
                    "--dynamic-bg-color": backgroundColor ?? "#00acdc",
                    "--dynamic-font-color": fontColor ?? "#fff",
                    border: "none",
                  }}
                />
                <Button
                  variant="primary"
                  style={{
                    border: "none",
                    "--dynamic-bg-color": backgroundColor,
                    "--dynamic-font-color": fontColor,
                  }}
                  onClick={() => setShowNewOfferModal(true)}
                >
                  <FaPlus />
                  <span className="ms-2">New Offer</span>
                </Button>
              </div>
            )}
            {!isDesktop && (
              <div className="d-flex justify-content-center flex-column mb-3">
                <Button
                  variant="primary"
                  style={{
                    border: "none",
                    "--dynamic-bg-color": backgroundColor,
                    "--dynamic-font-color": fontColor,
                    width: "100%",
                    padding: "10px",
                  }}
                  onClick={() => setShowNewOfferModal(true)}
                >
                  <FaPlus />
                  <span className="ms-2">New Offer</span>
                </Button>
                <Accordion className="filters-accordion">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Filters</Accordion.Header>
                    <Accordion.Body>
                      <Form.Group className="d-flex justify-content-between align-items-end">
                        <Form.Label className="mb-0">
                          Show Terminated?
                        </Form.Label>
                        <Form.Check
                          type="switch"
                          className="me-3"
                          checked={showTerminated}
                          onChange={(e) => setShowTerminated(e.target.checked)}
                          style={{
                            "--dynamic-bg-color": backgroundColor ?? "#00acdc",
                            "--dynamic-font-color": fontColor ?? "#fff",
                            border: "none",
                          }}
                        />
                      </Form.Group>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            )}
            <Row>
              <Col md="12">
                <Table>
                  <thead>
                    <tr>
                      <th>{t("No data found.")}</th>
                    </tr>
                  </thead>
                </Table>
                <OfferForm
                  showNewOffer={showNewOfferModal}
                  setShowNewOffer={setShowNewOfferModal}
                />
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  }
};

export default Offers;
