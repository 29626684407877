import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";
import countryList from "react-select-country-list";

const initialState = {
  customers: [],
};

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setCustomers(state, action) {
      state.customers = action.payload;
    },
    clearCustomers(state, action) {
      state.customers = [];
    },
    updateCustomer(state, action) {
      const { id, customerDetails } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return customerDetails;
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    removeCustomer(state, action) {
      const id = action.payload;
      const newCustomers = state.customers.filter(
        (customer) => customer.id !== id
      );
      return {
        ...state,
        customers: newCustomers,
      };
    },
    updateFullName(state, action) {
      const { id, fullName } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            fullName: fullName,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateAddress(state, action) {
      const { id, address } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            address: address,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateCity(state, action) {
      const { id, city } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            city: city,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateCountry(state, action) {
      const { id, country } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            country: country,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updatePostcode(state, action) {
      const { id, postcode } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            postcode: postcode,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateTelephone(state, action) {
      const { id, telephone } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            telephone: telephone,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateMobile(state, action) {
      const { id, mobile } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            mobileNumber: mobile,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateEmail(state, action) {
      const { id, email } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            email: email,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateOccupation(state, action) {
      const { id, occupation } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            occupation: occupation,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateCompany(state, action) {
      const { id, company } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            company: company,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateTIN(state, action) {
      const { id, tin } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            tinNumber: tin,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updateOffers(state, action) {
      const { id, offers } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            offers: offers,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    updatePrivilege(state, action) {
      const { id, privilege } = action.payload;
      const updatedCustomers = state.customers.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            privilege: privilege,
          };
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedCustomers,
      };
    },
    addCustomer: (state, action) => {
      const newCustomer = action.payload;
      return {
        ...state,
        customers: [...state.customers, newCustomer],
      };
    },
    removeCustomer: (state, action) => {
      const idToRemove = action.payload;
      const newCustomers = state.customers.filter(
        (customer) => customer.id !== idToRemove
      );
      return {
        ...state,
        customers: newCustomers,
      };
    },
  },
});

export const clearAllCustomers = () => (dispatch) => {
  dispatch(customersSlice.actions.clearCustomers());
};

export function getCustomers() {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/customers/`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(customersSlice.actions.setCustomers(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      dispatch(customersSlice.actions.setCustomers([]));
      return Promise.reject(error.message);
    }
  };
}

export function updateCustomerDetails({ id, values }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/customers/${id}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        customersSlice.actions.updateCustomer({
          id,
          customerDetails: response.data.data,
        })
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function deleteCustomer(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/customers/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(customersSlice.actions.removeCustomer(id));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function updateCustomerName(id, fullName) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("fullName", fullName);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/fullName",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateFullName({ id, fullName }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function updateCustomerAddress(id, address) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("address", address);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/address",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateAddress({ id, address }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function updateCustomerCity(id, city) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("city", city);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/city",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateCity({ id, city }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function updateCustomerCountry(id, country) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("country", country);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/country",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateCountry({ id, country }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function updateCustomerPostcode(id, postcode) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("postcode", postcode);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/postcode",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updatePostcode({ id, postcode }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function updateCustomerTelephone(id, telephone) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("telephone", telephone);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/telephone",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateTelephone({ id, telephone }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function updateCustomerMobile(id, mobile) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("mobile", mobile);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/mobile",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateMobile({ id, mobile }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function updateCustomerEmail(id, email) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("email", email);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/email",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateEmail({ id, email }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function updateCustomerOccupation(id, occupation) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("occupation", occupation);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/occupation",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateOccupation({ id, occupation }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function updateCustomerCompany(id, company) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("company", company);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/company",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateCompany({ id, company }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function updateCustomerTIN(id, tin) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("tin", tin);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          id +
          "/update/tin",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updateTIN({ id, tin }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function updateCustomerOffers(id, offers) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("offers", offers);
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/customers/${id}/update/offers`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(customersSlice.actions.updateOffers({ id, offers }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function updateCustomerPrivilege(
  userId,
  clientId,
  privilege,
  upperEntityId
) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("entityId", clientId);
    formData.append("privilege", privilege);
    if (privilege !== "ADMIN" && privilege !== "VIEWER") {
      formData.append("upperEntityId", upperEntityId);
    } else {
      formData.append("upperEntityId", -1);
    }
    try {
      await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/systemusers/` +
          userId +
          "/update/privilege",
        formData,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(customersSlice.actions.updatePrivilege({ clientId, privilege }));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export function addNewCustomer({ values }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      console.log(values);
      const data = {
        fullName: values.fullName,
        address: values.address,
        city: values.city,
        country: values.country,
        postcode: values.postcode,
        telephoneNumber: values.telephoneNumber,
        mobileNumber: values.mobileNumber,
        email: values.email,
        occupation: values.occupation,
        company: values.company,
        tin: values.tin,
        username: values.username,
        portfolioId: values.portfolioId,
        minimumAmountToInvoice: values.minimumAmountToInvoice,
        invoiceType: values.invoiceType,
        invoiceHandlerType: values.invoiceHandlerType,
        currency: values.currency,
        invoiceConfiguration: values.invoiceConfiguration,
        preferredLanguage: values.preferredLanguage,
        billingEmail: values.billingEmail,
        sendWelcomeMail: values.sendWelcomeMail,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/customers/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(customersSlice.actions.addCustomer(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function removeExistingCustomer(id) {
  return (dispatch) => {
    dispatch(customersSlice.actions.removeCustomer(id));
  };
}

export const {
  setCustomers,
  clearCustomers,
  updateFullName,
  updateAddress,
  updateCity,
  updateCountry,
  updatePostcode,
  updateTelephone,
  updateEmail,
  updateOccupation,
  updateCompany,
  updateTIN,
  updatePrivilege,
  addCustomer,
  removeCustomer,
  updateCustomer,
} = customersSlice.actions;
export default customersSlice.reducer;
