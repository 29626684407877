import React, { useContext, useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { User } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../contexts/NotyfContext";
import { getOfferItems } from "../../redux/slices/offers";

const OfferItems = () => {
  const [type, setType] = useState("");
  const user = useSelector((state) => state.user.user);
  const impersonationUser = useSelector(
    (state) => state.impersonationUser.impersonationUser
  );
  const [activeUser, setActiveUser] = useState({});
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchOfferItems = async () => {
      await dispatch(getOfferItems({ type }))
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
    };

    if (activeUser.id && type) {
      fetchOfferItems();
    }
  }, [activeUser, type]);
  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Offer Items</h1>
        <Form.Group className="mb-3">
          <Form.Label>Select Type</Form.Label>
          <Form.Select
            name="type"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="">Select Offer Item Type</option>
            <option value="Bucket">Buckets</option>
            <option value="Subscription">Subscription</option>
          </Form.Select>
        </Form.Group>
      </Container>
    </React.Fragment>
  );
};

export default OfferItems;
