import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";
import { updateTenant } from "./tenants";
import { updateManager } from "./managers";
import { updateCustomer } from "./customers";

const initialState = {
  _1nceConfig: [],
};

const _1nceConfigSlice = createSlice({
  name: "_1nceConfig",
  initialState,
  reducers: {
    set1nceConfig(state, action) {
      state._1nceConfig = action.payload;
    },
  },
});

export function get1nceConfig({ user_id }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/1nce/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(_1nceConfigSlice.actions.set1nceConfig(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function store1nceConfig({ data, role }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/1nce`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (role === "TENANT") {
        dispatch(
          updateTenant({ id: data.id, tenantDetails: response.data.data })
        );
      } else if (role === "MANAGER") {
        dispatch(
          updateManager({
            id: data.id,
            managerDetails: response.data.data,
          })
        );
      } else if (role === "CUSTOMER") {
        dispatch(
          updateCustomer({ id: data.id, customerDetails: response.data.data })
        );
      }
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function update1nceConfig({ user_id, data, role }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/1nce/${user_id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (role === "TENANT") {
        dispatch(
          updateTenant({ id: user_id, tenantDetails: response.data.data })
        );
      } else if (role === "MANAGER") {
        dispatch(
          updateManager({
            id: user_id,
            managerDetails: response.data.data,
          })
        );
      } else if (role === "CUSTOMER") {
        dispatch(
          updateCustomer({ id: user_id, customerDetails: response.data.data })
        );
      }
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export default _1nceConfigSlice.reducer;
