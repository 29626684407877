import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import useWindowSize from "../../../hooks/useWindowSize";

const FilterGroup = ({ column, filters, setFilters, page, pageSize }) => {
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  if (!column.canRenderFilter) {
    return null;
  }

  const handleChange = (filterName, e) => {
    const { value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  if (!column.canRenderFilter) {
    return null;
  }

  let filterComponent = null;

  if (column.filterType === "text" || column.filterType === "number") {
    // Custom text filter component
    filterComponent = (
      <Form.Control
        value={filters[column.id] || ""}
        onChange={(e) => handleChange(column.id, e)}
        placeholder={`${column.render("Header")}`}
      />
    );
  } else if (column.filterType === "select") {
    if (column.id === "state") {
      filterComponent = (
        <Form.Select
          value={filters.state || ""}
          onChange={(e) => handleChange("state", e)}
        >
          {/* Render select options based on your data */}
          <option value="">All</option>
          <option value="Productive">Productive</option>
          <option value="OnStock">On Stock</option>
          <option value="Ordered">Ordered</option>
          <option value="Test">Test</option>
          <option value="TestProductive">Test Productive</option>
          <option value="TestEnd">Test End</option>
          <option value="Suspended">Suspended</option>
          <option value="AutoSuspended">Auto Suspended</option>
          <option value="Deleted">Deleted</option>
          <option value="Paused">Paused</option>
          <option value="Lost">Lost</option>
          <option value="Reserved">Reserved</option>
          <option value="Deleting">Deleting</option>
          <option value="EuiccControl">EuiccControl</option>
          <option value="Passive">Passive</option>
          <option value="Migrate">Migrate</option>
        </Form.Select>
      );
    } else if (column.id === "sim_type") {
      filterComponent = (
        <Form.Select
          value={filters.sim_type || ""}
          onChange={(e) => {
            handleChange("sim_type", e);
            if (
              e.target.value === "NextM2M SIM T2" ||
              e.target.value === "Jersey Embedded Sim"
            ) {
              setFilters((prevFilters) => ({
                ...prevFilters,
                sku: "JerseyDirect",
              }));
            } else if (e.target.value === "NextM2M SIM T3") {
              setFilters((prevFilters) => ({
                ...prevFilters,
                sku: "Hi3g",
              }));
            } else {
              setFilters((prevFilters) => ({
                ...prevFilters,
                sku: "1NCE",
              }));
            }
          }}
        >
          <option value="">All</option>
          <option value="NextM2M SIM T2">T2 SIM Card</option>
          <option value="Jersey Embedded Sim">Embedded SIM Card</option>
          <option value="NextM2M SIM T3">T3 SIM Card</option>
          <option value="IoT SIM Card Industrial">
            IoT SIM Card Industrial
          </option>
          <option value="IoT SIM Chip Industrial">
            IoT SIM Chip Industrial
          </option>
        </Form.Select>
      );
    }
  } else if (column.filterType === "between") {
    if (isDesktop) {
      filterComponent = (
        <Row>
          <Form.Group as={Col} xs="12" lg="6">
            <Form.Control
              value={filters.minData || ""}
              onChange={(e) => handleChange("minData", e)}
              placeholder="Min"
            />
          </Form.Group>
          <Form.Group as={Col} xs="12" lg="6">
            <Form.Control
              value={filters.maxData || ""}
              onChange={(e) => handleChange("maxData", e)}
              placeholder="Max"
            />
          </Form.Group>
        </Row>
      );
    } else {
      filterComponent = (
        <Row>
          <Form.Group as={Col} xs="12" lg="6" className="mb-3">
            <Form.Control
              value={filters.minData || ""}
              onChange={(e) => handleChange("minData", e)}
              placeholder="Min"
            />
          </Form.Group>
          <Form.Group as={Col} xs="12" lg="6">
            <Form.Control
              value={filters.maxData || ""}
              onChange={(e) => handleChange("maxData", e)}
              placeholder="Max"
            />
          </Form.Group>
        </Row>
      );
    }
  }
  // Add more conditions for other filter types

  return (
    <>
      <Col md={3}>
        <Form.Group className="mb-3">
          <Form.Label>{column.render("Header")}</Form.Label>
          {filterComponent}
        </Form.Group>
      </Col>
    </>
  );
};

export default FilterGroup;
