import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "../../../hooks/useWindowSize";
import NotyfContext from "../../../contexts/NotyfContext";
import { useWhitelabel } from "../../../contexts/WhitelabelContext";
import * as Yup from "yup";
import { Button, Col, Collapse, Form, Modal, Row } from "react-bootstrap";
import { Formik } from "formik";
import {
  deallocateSims,
  findSimsByFile,
  findSimsByRange,
} from "../../../redux/slices/sims";

const DeallocateSimsModal = ({
  showDeallocateSimsModal,
  setShowDeallocateSimsModal,
  clientToEdit,
}) => {
  const user = useSelector((state) => state.user.user);
  const impersonationUser = useSelector(
    (state) => state.impersonationUser.impersonationUser
  );
  const [activeUser, setActiveUser] = useState({});
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  const [internalShow, setInternalShow] = useState(showDeallocateSimsModal);
  const [fadeOut, setFadeOut] = useState(false);
  const notyf = useContext(NotyfContext);
  const { backgroundColor, fontColor } = useWhitelabel();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    if (showDeallocateSimsModal && !isDesktop) {
      setFadeOut(false);
      setInternalShow(true);
    }
  }, [showDeallocateSimsModal]);

  const handleHide = () => {
    setFadeOut(true);
    setTimeout(() => {
      setInternalShow(false);
      setShowDeallocateSimsModal(false);
    }, 300);
  };

  const initialValues = {
    sim_selection: "",
    imsi: "",
    iccid: "",
    imsiRange: {
      minImsi: "",
      maxImsi: "",
    },
    iccidRange: {
      minIccid: "",
      maxIccid: "",
    },
    file: null,
    simList: [],
    price: 0,
    vat: 0,
  };

  const firstStepValidation = Yup.object().shape({
    sim_selection: Yup.string().required("Required"),
    imsi: Yup.string().when("sim_selection", {
      is: "single_imsi",
      then: (schema) =>
        schema
          .matches(/^\d{15}$/, "IMSI must be a 15-digit number")
          .required("IMSI is required when selecting single IMSI"),
      otherwise: (schema) => schema.notRequired(),
    }),
    iccid: Yup.string().when("sim_selection", {
      is: "single_iccid",
      then: (schema) =>
        schema
          .matches(/^\d{18,22}$/, "ICCID must be from 18 to 22 digits.")
          .required("ICCID is required when selecting single ICCID"),
      otherwise: (schema) => schema.notRequired(),
    }),
    imsiRange: Yup.object()
      .shape({
        minImsi: Yup.string().when("sim_selection", {
          is: "imsi_range",
          then: (schema) =>
            schema
              .matches(/^\d{15}$/, "IMSI must be a 15-digit number")
              .required("Min IMSI is required"),
        }),
        maxImsi: Yup.string().when("sim_selection", {
          is: "imsi_range",
          then: (schema) =>
            schema
              .matches(/^\d{15}$/, "IMSI must be a 15-digit number")
              .required("Max IMSI is required"),
        }),
      })
      .test(
        "min-less-than-max",
        "Min IMSI must be smaller than Max IMSI",
        function (values) {
          const { minImsi, maxImsi } = values;
          if (values.sim_selection !== "imsi_range") return true; // Skip validation if sim_selection is not imsi_range
          if (!minImsi || !maxImsi) return true; // Prevent errors if fields are empty (handled by required rule)
          return minImsi < maxImsi;
        }
      ),
    iccidRange: Yup.object()
      .shape({
        minIccid: Yup.string().when("sim_selection", {
          is: "iccid_range",
          then: (schema) =>
            schema
              .matches(/^\d{18,22}$/, "ICCID must be from 18 to 22 digits.")
              .required("Min ICCID is required"),
        }),
        maxImsi: Yup.string().when("sim_selection", {
          is: "iccid_range",
          then: (schema) =>
            schema
              .matches(/^\d{18,22}$/, "ICCID must be from 18 to 22 digits.")
              .required("Max ICCID is required"),
        }),
      })
      .test(
        "min-less-than-max",
        "Min ICCID must be smaller than Max ICCID",
        function (values) {
          const { minIccid, maxIccid } = values;
          if (values.sim_selection !== "iccid_range") return true; // Skip validation if sim_selection is not imsi_range
          if (!minIccid || !maxIccid) return true; // Prevent errors if fields are empty (handled by required rule)
          return Number(minIccid) < Number(maxIccid);
        }
      ),
    simList: Yup.array().min(1, "Must have at least 1 valid SIM card."),
  });

  const fetchSimsByFile = async ({ file, setFieldValue, setFieldError }) => {
    await findSimsByFile({ file, id: clientToEdit.id })
      .then((response) => {
        setFieldValue("simList", response);
      })
      .catch((error) => {
        setFieldError("file", error);
      });
  };

  const fetchSimsByRange = async ({
    field,
    minImsi = null,
    maxImsi = null,
    minIccid = null,
    maxIccid = null,
    setFieldValue,
    setFieldError,
    id = null,
  }) => {
    await findSimsByRange({ minImsi, maxImsi, minIccid, maxIccid, id })
      .then((response) => {
        setFieldValue("simList", response);
      })
      .catch((error) => {
        setFieldError(`${field}`, error);
      });
  };

  if (isDesktop) {
    return (
      <Modal
        show={showDeallocateSimsModal}
        onHide={() => {
          setShowDeallocateSimsModal(false);
          setCurrentStep(1);
        }}
        centered
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Deallocate Sims</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={firstStepValidation}
            onSubmit={async (values, { setValues }) => {
              await dispatch(deallocateSims({ sims: values.simList }))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Sims deallocated successfully",
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  setCurrentStep(1);
                  setValues(initialValues);
                  setShowDeallocateSimsModal(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldError,
              setValues,
              validateForm,
              setFieldTouched,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>From</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={clientToEdit.company}
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>To</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={
                          activeUser.role !== "admin"
                            ? activeUser.company
                            : "Click Sims S.A."
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Form.Group className="mb-3">
                    <Form.Label>SIM Selection Type</Form.Label>
                    <Form.Select
                      name="sim_selection"
                      value={values.sim_selection}
                      onChange={(e) => {
                        setValues(initialValues);
                        const value = e.target.value;
                        setFieldValue("sim_selection", "");
                        setTimeout(() => {
                          setFieldValue("sim_selection", value);
                        }, 300);
                      }}
                      onBlur={handleBlur}
                      isInvalid={Boolean(
                        touched.sim_selection && errors.sim_selection
                      )}
                    >
                      <option value="">Select SIM Selection Type</option>
                      <option value="single_imsi">Single IMSI</option>
                      <option value="single_iccid">Single ICCID</option>
                      <option value="imsi_range">IMSI Range</option>
                      <option value="iccid_range">ICCID Range</option>
                      <option value="file">CSV/XLSX File</option>
                    </Form.Select>
                  </Form.Group>
                  <Collapse
                    in={values.sim_selection === "single_imsi"}
                    dimension="height"
                  >
                    <div>
                      <Form.Group className="mb-3">
                        <Form.Label>IMSI</Form.Label>
                        <Form.Control
                          type="text"
                          name="imsi"
                          value={values.imsi}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={Boolean(touched.imsi && errors.imsi)}
                          isValid={values.simList.length > 0}
                        />
                        <Form.Control.Feedback type="valid">
                          {values.simList.length} sims found
                        </Form.Control.Feedback>
                        {!!touched.imsi && (
                          <Form.Control.Feedback type="invalid">
                            {errors.imsi}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <div className="d-flex justify-content-end my-3">
                        <Button
                          variant="primary"
                          style={{
                            "--dynamic-bg-color": backgroundColor,
                            "--dynamic-font-color": fontColor,
                            border: "none",
                          }}
                          onClick={async () => {
                            fetchSimsByRange({
                              minImsi: values.imsi,
                              maxImsi: values.imsi,
                              setFieldValue: setFieldValue,
                              setFieldError: setFieldError,
                              id: clientToEdit.id,
                            });
                          }}
                        >
                          Validate
                        </Button>
                      </div>
                    </div>
                  </Collapse>
                  <Collapse
                    in={values.sim_selection === "single_iccid"}
                    dimension="height"
                  >
                    <Form.Group className="mb-3">
                      <Form.Label>ICCID</Form.Label>
                      <Form.Control
                        type="text"
                        name="iccid"
                        value={values.iccid}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={Boolean(touched.iccid && errors.iccid)}
                        isValid={values.simList.length > 0}
                      />
                      <Form.Control.Feedback type="valid">
                        {values.simList.length} sims found.
                      </Form.Control.Feedback>
                      {!!touched.iccid && (
                        <Form.Control.Feedback type="invalid">
                          {errors.iccid}
                        </Form.Control.Feedback>
                      )}
                      <div className="d-flex justify-content-end my-3">
                        <Button
                          variant="primary"
                          style={{
                            "--dynamic-bg-color": backgroundColor,
                            "--dynamic-font-color": fontColor,
                            border: "none",
                          }}
                          onClick={async () => {
                            fetchSimsByRange({
                              minIccid: values.iccid,
                              maxIccid: values.iccid,
                              setFieldValue: setFieldValue,
                              setFieldError: setFieldError,
                              id: clientToEdit.id,
                            });
                          }}
                        >
                          Validate
                        </Button>
                      </div>
                    </Form.Group>
                  </Collapse>
                  <Collapse
                    in={values.sim_selection === "imsi_range"}
                    dimension="height"
                  >
                    <div>
                      <Row className="mb-3">
                        <Col>
                          <Form.Group>
                            <Form.Label>Start IMSI</Form.Label>
                            <Form.Control
                              type="text"
                              name="imsiRange.minImsi"
                              value={values.imsiRange.minImsi}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={Boolean(
                                touched?.imsiRange?.minImsi &&
                                  errors?.imsiRange?.minImsi
                              )}
                              isValid={values.simList.length > 0}
                            />
                            <Form.Control.Feedback type="valid">
                              {values.simList.length} sims found
                            </Form.Control.Feedback>
                            {!!touched?.imsiRange?.minImsi && (
                              <Form.Control.Feedback type="invalid">
                                {errors?.imsiRange?.minImsi}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>End IMSI</Form.Label>
                            <Form.Control
                              type="text"
                              name="imsiRange.maxImsi"
                              value={values.imsiRange.maxImsi}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={Boolean(
                                touched?.imsiRange?.maxImsi &&
                                  errors?.imsiRange?.maxImsi
                              )}
                              isValid={values.simList.length > 0}
                            />
                            {!!touched?.imsiRange?.maxImsi && (
                              <Form.Control.Feedback type="invalid">
                                {errors?.imsiRange?.maxImsi}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end my-3">
                        <Button
                          variant="primary"
                          style={{
                            "--dynamic-bg-color": backgroundColor,
                            "--dynamic-font-color": fontColor,
                            border: "none",
                          }}
                          onClick={async () => {
                            fetchSimsByRange({
                              minImsi: values.imsiRange.minImsi,
                              maxImsi: values.imsiRange.maxImsi,
                              setFieldValue: setFieldValue,
                              setFieldError: setFieldError,
                              id: clientToEdit.id,
                            });
                          }}
                        >
                          Validate
                        </Button>
                      </div>
                    </div>
                  </Collapse>
                  <Collapse
                    in={values.sim_selection === "iccid_range"}
                    dimension="height"
                  >
                    <div>
                      <Row className="mb-3">
                        <Col>
                          <Form.Group>
                            <Form.Label>Start ICCID</Form.Label>
                            <Form.Control
                              type="text"
                              name="iccidRange.minIccid"
                              value={values.iccidRange.minIccid}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={Boolean(
                                touched?.iccidRange?.minIccid &&
                                  errors?.iccidRange?.minIccid
                              )}
                              isValid={values.simList.length > 0}
                            />
                            <Form.Control.Feedback type="valid">
                              {values.simList.length} sims found
                            </Form.Control.Feedback>
                            {!!touched?.iccidRange?.minIccid && (
                              <Form.Control.Feedback type="invalid">
                                {errors?.iccidRange?.minIccid}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>End ICCID</Form.Label>
                            <Form.Control
                              type="text"
                              name="iccidRange.maxIccid"
                              value={values.iccidRange.maxIccid}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={Boolean(
                                touched?.iccidRange?.maxIccid &&
                                  errors?.iccidRange?.maxIccid
                              )}
                              isValid={values.simList.length > 0}
                            />
                            {!!touched?.iccidRange?.maxIccid && (
                              <Form.Control.Feedback type="invalid">
                                {errors?.iccidRange?.maxIccid}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end my-3">
                        <Button
                          variant="primary"
                          style={{
                            "--dynamic-bg-color": backgroundColor,
                            "--dynamic-font-color": fontColor,
                            border: "none",
                          }}
                          onClick={async () => {
                            fetchSimsByRange({
                              minIccid: values.iccidRange.minIccid,
                              maxIccid: values.iccidRange.maxIccid,
                              setFieldValue: setFieldValue,
                              setFieldError: setFieldError,
                              id: clientToEdit.id,
                            });
                          }}
                        >
                          Validate
                        </Button>
                      </div>
                    </div>
                  </Collapse>
                  <Collapse
                    in={values.sim_selection === "file"}
                    dimension="height"
                  >
                    <Form.Group className="mb-3">
                      <Form.Label>File Upload</Form.Label>
                      <Form.Control
                        key={Date.now()}
                        type="file"
                        size="lg"
                        className="sim-file-upload"
                        isInvalid={!!errors.file}
                        isValid={values.simList.length > 0}
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];

                          if (file) {
                            event.target.value = ""; // ✅ Reset the input value to trigger `onChange` again on the same file

                            setFieldValue("file", file);
                            fetchSimsByFile({
                              file,
                              setFieldValue,
                              setFieldError,
                            });
                          }
                        }}
                      />
                      <Form.Control.Feedback type="valid">
                        {values.simList.length} sims
                      </Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        {errors.file}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Collapse>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      style={{
                        "--dynamic-bg-color": backgroundColor,
                        "--dynamic-font-color": fontColor,
                        border: "none",
                      }}
                      onClick={() => {
                        setCurrentStep(1);
                        setValues(initialValues);
                        setShowDeallocateSimsModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      style={{
                        "--dynamic-bg-color": backgroundColor,
                        "--dynamic-font-color": fontColor,
                        border: "none",
                      }}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
};

export default DeallocateSimsModal;
