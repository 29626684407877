import React from "react";
import { matchPath } from "react-router-dom";

import SidebarNavListItem from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";

const filterChildrenByPrivilege = ({ title, children, privilege, user }) => {
  if (title === "sidebar.crm") {
    switch (privilege) {
      case "admin":
        return children;
      case "manager":
        return children.filter(
          (child) =>
            child.title !== "sidebar.admins" &&
            child.title !== "sidebar.managers" &&
            child.title !== "sidebar.tenants"
        );
      case "tenant":
        return children.filter(
          (child) =>
            child.title !== "sidebar.admins" &&
            child.title !== "sidebar.tenants"
        );
      case "customer":
        return children.filter(
          (child) =>
            child.title !== "sidebar.admins" &&
            child.title !== "sidebar.managers" &&
            child.title !== "sidebar.tenants" &&
            child.title !== "sidebar.customers"
        );
      case "subaccount":
        if (user.admin !== null) {
          return children;
        } else if (user.tenant !== null) {
          return children.filter(
            (child) =>
              child.title !== "sidebar.admins" &&
              child.title !== "sidebar.tenants"
          );
        } else if (user.manager !== null) {
          return children.filter(
            (child) =>
              child.title !== "sidebar.admins" &&
              child.title !== "sidebar.managers" &&
              child.title !== "sidebar.tenants"
          );
        } else if (user.customer !== null) {
          return children.filter(
            (child) =>
              child.title !== "sidebar.admins" &&
              child.title !== "sidebar.managers" &&
              child.title !== "sidebar.tenants" &&
              child.title !== "sidebar.customers"
          );
        }
      default:
        return [];
    }
  } else if (title === "sidebar.account") {
    switch (privilege) {
      case "admin":
        return children.filter(
          (child) =>
            child.title === "sidebar.profile" ||
            child.title === "sidebar.orders"
        );
      case "tenant":
        return children;
      case "manager":
        return children;
      case "customer":
        return children;
      case "subaccount":
        let accountChildren = [];

        if (user.admin !== null) {
          return children.filter(
            (child) =>
              child.title === "sidebar.profile" ||
              child.title === "sidebar.orders"
          );
        } else {
          children.forEach((child) => {
            if (child.title === "sidebar.profile") {
              accountChildren.push(child);
            } else if (child.title === "sidebar.orders") {
              if (
                user.privileges.some(
                  (privilege) => privilege.name === "OrdersMenu"
                )
              ) {
                accountChildren.push(child);
              }
            } else if (child.title === "sidebar.invoices") {
              if (
                user.privileges.some(
                  (privilege) => privilege.name === "InvoicesMenu"
                )
              ) {
                accountChildren.push(child);
              }
            } else if (child.title === "sidebar.billingProducts") {
              if (
                user.privileges.some(
                  (privilege) => privilege.name === "BillingProductsMenu"
                )
              ) {
                accountChildren.push(child);
              }
            } else if (child.title === "sidebar.statements") {
              if (
                user.privileges.some(
                  (privilege) => privilege.name === "StatementsMenu"
                )
              ) {
                accountChildren.push(child);
              }
            } else if (child.title === "sidebar.emails") {
              if (
                user.privileges.some(
                  (privilege) => privilege.name === "EmailsMenu"
                )
              ) {
                accountChildren.push(child);
              }
            }
          });
          return accountChildren;
        }
      default:
        return [];
    }
  } else if (title === "sidebar.reports") {
    let reportChildren = [];
    if (privilege !== "subaccount") {
      return children;
    } else {
      children.forEach((child) => {
        if (child.title === "sidebar.generateReport") {
          reportChildren.push(child);
        } else if (child.title === "sidebar.automatedReports") {
          if (
            user.privileges.some(
              (privilege) => privilege.name === "AutomatedReportsMenu"
            )
          ) {
            reportChildren.push(child);
          }
        } else if (child.title === "sidebar.reportHistory") {
          if (
            user.privileges.some(
              (privilege) => privilege.name === "AutomatedReportsMenu"
            )
          ) {
            reportChildren.push(child);
          }
        }
      });
      return reportChildren;
    }
  } else if (title === "Catalog") {
    switch (privilege) {
      case "admin":
        return children;
      default:
        return [];
    }
  }
};

const reduceChildRoutes = (props) => {
  const {
    items,
    page,
    depth,
    currentRoute,
    privilege,
    user,
    handleCloseMore = null,
  } = props;
  if (!page.children) {
    if (privilege === "viewer" && page.title !== "sidebar.dashboard") {
      return items;
    }
    if (privilege !== "admin" && page.title === "sidebar.documentation") {
      return items;
    }
    if (privilege !== "admin" && page.title === "sidebar.privileges") {
      return items;
    }
    if (privilege !== "admin" && page.title === "sidebar.services") {
      return items;
    }
    if (page.title === "sidebar.alerts" && privilege === "subaccount") {
      if (
        user.privileges.some((privilege) => privilege.name === "AlertsMenu")
      ) {
        items.push(
          <SidebarNavListItem
            depth={depth}
            href={page.href}
            icon={page.icon}
            key={page.title}
            badge={page.badge}
            title={page.title}
            handleCloseMore={handleCloseMore}
          />
        );
      } else {
        return items;
      }
    } else {
      items.push(
        <SidebarNavListItem
          depth={depth}
          href={page.href}
          icon={page.icon}
          key={page.title}
          badge={page.badge}
          title={page.title}
          handleCloseMore={handleCloseMore}
        />
      );
    }
  } else if (page.title === "sidebar.crm") {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : false;

    const filteredChildren = filterChildrenByPrivilege({
      title: page.title,
      children: page.children,
      privilege: privilege,
      user: user,
    });

    if (filteredChildren.length === 0) {
      return items;
    }

    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
        handleCloseMore={handleCloseMore}
      >
        <SidebarNavList
          depth={depth + 1}
          pages={filteredChildren}
          handleCloseMore={handleCloseMore}
        />
      </SidebarNavListItem>
    );
  } else if (page.title === "sidebar.account") {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : false;

    const filteredChildren = filterChildrenByPrivilege({
      title: page.title,
      children: page.children,
      privilege: privilege,
      user: user,
    });

    if (filteredChildren.length === 0) {
      return items;
    }
    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
        handleCloseMore={handleCloseMore}
      >
        <SidebarNavList
          depth={depth + 1}
          pages={filteredChildren}
          handleCloseMore={handleCloseMore}
        />
      </SidebarNavListItem>
    );
  } else if (page.title === "Catalog") {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : false;

    const filteredChildren = filterChildrenByPrivilege({
      title: page.title,
      children: page.children,
      privilege: privilege,
      user: user,
    });

    if (filteredChildren.length === 0) {
      return items;
    }
    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
        handleCloseMore={handleCloseMore}
      >
        <SidebarNavList
          depth={depth + 1}
          pages={filteredChildren}
          handleCloseMore={handleCloseMore}
        />
      </SidebarNavListItem>
    );
  } else if (page.title === "sidebar.reports") {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : false;

    const filteredChildren = filterChildrenByPrivilege({
      title: page.title,
      children: page.children,
      privilege: privilege,
      user: user,
    });

    if (filteredChildren.length === 0) {
      return items;
    }

    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
        handleCloseMore={handleCloseMore}
      >
        <SidebarNavList
          depth={depth + 1}
          pages={filteredChildren}
          handleCloseMore={handleCloseMore}
        />
      </SidebarNavListItem>
    );
  }

  return items;
};

export default reduceChildRoutes;
