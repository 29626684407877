import React from "react";
import { Collapse, Form, InputGroup } from "react-bootstrap";
import { useWhitelabel } from "../../contexts/WhitelabelContext";

const NewWatchdogForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const { backgroundColor, fontColor } = useWhitelabel();
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.name && errors.name)}
        />
        {!!touched.name && (
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          type="text"
          name="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.description && errors.description)}
        />
        {!!touched.description && (
          <Form.Control.Feedback type="invalid">
            {errors.description}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Action Type</Form.Label>
        <Form.Select
          name="actionType"
          value={values.actionType}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.actionType && errors.actionType)}
        >
          <option value="">Select Action Type</option>
          <option value="NONE">No Action</option>
          <option value="SUSPEND">Suspend</option>
        </Form.Select>
        {!!touched.actionType && (
          <Form.Control.Feedback type="invalid">
            {errors.actionType}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Amount</Form.Label>
        <Form.Control
          type="text"
          name="amount"
          value={values.amount}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.amount && errors.amount)}
        />
        {!!touched.amount && (
          <Form.Control.Feedback type="invalid">
            {errors.amount}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Warning</Form.Label>
        <Form.Control
          type="text"
          name="warning"
          value={values.warning}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.warning && errors.warning)}
        />
        {!!touched.warning && (
          <Form.Control.Feedback type="invalid">
            {errors.warning}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Warning 1</Form.Label>
        <Form.Control
          type="text"
          name="warning1"
          value={values.warning1}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.warning1 && errors.warning1)}
        />
        {!!touched.warning1 && (
          <Form.Control.Feedback type="invalid">
            {errors.warning1}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Warning 2</Form.Label>
        <Form.Control
          type="text"
          name="warning2"
          value={values.warning2}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.warning2 && errors.warning2)}
        />
        {!!touched.warning2 && (
          <Form.Control.Feedback type="invalid">
            {errors.warning2}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Service</Form.Label>
        <Form.Select
          name="service"
          value={values.service}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.service && errors.service)}
        >
          <option value="">Select Service</option>
          <option value="data">Data</option>
          <option value="sms">SMS</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Unit Type</Form.Label>
        <Form.Select
          name="unitType"
          value={values.unitType}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.unitType && errors.unitType)}
        >
          <option value="">Select Unit Type</option>
          {values.service === "data" && (
            <>
              <option value="byte">Byte</option>
              <option value="kb">KB</option>
              <option value="mb">MB</option>
              <option value="gb">GB</option>
            </>
          )}
          {values.service === "sms" && <option value="unit">Unit</option>}
        </Form.Select>
      </Form.Group>
      <Collapse in={values.actionType === "SUSPEND"} dimension="height">
        <div>
          <Form.Group className="mb-3">
            <Form.Check
              type="switch"
              label="Enable Suspend Interval?"
              checked={values.enableSuspendInterval}
              name="enableSuspendInterval"
              onChange={(e) =>
                setFieldValue("enableSuspendInterval", e.target.checked)
              }
              style={{
                "--dynamic-bg-color": backgroundColor,
                "--dynamic-font-color": fontColor,
              }}
              onBlur={handleBlur}
            />
          </Form.Group>
          <Collapse in={values.enableSuspendInterval} dimension="height">
            <Form.Group className="mb-3">
              <Form.Label>Suspend Interval</Form.Label>
              <InputGroup>
                <Form.Control
                  style={{
                    width: "20%",
                  }}
                  type="text"
                  name="suspendInterval.amount"
                  value={values.suspendInterval.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={Boolean(
                    touched.suspendInterval?.amount &&
                      errors.suspendInterval?.amount
                  )}
                />
                {/* Select: days or months */}
                <Form.Select
                  style={{
                    width: "80%",
                  }}
                  name="suspendInterval.period"
                  value={values.suspendInterval.period}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={Boolean(
                    touched.suspendInterval?.period &&
                      errors.suspendInterval?.period
                  )}
                >
                  <option value="">Select period</option>
                  <option value="days">Days</option>
                  <option value="months">Months</option>
                </Form.Select>
              </InputGroup>
            </Form.Group>
          </Collapse>
        </div>
      </Collapse>
      <Form.Group className="mb-3">
        <Form.Label>Watchdog Interval</Form.Label>
        <InputGroup>
          <Form.Control
            style={{
              width: "20%",
            }}
            type="text"
            name="watchdogInterval.amount"
            value={values.watchdogInterval.amount}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(
              touched.watchdogInterval?.amount &&
                errors.watchdogInterval?.amount
            )}
          />
          {/* Select: days or months */}
          <Form.Select
            style={{
              width: "80%",
            }}
            name="watchdogInterval.period"
            value={values.watchdogInterval.period}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(
              touched.watchdogInterval?.period &&
                errors.watchdogInterval?.period
            )}
          >
            <option value="">Select period</option>
            <option value="days">Days</option>
            <option value="months">Months</option>
          </Form.Select>
        </InputGroup>
      </Form.Group>
    </>
  );
};

export default NewWatchdogForm;
