const getServiceData = (t) => {
  const serviceColumns = [
    {
      Header: "Name",
      accessor: "name",
      sortable: true,
    },
    {
      Header: "Group Tag",
      accessor: "groupTag",
      sortable: true,
    },
    {
      Header: "Description",
      accessor: "description",
      sortable: false,
    },
    {
      Header: "Unit Type",
      accessor: "unitType",
      sortable: true,
    },
  ];

  return { serviceColumns };
};

export default getServiceData;
