import React from "react";
import { Form } from "react-bootstrap";

const NewSimForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
}) => {
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Sim Type</Form.Label>
        <Form.Select
          name="simType"
          value={values.simType}
          onChange={(e) => {
            setFieldValue("name", e.target.value);
            handleChange(e);
          }}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.simType && errors.simType)}
        >
          <option value="">Select SIM Card Type</option>
          <option value="T2">T2 Sim Card</option>
          <option value="T3">T3 Sim Card</option>
          <option value="Embedded">Embedded SIM Card</option>
          <option value="IoT Sim Card Industrial">
            IoT Sim Card Industrial
          </option>
          <option value="IoT Sim Chip Industrial">
            IoT Sim Chip Industrial
          </option>
        </Form.Select>
      </Form.Group>
    </>
  );
};

export default NewSimForm;
