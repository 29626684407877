import React, { useContext, useState } from "react";
import {
  Accordion,
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { offerColumns } from "../../inputData/offerData";
import { Container, Row, Col, Table, Pagination, Form } from "react-bootstrap";
import {
  defaultColumn,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import {
  FaSortUp,
  FaSortDown,
  FaSort,
  FaPlus,
  FaTrashAlt,
  FaPencilAlt,
  FaListAlt,
  FaUndo,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useWhitelabel } from "../../contexts/WhitelabelContext";
import OfferForm from "./OfferForm";
import useWindowSize from "../../hooks/useWindowSize";
import EditOfferItem from "./EditOfferItem";
import TerminateOfferModal from "./TerminateOfferModal";
import { useDispatch } from "react-redux";
import { reinstateOffer } from "../../redux/slices/offers";
import NotyfContext from "../../contexts/NotyfContext";
import OfferItemsModal from "./OfferItemsModal";

const RowSelectingTable = ({ columns: offerColumns, data }) => {
  const { t } = useTranslation();
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  const { backgroundColor, fontColor } = useWhitelabel();
  const [showEditOffer, setShowEditOffer] = useState(false);
  const [showDeleteOffer, setShowDeleteOffer] = useState(false);
  const [offerToManage, setOfferToManage] = useState({});
  const [showManageOfferItems, setShowManageOfferItems] = useState(false);
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: offerColumns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: "Actions",
          sortable: false,
          Header: "Actions",
          Cell: ({ row }) => (
            <DropdownButton
              as={ButtonGroup}
              title="Actons"
              variant="primary"
              data-boundary="viewport"
              style={{
                "--dynamic-bg-color": backgroundColor,
                "--dynamic-font-color": fontColor,
                border: "none",
              }}
            >
              {!row.original.terminatedAt && (
                <Dropdown.Item
                  eventKey="edit"
                  onClick={() => {
                    setOfferToManage(row.original);
                    setShowEditOffer(true);
                  }}
                >
                  <FaPencilAlt />
                  <span className="ms-2">Edit</span>
                </Dropdown.Item>
              )}
              {!row.original.terminatedAt && (
                <Dropdown.Item
                  eventKey="delete"
                  onClick={() => {
                    setOfferToManage(row.original);
                    setShowDeleteOffer(true);
                  }}
                >
                  <FaTrashAlt />
                  <span className="ms-2">Delete</span>
                </Dropdown.Item>
              )}
              {!row.original.terminatedAt && (
                <Dropdown.Item
                  eventKey="offer-items"
                  onClick={() => {
                    setOfferToManage(row.original);
                    setShowManageOfferItems(true);
                  }}
                >
                  <FaListAlt />
                  <span className="ms-2">Manage Offer Items</span>
                </Dropdown.Item>
              )}
              {row.original.terminatedAt && (
                <Dropdown.Item
                  eventKey="reinstate"
                  onClick={async () => {
                    await dispatch(reinstateOffer({ id: row.original.id }))
                      .then(() => {
                        notyf.open({
                          type: "success",
                          message: "Offer successfully reinstated",
                          duration: 5000,
                          ripple: true,
                          dismissible: false,
                          position: {
                            x: "center",
                            y: "top",
                          },
                        });
                      })
                      .catch((error) => {
                        notyf.open({
                          type: "danger",
                          message: error,
                          duration: 5000,
                          ripple: true,
                          dismissible: false,
                          position: {
                            x: "center",
                            y: "top",
                          },
                        });
                      });
                  }}
                >
                  <FaUndo />
                  <span className="ms-2">Reinstate</span>
                </Dropdown.Item>
              )}
            </DropdownButton>
          ),
        },
      ]);
    }
  );

  return (
    <React.Fragment>
      <Table id="billingProductsTable" hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    <span>
                      {column.sortable ? (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <FaSortUp className="ms-2 " />
                          ) : (
                            <FaSortDown className="ms-2 " />
                          )
                        ) : (
                          <FaSort className="ms-2" />
                        )
                      ) : null}
                    </span>
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <EditOfferItem
        showEditOffer={showEditOffer}
        setShowEditOffer={setShowEditOffer}
        offerToManage={offerToManage}
      />
      <TerminateOfferModal
        showDeleteOffer={showDeleteOffer}
        setShowDeleteOffer={setShowDeleteOffer}
        offerToTerminate={offerToManage}
      />
      <OfferItemsModal
        showManageOfferItemsModal={showManageOfferItems}
        setShowManageOfferItemsModal={setShowManageOfferItems}
        offerToManage={offerToManage}
      />
      {isDesktop && (
        <Row className="offers-pagination-row">
          <Col md="9">
            <span className="mx-2">
              {t("table.page")}{" "}
              <strong>
                {pageIndex + 1} {t("table.of")} {pageOptions.length}
              </strong>
            </span>
            <span className="ms-3 me-2">{t("table.show")}:</span>
            <Form.Select
              className="d-inline-block w-auto"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>

            <span className="ms-3 me-2">{t("table.goToPage")}:</span>
            <Form.Control
              className="d-inline-block"
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "75px" }}
            />
          </Col>
          <Col md="3">
            <Pagination className="float-end">
              <Pagination.First
                onClick={() => {
                  gotoPage(0);
                }}
                disabled={!canPreviousPage}
              />
              <Pagination.Prev
                onClick={() => {
                  previousPage();
                }}
                disabled={!canPreviousPage}
              />
              <Pagination.Next
                onClick={() => {
                  nextPage();
                }}
                disabled={!canNextPage}
              />
              <Pagination.Last
                onClick={() => {
                  gotoPage(pageCount - 1);
                }}
                disabled={!canNextPage}
              />
            </Pagination>
          </Col>
        </Row>
      )}
      {!isDesktop && (
        <React.Fragment>
          <div className="offers-pagination mt-3">
            <Row className="mb-3">
              <Col className="d-flex justify-content-center">
                <Pagination className="float-end">
                  <Pagination.Prev
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  />
                  <Col className="d-flex align-items-end mx-2 px-3">
                    <span className="mx-2">
                      <strong>
                        {pageIndex + 1} {t("table.of")} {pageOptions.length}
                      </strong>
                    </span>
                  </Col>
                  <Pagination.Next
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  />
                </Pagination>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs="auto">
                <span className="me-2">{t("table.show")}:</span>
                <Form.Select
                  className="d-inline-block w-auto"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  style={{
                    padding: "0.25rem 2.1rem 0.25rem 0.7rem",
                    border: "none",
                    boxShadow: "none",
                    fontSize: "14px",
                  }}
                >
                  {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col>
                <div className="float-end">
                  <span className="me-2">{t("table.goToPage")}:</span>
                  <Form.Control
                    className="d-inline-block w-auto"
                    type="number"
                    defaultValue={pageIndex + 1}
                    min={1}
                    max={pageCount}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                    style={{
                      padding: "0.25rem 1.2rem 0.25rem 0.7rem",
                      fontSize: "0.825rem",
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const RowExpanding = (props) => {
  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <RowSelectingTable columns={offerColumns} data={props.data} />
      </Container>
    </React.Fragment>
  );
};

const OffersList = (props) => {
  const { backgroundColor, fontColor } = useWhitelabel();
  const [showNewOfferModal, setShowNewOfferModal] = useState(false);
  const [width] = useWindowSize();
  const isDesktop = width > 768;

  return (
    <Card>
      <Card.Body>
        {isDesktop && (
          <div className="mb-3 d-flex justify-content-end align-items-center">
            <Form.Check
              type="checkbox"
              className="me-3"
              checked={props.showTerminated}
              label="Show Terminated?"
              onChange={(e) => props.setShowTerminated(e.target.checked)}
              style={{
                "--dynamic-bg-color": backgroundColor ?? "#00acdc",
                "--dynamic-font-color": fontColor ?? "#fff",
                border: "none",
              }}
            />
            <Button
              variant="primary"
              style={{
                border: "none",
                "--dynamic-bg-color": backgroundColor,
                "--dynamic-font-color": fontColor,
              }}
              onClick={() => setShowNewOfferModal(true)}
            >
              <FaPlus />
              <span className="ms-2">New Offer</span>
            </Button>
          </div>
        )}
        {!isDesktop && (
          <div className="d-flex justify-content-center flex-column mb-3">
            <Button
              variant="primary"
              style={{
                border: "none",
                "--dynamic-bg-color": backgroundColor,
                "--dynamic-font-color": fontColor,
                width: "100%",
                padding: "10px",
              }}
              onClick={() => setShowNewOfferModal(true)}
            >
              <FaPlus />
              <span className="ms-2">New Offer</span>
            </Button>
            <Accordion className="filters-accordion">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Filters</Accordion.Header>
                <Accordion.Body>
                  <Form.Group className="d-flex justify-content-between align-items-end">
                    <Form.Label className="mb-0">Show Terminated?</Form.Label>
                    <Form.Check
                      type="switch"
                      className="me-3"
                      checked={props.showTerminated}
                      onChange={(e) =>
                        props.setShowTerminated(e.target.checked)
                      }
                      style={{
                        "--dynamic-bg-color": backgroundColor ?? "#00acdc",
                        "--dynamic-font-color": fontColor ?? "#fff",
                        border: "none",
                      }}
                    />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        )}
        <RowExpanding data={props.data} />
        <OfferForm
          showNewOffer={showNewOfferModal}
          setShowNewOffer={setShowNewOfferModal}
        />
      </Card.Body>
    </Card>
  );
};

export default OffersList;
