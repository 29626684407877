import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../../../contexts/NotyfContext";
import { useTranslation } from "react-i18next";
import { useWhitelabel } from "../../../../contexts/WhitelabelContext";
import useWindowSize from "../../../../hooks/useWindowSize";
import {
  get1nceConfig,
  store1nceConfig,
  update1nceConfig,
} from "../../../../redux/slices/_1nceConfig";
import * as Yup from "yup";
import {
  Collapse,
  Form,
  InputGroup,
  Modal,
  Button,
  Offcanvas,
} from "react-bootstrap";
import Loader from "../../../../components/spinners/Loader";
import { Formik } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { error } from "jquery";

const _1nceConfigModal = ({
  show1nceConfigModal,
  setShow1nceConfigModal,
  entityUser,
}) => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const { whitelabelLoading, backgroundColor, fontColor } = useWhitelabel();
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  const [internalShow, setInternalShow] = useState(show1nceConfigModal);
  const [fadeOut, setFadeOut] = useState(false);
  const _1nceConfig = useSelector((state) => state["_1nceConfig"]._1nceConfig);
  const [isLoading, setIsLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    if (show1nceConfigModal && !isDesktop) {
      setFadeOut(false);
      setInternalShow(true);
    }
  }, [show1nceConfigModal]);

  const handleHide = () => {
    setFadeOut(true);
    setTimeout(() => {
      setInternalShow(false);
      setShow1nceConfigModal(false);
    }, 300);
  };

  useEffect(() => {
    const fetch1nceConfiguration = async () => {
      await dispatch(get1nceConfig({ user_id: entityUser.id }))
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
    };

    if (show1nceConfigModal && entityUser.id) {
      fetch1nceConfiguration();
    }
  }, [show1nceConfigModal, entityUser.id]);

  const initialValues = {
    enable1nceConfig: entityUser.has1nceConfiguration ? true : false,
    username: _1nceConfig?.username ?? "",
    organizationId: _1nceConfig?.organizationId ?? "",
    changePassword: _1nceConfig && _1nceConfig.id ? false : true,
    password: "",
    confirmPassword: "",
  };

  const validation = Yup.object().shape({
    enable1nceConfig: Yup.boolean(),
    username: Yup.string().required("Username is required"),
    organizationId: Yup.number("Organization ID must be a number").required(
      "Organization ID is required"
    ),
    changePassword: Yup.boolean(),
    password: Yup.string().when(["enable1nceConfig", "changePassword"], {
      is: (enable1nceConfig, changePassword) =>
        !enable1nceConfig || changePassword,
      then: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters long"),
      otherwise: Yup.string(),
    }),
    confirmPassword: Yup.string().when(["enable1nceConfig", "changePassword"], {
      is: (enable1nceConfig, changePassword) =>
        !enable1nceConfig || changePassword,
      then: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      otherwise: Yup.string(),
    }),
  });

  if (isDesktop) {
    return (
      <Modal
        show={show1nceConfigModal}
        onHide={() => setShow1nceConfigModal(false)}
        centered
        backdrop="static"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("crm.1nceModal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading || (whitelabelLoading && <Loader />)}
          {!isLoading && (
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validation}
              onSubmit={async (values, { setValues }) => {
                if (_1nceConfig && _1nceConfig.id) {
                  const data = {
                    username: values.username,
                    organizationId: values.organizationId,
                  };
                  if (values.changePassword) {
                    data.password = values.password;
                  }
                  await dispatch(
                    update1nceConfig({
                      user_id: entityUser.id,
                      data: data,
                      role: entityUser.privilege,
                    })
                  )
                    .then(() => {
                      notyf.open({
                        type: "success",
                        message: "Configuration updated successfully",
                        duration: 5000,
                        ripple: true,
                        dismissible: false,
                        position: {
                          x: "center",
                          y: "top",
                        },
                      });
                    })
                    .catch((error) => {
                      notyf.open({
                        type: "danger",
                        message: error,
                        duration: 5000,
                        ripple: true,
                        dismissible: false,
                        position: {
                          x: "center",
                          y: "top",
                        },
                      });
                    })
                    .finally(() => {
                      setShow1nceConfigModal(false);
                    });
                } else if (!_1nceConfig || _1nceConfig.id) {
                  const data = {
                    id: entityUser.id,
                    username: values.username,
                    password: values.password,
                    organizationId: values.organizationId,
                  };
                  await dispatch(
                    store1nceConfig({ data: data, role: entityUser.privilege })
                  )
                    .then(() => {
                      notyf.open({
                        type: "success",
                        message: "Configuration stored successfully",
                        duration: 5000,
                        ripple: true,
                        dismissible: false,
                        position: {
                          x: "center",
                          y: "top",
                        },
                      });
                    })
                    .catch((error) => {
                      notyf.open({
                        type: "danger",
                        message: error,
                        duration: 5000,
                        ripple: true,
                        dismissible: false,
                        position: {
                          x: "center",
                          y: "top",
                        },
                      });
                    })
                    .finally(() => {
                      setShow1nceConfigModal(false);
                    });
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setValues,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Check
                        type="switch"
                        className="enable-1nce-switch"
                        label={t("crm.1nceModal.enable1nce")}
                        name="enable1nceConfig"
                        checked={values.enable1nceConfig}
                        onChange={(e) =>
                          setFieldValue("enable1nceConfig", e.target.checked)
                        }
                        style={{
                          "--dynamic-bg-color": backgroundColor,
                          "--dynamic-font-color": fontColor,
                        }}
                      />
                    </Form.Group>
                    <Collapse in={values.enable1nceConfig} dimension="height">
                      <div>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            {t("crm.1nceModal.organizationId")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="organizationId"
                            value={values.organizationId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={Boolean(
                              touched.organizationId && errors.organizationId
                            )}
                          />
                          {!!touched.organizationId && (
                            <Form.Control.Feedback type="invalid">
                              {errors.organizationId}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("crm.1nceModal.username")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={Boolean(
                              touched.username && errors.username
                            )}
                          />
                          {!!touched.username && (
                            <Form.Control.Feedback type="invalid">
                              {errors.username}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        {_1nceConfig && _1nceConfig.id && (
                          <Form.Group className="mb-3">
                            <Form.Check
                              type="checkbox"
                              className="change-password-checkbox"
                              label={t("crm.1nceModal.changePassword")}
                              name="changePassword"
                              checked={values.changePassword}
                              onChange={(e) =>
                                setFieldValue(
                                  "changePassword",
                                  e.target.checked
                                )
                              }
                              style={{
                                "--dynamic-bg-color": backgroundColor,
                                "--dynamic-font-color": fontColor,
                              }}
                            />
                          </Form.Group>
                        )}
                        <Collapse in={values.changePassword} dimension="height">
                          <div>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                {t("crm.1nceModal.password")}
                              </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  name="password"
                                  type={showPassword ? "text" : "password"}
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="false"
                                  isInvalid={
                                    touched.password && errors.password
                                  }
                                />
                                <InputGroup.Text
                                  style={{
                                    borderTopRightRadius: "20px",
                                    borderBottomRightRadius: "20px",
                                    backgroundColor: "transparent",
                                    borderLeft: "none",
                                  }}
                                >
                                  <Button
                                    style={{
                                      border: "none",
                                      backgroundColor: "transparent",
                                      color: "gray",
                                    }}
                                    onClick={togglePasswordVisibility}
                                  >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                  </Button>
                                </InputGroup.Text>
                              </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                {t("crm.1nceModal.confirmPassword")}
                              </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  name="confirmPassword"
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  value={values.confirmPassword}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="false"
                                  isInvalid={
                                    touched.confirmPassword &&
                                    errors.confirmPassword
                                  }
                                />
                                <InputGroup.Text
                                  style={{
                                    borderTopRightRadius: "20px",
                                    borderBottomRightRadius: "20px",
                                    backgroundColor: "transparent",
                                    borderLeft: "none",
                                  }}
                                >
                                  <Button
                                    style={{
                                      border: "none",
                                      backgroundColor: "transparent",
                                      color: "gray",
                                    }}
                                    onClick={toggleConfirmPasswordVisibility}
                                  >
                                    {showConfirmPassword ? (
                                      <FaEyeSlash />
                                    ) : (
                                      <FaEye />
                                    )}
                                  </Button>
                                </InputGroup.Text>
                              </InputGroup>
                            </Form.Group>
                          </div>
                        </Collapse>
                      </div>
                    </Collapse>
                    <Modal.Footer>
                      <Button
                        type="submit"
                        variant="primary"
                        style={{
                          "--dynamic-bg-color": backgroundColor,
                          "--dynamic-font-color": fontColor,
                          border: "none",
                        }}
                      >
                        {t("crm.1nceModal.submit")}
                      </Button>
                      <Button
                        variant="primary"
                        style={{
                          "--dynamic-bg-color": backgroundColor,
                          "--dynamic-font-color": fontColor,
                          border: "none",
                        }}
                        onClick={() => {
                          setShow1nceConfigModal(false);
                        }}
                      >
                        {t("crm.1nceModal.cancel")}
                      </Button>
                    </Modal.Footer>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    );
  } else {
    return (
      <Offcanvas
        show={internalShow}
        placement="bottom"
        backdrop="static"
        scrollable="true"
        className={`fullscreen-offcanvas ${fadeOut ? "fade-out" : ""}`}
        onHide={handleHide}
      >
        <Offcanvas.Header closeButton className="fullscreen-offcanvas-header">
          <Offcanvas.Title>{t("crm.1nceModal.title")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="fullscreen-offcanvas-body">
          {isLoading || (whitelabelLoading && <Loader />)}
          {!isLoading && (
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validation}
              onSubmit={async (values, { setValues }) => {
                if (_1nceConfig && _1nceConfig.id) {
                  const data = {
                    username: values.username,
                    organizationId: values.organizationId,
                  };
                  if (values.changePassword) {
                    data.password = values.password;
                  }
                  await dispatch(
                    update1nceConfig({
                      user_id: entityUser.id,
                      data: data,
                      role: entityUser.privilege,
                    })
                  )
                    .then(() => {
                      notyf.open({
                        type: "success",
                        message: "Configuration updated successfully",
                        duration: 5000,
                        ripple: true,
                        dismissible: false,
                        position: {
                          x: "center",
                          y: "top",
                        },
                      });
                    })
                    .catch((error) => {
                      notyf.open({
                        type: "danger",
                        message: error,
                        duration: 5000,
                        ripple: true,
                        dismissible: false,
                        position: {
                          x: "center",
                          y: "top",
                        },
                      });
                    })
                    .finally(() => {
                      setShow1nceConfigModal(false);
                    });
                } else if (!_1nceConfig || _1nceConfig.id) {
                  const data = {
                    id: entityUser.id,
                    username: values.username,
                    password: values.password,
                    organizationId: values.organizationId,
                  };
                  await dispatch(
                    store1nceConfig({ data: data, role: entityUser.privilege })
                  )
                    .then(() => {
                      notyf.open({
                        type: "success",
                        message: "Configuration stored successfully",
                        duration: 5000,
                        ripple: true,
                        dismissible: false,
                        position: {
                          x: "center",
                          y: "top",
                        },
                      });
                    })
                    .catch((error) => {
                      notyf.open({
                        type: "danger",
                        message: error,
                        duration: 5000,
                        ripple: true,
                        dismissible: false,
                        position: {
                          x: "center",
                          y: "top",
                        },
                      });
                    })
                    .finally(() => {
                      setShow1nceConfigModal(false);
                    });
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setValues,
              }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3 d-flex justify-content-between">
                      <Form.Label>{t("crm.1nceModal.enable1nce")}</Form.Label>
                      <Form.Check
                        type="switch"
                        className="enable-1nce-switch"
                        name="enable1nceConfig"
                        checked={values.enable1nceConfig}
                        onChange={(e) =>
                          setFieldValue("enable1nceConfig", e.target.checked)
                        }
                        style={{
                          "--dynamic-bg-color": backgroundColor,
                          "--dynamic-font-color": fontColor,
                        }}
                      />
                    </Form.Group>
                    <Collapse in={values.enable1nceConfig} dimension="height">
                      <div>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            {t("crm.1nceModal.organizationId")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="organizationId"
                            value={values.organizationId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={Boolean(
                              touched.organizationId && errors.organizationId
                            )}
                          />
                          {!!touched.organizationId && (
                            <Form.Control.Feedback type="invalid">
                              {errors.organizationId}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("crm.1nceModal.username")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={Boolean(
                              touched.username && errors.username
                            )}
                          />
                          {!!touched.username && (
                            <Form.Control.Feedback type="invalid">
                              {errors.username}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        {_1nceConfig && _1nceConfig.id && (
                          <Form.Group className="mb-3 d-flex justify-content-between">
                            <Form.Label>
                              {t("crm.1nceModal.changePassword")}
                            </Form.Label>
                            <Form.Check
                              type="checkbox"
                              className="change-password-checkbox"
                              name="changePassword"
                              checked={values.changePassword}
                              onChange={(e) =>
                                setFieldValue(
                                  "changePassword",
                                  e.target.checked
                                )
                              }
                              style={{
                                "--dynamic-bg-color": backgroundColor,
                                "--dynamic-font-color": fontColor,
                              }}
                            />
                          </Form.Group>
                        )}
                        <Collapse in={values.changePassword} dimension="height">
                          <div>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                {t("crm.1nceModal.password")}
                              </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  name="password"
                                  type={showPassword ? "text" : "password"}
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="false"
                                  isInvalid={
                                    touched.password && errors.password
                                  }
                                />
                                <InputGroup.Text
                                  style={{
                                    borderTopRightRadius: "20px",
                                    borderBottomRightRadius: "20px",
                                    backgroundColor: "transparent",
                                    borderLeft: "none",
                                  }}
                                >
                                  <Button
                                    style={{
                                      border: "none",
                                      backgroundColor: "transparent",
                                      color: "gray",
                                    }}
                                    onClick={togglePasswordVisibility}
                                  >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                  </Button>
                                </InputGroup.Text>
                              </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                {t("crm.1nceModal.confirmPassword")}
                              </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  name="confirmPassword"
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  value={values.confirmPassword}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  autoComplete="false"
                                  isInvalid={
                                    touched.confirmPassword &&
                                    errors.confirmPassword
                                  }
                                />
                                <InputGroup.Text
                                  style={{
                                    borderTopRightRadius: "20px",
                                    borderBottomRightRadius: "20px",
                                    backgroundColor: "transparent",
                                    borderLeft: "none",
                                  }}
                                >
                                  <Button
                                    style={{
                                      border: "none",
                                      backgroundColor: "transparent",
                                      color: "gray",
                                    }}
                                    onClick={toggleConfirmPasswordVisibility}
                                  >
                                    {showConfirmPassword ? (
                                      <FaEyeSlash />
                                    ) : (
                                      <FaEye />
                                    )}
                                  </Button>
                                </InputGroup.Text>
                              </InputGroup>
                            </Form.Group>
                          </div>
                        </Collapse>
                      </div>
                    </Collapse>
                    <div className="d-flex justify-content-center flex-column mt-3">
                      <Button
                        type="submit"
                        variant="primary"
                        className="mb-3"
                        style={{
                          "--dynamic-bg-color": backgroundColor,
                          "--dynamic-font-color": fontColor,
                          border: "none",
                          padding: "10px",
                        }}
                      >
                        {t("crm.1nceModal.submit")}
                      </Button>
                      <Button
                        variant="primary"
                        style={{
                          "--dynamic-bg-color": backgroundColor,
                          "--dynamic-font-color": fontColor,
                          border: "none",
                          padding: "10px",
                        }}
                        onClick={() => {
                          setShow1nceConfigModal(false);
                        }}
                      >
                        {t("crm.1nceModal.cancel")}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
};

export default _1nceConfigModal;
