import React from "react";
import { Collapse, Form } from "react-bootstrap";
import { useWhitelabel } from "../../contexts/WhitelabelContext";

const NewBucketForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const { backgroundColor, fontColor } = useWhitelabel();
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.name && errors.name)}
        />
        {!!touched.name && (
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          type="text"
          name="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.description && errors.description)}
        />
        {!!touched.description && (
          <Form.Control.Feedback type="invalid">
            {errors.description}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Sharing Type</Form.Label>
        <Form.Select
          name="sharingType"
          value={values.sharingType}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.sharingType && errors.sharingType)}
        >
          <option value="">Select Sharing Type</option>
          <option value="LOCAL">Local</option>
          <option value="SHARED">Shared</option>
        </Form.Select>
        {!!touched.sharingType && (
          <Form.Control.Feedback type="invalid">
            {errors.sharingType}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Check
          name="changeable"
          checked={values.changeable}
          onChange={(e) => setFieldValue("changeable", e.target.checked)}
          label="Changeable?"
          style={{
            "--dynamic-bg-color": backgroundColor,
            "--dynamic-font-color": fontColor,
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Check
          name="enableWarning"
          checked={values.enableWarning}
          onChange={(e) => setFieldValue("enableWarning", e.target.checked)}
          onBlur={handleBlur}
          label="Enable Warning?"
          style={{
            "--dynamic-bg-color": backgroundColor,
            "--dynamic-font-color": fontColor,
          }}
        />
      </Form.Group>
      <Collapse in={values.enableWarning} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>Warning</Form.Label>
          <Form.Control
            type="text"
            value={values.warning}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.warning && errors.warning)}
          />
          {!!touched.warning && (
            <Form.Control.Feedback type="invalid">
              {errors.warning}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Collapse>
      <Form.Group className="mb-3">
        <Form.Label>Amount</Form.Label>
        <Form.Control
          type="text"
          name="amount"
          value={values.amount}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.amount && errors.amount)}
        />
        {!!touched.amount && (
          <Form.Control.Feedback type="invalid">
            {errors.amount}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Unit Type</Form.Label>
        <Form.Select
          name="unitType"
          value={values.unitType}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.unitType && errors.unitType)}
        >
          <option value="">Select unit type</option>
          <option value="byte">Byte</option>
          <option value="kb">KB</option>
          <option value="mb">MB</option>
          <option value="gb">GB</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Reset Interval</Form.Label>
        <Form.Select
          name="resetInterval"
          value={values.resetInterval}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.resetInterval && errors.resetInterval)}
        >
          <option value="">Select Reset Interval</option>
          <option value="DAILY">Day</option>
          <option value="WEEKLY">Week</option>
          <option value="MONTHLY">Month</option>
        </Form.Select>
      </Form.Group>
    </>
  );
};

export default NewBucketForm;
