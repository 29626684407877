import { Image } from "react-bootstrap";
import defaultUser from "../assets/img/avatars/default-user.png";
import SelectColumnFilter from "../utils/SelectColumnFilter";

const getClientData = (t) => {
  const clientColumns = [
    {
      Header: "",
      accessor: "image",
      Cell: ({ row }) => {
        return (
          <Image
            className="avatar rounded-circle"
            src={
              row.original.image && row.original.image.imagePath
                ? `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${row.original?.image?.imagePath}`
                : defaultUser
            }
            alt=""
          />
        );
      },
      sortable: false,
      Filter: () => null,
      canRenderFilter: false,
    },
    {
      Header: "ID",
      accessor: "id",
      sortable: true,
      canRenderFilter: false,
    },
    {
      Header: t("crm.fullName"),
      accessor: "fullName",
      sortable: true,
      canRenderFilter: true,
    },
    {
      Header: t("crm.company"),
      accessor: "company",
      Cell: ({ row }) => {
        return <>{row.original?.company ?? "N/A"}</>;
      },
      sortable: true,
      canRenderFilter: true,
    },
    {
      Header: t("crm.email"),
      accessor: "email",
      sortable: true,
      canRenderFilter: true,
    },
  ];

  return { clientColumns };
};

export default getClientData;
