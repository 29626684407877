import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Modal, Offcanvas } from "react-bootstrap";
import { useWhitelabel } from "../../contexts/WhitelabelContext";
import { Formik } from "formik";
import * as Yup from "yup";
import OfferNaming from "./OfferNaming";
import NotyfContext from "../../contexts/NotyfContext";
import { useDispatch } from "react-redux";
import { createCatalogOffer } from "../../redux/slices/offers";
import useWindowSize from "../../hooks/useWindowSize";

const OfferForm = ({ showNewOffer, setShowNewOffer }) => {
  const { backgroundColor, fontColor } = useWhitelabel();
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  const [internalShow, setInternalShow] = useState(showNewOffer);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (showNewOffer && !isDesktop) {
      setFadeOut(false);
      setInternalShow(true);
    }
  }, [showNewOffer]);

  const handleHide = () => {
    setFadeOut(true);
    setTimeout(() => {
      setInternalShow(false);
      setShowNewOffer(false);
    }, 300);
  };

  const initialValues = {
    name: "",
    currency: "",
    payment_type: "",
  };

  const validation = Yup.object().shape({
    name: Yup.string().required("Required"),
    currency: Yup.string().required("Required"),
    payment_type: Yup.string().required("Required"),
  });

  if (isDesktop) {
    return (
      <Modal
        show={showNewOffer}
        onHide={() => setShowNewOffer(false)}
        centered
        backdrop="static"
        scrollable
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>New Offer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, { setValues }) => {
              const data = {
                name: values.name,
                currency: values.currency,
                paymentType: values.payment_type,
              };

              await dispatch(createCatalogOffer({ data }))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Offer successfully created",
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  setValues(initialValues);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setValues,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <OfferNaming
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />

                  <Modal.Footer>
                    <Button
                      variant="primary"
                      style={{
                        "--dynamic-bg-color": backgroundColor,
                        "--dynamic-font-color": fontColor,
                        border: "none",
                      }}
                      onClick={() => {
                        setValues(initialValues);
                        setShowNewOffer(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      style={{
                        "--dynamic-bg-color": backgroundColor,
                        "--dynamic-font-color": fontColor,
                        border: "none",
                      }}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <Offcanvas
      show={internalShow}
      placement="bottom"
      backdrop="static"
      scrollable={true}
      className={`fullscreen-offcanvas ${fadeOut ? "fade-out" : ""}`}
      onHide={handleHide}
    >
      <Offcanvas.Header closeButton className="fullscreen-offcanvas-header">
        <Offcanvas.Title>New Offer</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="fullscreen-offcanvas-body">
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={async (values, { setValues }) => {
            const data = {
              name: values.name,
              currency: values.currency,
              paymentType: values.payment_type,
            };

            await dispatch(createCatalogOffer({ data }))
              .then(() => {
                notyf.open({
                  type: "success",
                  message: "Offer successfully created",
                  duration: 5000,
                  ripple: true,
                  dismissible: false,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .catch((error) => {
                notyf.open({
                  type: "danger",
                  message: error,
                  duration: 5000,
                  ripple: true,
                  dismissible: false,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .finally(() => {
                setValues(initialValues);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setValues,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <OfferNaming
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

                <div className="d-flex justify-content-center flex-column mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    className="mb-3"
                    style={{
                      "--dynamic-bg-color": backgroundColor,
                      "--dynamic-font-color": fontColor,
                      border: "none",
                      padding: "10px",
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="primary"
                    style={{
                      "--dynamic-bg-color": backgroundColor,
                      "--dynamic-font-color": fontColor,
                      border: "none",
                      padding: "10px",
                    }}
                    onClick={() => {
                      setValues(initialValues);
                      setShowNewOffer(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OfferForm;
