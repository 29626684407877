import { useState, useEffect } from "react";
import useSidebar from "./useSidebar";
import { SIDEBAR_BEHAVIOR } from "../constants";

function useWindowSize() {
  const [size, setSize] = useState([window.innerWidth]);
  const { behavior, setBehavior } = useSidebar();

  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth]);
      if (window.innerWidth < 600) {
        setBehavior(SIDEBAR_BEHAVIOR.STICKY);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setBehavior]);

  return size;
}

export default useWindowSize;
