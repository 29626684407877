import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  offers: [],
  catalogOffers: [],
  availableOffers: [],
  assignedOffers: [],
  offerItems: [],
};

const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    setOffers(state, action) {
      state.offers = action.payload;
    },
    setCatalogOffers(state, action) {
      state.catalogOffers = action.payload;
    },
    setAvailableOffers(state, action) {
      state.availableOffers = action.payload;
    },
    addAvailableOffer(state, action) {
      const newOffer = action.payload;
      return {
        ...state,
        availableOffers: [...state.availableOffers, newOffer],
      };
    },
    addCatalogOffer(state, action) {
      const newOffer = action.payload;
      return {
        ...state,
        catalogOffers: [...state.catalogOffers, newOffer],
      };
    },
    updateAvailableOffer(state, action) {
      const { id, offerDetails } = action.payload;
      const updatedOffers = state.availableOffers.map((offer) => {
        if (offer.id === id) {
          return offerDetails;
        }
        return offer;
      });
      return {
        ...state,
        availableOffers: updatedOffers,
      };
    },
    updateCatalogOffer(state, action) {
      const { id, offerDetails } = action.payload;
      const updatedOffers = state.catalogOffers.map((offer) => {
        if (offer.id === id) {
          return offerDetails;
        }
        return offer;
      });
      return {
        ...state,
        catalogOffers: updatedOffers,
      };
    },
    removeCatalogOffer(state, action) {
      const id = action.payload;
      const newCatalogOffers = state.catalogOffers.filter(
        (customer) => customer.id !== id
      );
      return {
        ...state,
        catalogOffers: newCatalogOffers,
      };
    },
    setAssignedOffers(state, action) {
      state.assignedOffers = action.payload;
    },
    setOfferItems(state, action) {
      state.offerItems = action.payload;
    },
    addOfferItem(state, action) {
      const newOfferItem = action.payload;
      return {
        ...state,
        offerItems: [...state.offerItems, newOfferItem],
      };
    },
  },
});

export function getOffers(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/offers/${id}/distribution`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(offersSlice.actions.setOffers(response.data));
      return Promise.resolve("Success");
    } catch (error) {
      dispatch(offersSlice.actions.setOffers([]));
      return Promise.reject("Error");
    }
  };
}

export function getCatalogOffers({ showTerminated = false }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/offers/catalog`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
          params: {
            showTerminated: showTerminated,
          },
        }
      );
      dispatch(offersSlice.actions.setCatalogOffers(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function reinstateOffer({ id }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/offers/${id}/reinstate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        offersSlice.actions.updateCatalogOffer({
          id: id,
          offerDetails: response.data.data,
        })
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getAvailableOffers() {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/offers/available`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(offersSlice.actions.setAvailableOffers(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      dispatch(offersSlice.actions.setAvailableOffers([]));
      return Promise.reject(error.message);
    }
  };
}

export function getAssignedOffersForUser(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/offers/catalog/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(offersSlice.actions.setAssignedOffers(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      dispatch(offersSlice.actions.setAssignedOffers([]));
      return Promise.reject(error.message);
    }
  };
}

export function createCatalogOffer({ data }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/offers`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(offersSlice.actions.addAvailableOffer(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function updateOffer({ id, data }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/offers/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        offersSlice.actions.updateCatalogOffer({
          id: id,
          offerDetails: response.data.data,
        })
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function updateOffers({ id, data }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/offers/${id}/update`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getOfferItems({ type }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/offerItems`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
          params: {
            type: type,
          },
        }
      );
      dispatch(offersSlice.actions.setOfferItems(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getOfferOfferItems({ id }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/offerItems/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(offersSlice.actions.setOfferItems(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function storeOfferItem({ data }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/offerItems`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(offersSlice.actions.addOfferItem(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function terminateOffer(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/offers/${id}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(offersSlice.actions.removeCatalogOffer(id));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export const { setOffers, setAvailableOffers } = offersSlice.actions;
export default offersSlice.reducer;
