import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../../redux/slices/services";
import NotyfContext from "../../../contexts/NotyfContext";
import getServiceData from "./serviceData";
import { useTranslation } from "react-i18next";
import {
  Card,
  Container,
  Form,
  Pagination,
  Table,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import Loader from "../../../components/spinners/Loader";
import { useWhitelabel } from "../../../contexts/WhitelabelContext";
import {
  useTable,
  useRowSelect,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
} from "react-table";
import useWindowSize from "../../../hooks/useWindowSize";
import { FaPlus, FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { Helmet } from "react-helmet-async";
import NewServiceModal from "./NewServiceModal";

const Services = () => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const services = useSelector((state) => state.services.services);
  const [isLoading, setIsLoading] = useState(true);
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const { serviceColumns } = useMemo(() => getServiceData(t), [t]);
  const { faviconUrl, backgroundColor, fontColor } = useWhitelabel();
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  const [showNewServiceModal, setShowNewServiceModal] = useState(false);

  useEffect(() => {
    const fetchServices = async () => {
      await dispatch(getServices())
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
    };

    if (user.id && user.role === "admin") {
      fetchServices();
    }
  }, [user]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: serviceColumns,
      data: services,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  if (isLoading) {
    return (
      <Card className="w-100 h-100 d-flex justify-content-center align-items-center">
        <Card.Body className="w-100 h-100 d-flex justify-content-center align-items-center">
          <Loader />
        </Card.Body>
      </Card>
    );
  }
  return (
    <React.Fragment>
      <Helmet title="Services">
        <link rel="icon" href={faviconUrl} />
      </Helmet>
      <Container fluid className="p-0">
        <Card>
          <Card.Header>
            <Card.Title>Services</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="mb-3 d-flex justify-content-end align-items-center">
              <Button
                variant="primary"
                style={{
                  "--dynamic-bg-color": backgroundColor,
                  "--dynamic-font-color": fontColor,
                  border: "none",
                }}
                onClick={() => setShowNewServiceModal(true)}
              >
                <FaPlus className="me-2" />
                <span>New Service</span>
              </Button>
            </div>
            <Table hover responsive {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        <span {...column.getSortByToggleProps()}>
                          {column.render("Header")}
                          <span>
                            {column.sortable ? (
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <FaSortUp className="ms-2 " />
                                ) : (
                                  <FaSortDown className="ms-2 " />
                                )
                              ) : (
                                <FaSort className="ms-2" />
                              )
                            ) : null}
                          </span>
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {isDesktop && (
              <Row className="client-pagination-row">
                <Col md="9" className="client-pagination-col">
                  <span className="mx-2">
                    {t("table.page")}{" "}
                    <strong>
                      {pageIndex + 1} {t("table.of")} {pageOptions.length}
                    </strong>
                  </span>
                  <span className="ms-3 me-2">{t("table.show")}:</span>
                  <Form.Select
                    className="d-inline-block w-auto"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </Form.Select>

                  <span className="ms-3 me-2">{t("table.goToPage")}:</span>
                  <Form.Control
                    className="d-inline-block"
                    type="number"
                    defaultValue={pageIndex + 1}
                    min={1}
                    max={pageCount}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                    style={{ width: "75px" }}
                  />
                </Col>
                <Col md="3" className="client-pagination-col">
                  <Pagination className="float-end">
                    <Pagination.First
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    />
                    <Pagination.Prev
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    />
                    <Pagination.Next
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    />
                    <Pagination.Last
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    />
                  </Pagination>
                </Col>
              </Row>
            )}
            {!isDesktop && (
              <React.Fragment>
                <div className="client-pagination mt-3">
                  <Row className="mb-3">
                    <Col className="d-flex justify-content-center">
                      <Pagination className="float-end">
                        <Pagination.Prev
                          onClick={() => previousPage()}
                          disabled={!canPreviousPage}
                        />
                        <Col className="d-flex align-items-end mx-2 px-3">
                          <span className="mx-2">
                            <strong>
                              {pageIndex + 1} {t("table.of")}{" "}
                              {pageOptions.length}
                            </strong>
                          </span>
                        </Col>
                        <Pagination.Next
                          onClick={() => nextPage()}
                          disabled={!canNextPage}
                        />
                      </Pagination>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs="auto">
                      <span className="me-2">{t("table.show")}:</span>
                      <Form.Select
                        className="d-inline-block w-auto"
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(Number(e.target.value));
                        }}
                        style={{
                          padding: "0.25rem 2.1rem 0.25rem 0.7rem",
                          border: "none",
                          boxShadow: "none",
                          fontSize: "14px",
                        }}
                      >
                        {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            {pageSize}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col>
                      <div className="float-end">
                        <span className="me-2">{t("table.goToPage")}:</span>
                        <Form.Control
                          className="d-inline-block w-auto"
                          type="number"
                          defaultValue={pageIndex + 1}
                          min={1}
                          max={pageCount}
                          onChange={(e) => {
                            const page = e.target.value
                              ? Number(e.target.value) - 1
                              : 0;
                            gotoPage(page);
                          }}
                          style={{
                            padding: "0.25rem 1.2rem 0.25rem 0.7rem",
                            fontSize: "0.825rem",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </React.Fragment>
            )}
          </Card.Body>
          <NewServiceModal
            showNewServiceModal={showNewServiceModal}
            setShowNewServiceModal={setShowNewServiceModal}
          />
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Services;
