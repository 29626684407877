import React, { useContext, useEffect, useState } from "react";
import { useWhitelabel } from "../../contexts/WhitelabelContext";
import NotyfContext from "../../contexts/NotyfContext";
import { useDispatch } from "react-redux";
import useWindowSize from "../../hooks/useWindowSize";
import { Button, Modal, Offcanvas } from "react-bootstrap";
import { terminateOffer } from "../../redux/slices/offers";

const TerminateOfferModal = ({
  showDeleteOffer,
  setShowDeleteOffer,
  offerToTerminate,
}) => {
  const { backgroundColor, fontColor } = useWhitelabel();
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  const [internalShow, setInternalShow] = useState(showDeleteOffer);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (showDeleteOffer && !isDesktop) {
      setFadeOut(false);
      setInternalShow(true);
    }
  }, [showDeleteOffer]);

  const handleHide = () => {
    setFadeOut(true);
    setTimeout(() => {
      setInternalShow(false);
      setShowDeleteOffer(false);
    }, 300);
  };

  const handleDelete = async () => {
    await dispatch(terminateOffer(offerToTerminate.id))
      .then(() => {
        notyf.open({
          type: "success",
          message: "Offer successfully terminated",
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "center",
            y: "top",
          },
        });
      })
      .catch((error) => {
        notyf.open({
          type: "danger",
          message: error,
          duration: 5000,
          ripple: true,
          dismissible: false,
          position: {
            x: "center",
            y: "top",
          },
        });
      })
      .finally(() => {
        if (isDesktop) {
          setShowDeleteOffer(false);
        } else {
          handleHide();
        }
      });
  };

  if (isDesktop) {
    return (
      <Modal
        show={showDeleteOffer}
        onHide={() => setShowDeleteOffer(false)}
        centered
        backdrop="static"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Terminate Offer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-justify">
            Are you sure you would like to terminate offer{" "}
            {offerToTerminate.name}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            style={{
              "--dynamic-bg-color": backgroundColor,
              "--dynamic-font-color": fontColor,
              border: "none",
            }}
            onClick={() => setShowDeleteOffer(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{
              "--dynamic-bg-color": backgroundColor,
              "--dynamic-font-color": fontColor,
              border: "none",
            }}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return (
    <Offcanvas
      show={internalShow}
      placement="bottom"
      backdrop="static"
      scrollable={true}
      className={`fullscreen-offcanvas ${fadeOut ? "fade-out" : ""}`}
      onHide={handleHide}
    >
      <Offcanvas.Header closeButton className="fullscreen-offcanvas-header">
        <Offcanvas.Title>Terminate Offer</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="fullscreen-offcanvas-body">
        <p className="text-justify">
          Are you sure you would like to terminate offer {offerToTerminate.name}
          ?
        </p>
        <div className="mt-3 d-flex justify-content-center flex-column">
          <Button
            variant="primary"
            className="mb-3"
            style={{
              "--dynamic-bg-color": backgroundColor,
              "--dynamic-font-color": fontColor,
              border: "none",
              padding: "10px",
            }}
            onClick={handleDelete}
          >
            Delete
          </Button>
          <Button
            variant="primary"
            style={{
              "--dynamic-bg-color": backgroundColor,
              "--dynamic-font-color": fontColor,
              border: "none",
              padding: "10px",
            }}
            onClick={() => setShowDeleteOffer(false)}
          >
            Cancel
          </Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default TerminateOfferModal;
