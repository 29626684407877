import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import adminsReducer from "./slices/admins";
import managersReducer from "./slices/managers";
import tenantsReducer from "./slices/tenants";
import customersReducer from "./slices/customers";
import subaccountsReducer from "./slices/subaccounts";
import userReducer from "./slices/user";
import simsReducer from "./slices/sims";
import billingProductsReducer from "./slices/billingProducts";
import offersReducer from "./slices/offers";
import invoicesReducer from "./slices/invoices";
import distributionReducer from "./slices/distribution";
import statementsReducer from "./slices/statements";
import ordersReducer from "./slices/orders";
import sessionReducer from "./slices/dataSessions";
import alertReducer from "./slices/alerts";
import watchdogReducer from "./slices/watchdogs";
import reportsReducer from "./slices/reports";
import impersonationUserReducer from "./slices/impersonationUser";
import customFieldsReducer from "./slices/customFields";
import preferencesReducer from "./slices/preferences";
import whitelabelReducer from "./slices/whitelabel";
import dashboardReducer from "./slices/dashboard";
import privilegesReducer from "./slices/privileges";
import zonesReducer from "./slices/zones";
import smsReducer from "./slices/sms";
import userActivityReducer from "./slices/userActivity";
import notificationReducer from "./slices/notifications";
import _1nceConfigReducer from "./slices/_1nceConfig";
import servicesReducer from "./slices/services";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    admins: adminsReducer,
    managers: managersReducer,
    tenants: tenantsReducer,
    customers: customersReducer,
    subaccounts: subaccountsReducer,
    user: userReducer,
    sims: simsReducer,
    offers: offersReducer,
    billingProducts: billingProductsReducer,
    invoices: invoicesReducer,
    statements: statementsReducer,
    orders: ordersReducer,
    dataSessions: sessionReducer,
    distribution: distributionReducer,
    alerts: alertReducer,
    watchdogs: watchdogReducer,
    reports: reportsReducer,
    impersonationUser: impersonationUserReducer,
    customFields: customFieldsReducer,
    preferences: preferencesReducer,
    whitelabel: whitelabelReducer,
    dashboard: dashboardReducer,
    privileges: privilegesReducer,
    zones: zonesReducer,
    sms: smsReducer,
    userActivity: userActivityReducer,
    notifications: notificationReducer,
    _1nceConfig: _1nceConfigReducer,
    services: servicesReducer,
  },
});
