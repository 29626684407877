import { Badge } from "react-bootstrap";

const offerColumns = [
  {
    Header: "Name",
    accessor: "name",
    sortable: true,
    Cell: ({ row: { original } }) => (
      <span style={{ color: original.terminatedAt ? "red" : "inherit" }}>
        {original.name}
      </span>
    ),
  },
  {
    Header: "Status",
    accessor: "status",
    sortable: true,
    Cell: ({ row: { original } }) => (
      <span>
        {original.status === "Valid" ? (
          <Badge bg="success">Valid</Badge>
        ) : (
          <Badge bg="danger">Invalid</Badge>
        )}
      </span>
    ),
  },
  {
    Header: "Currency",
    accessor: "currency",
    sortable: true,
    Cell: ({ row: { original } }) => (
      <span style={{ color: original.terminatedAt ? "red" : "inherit" }}>
        {original.currency}
      </span>
    ),
  },
  {
    Header: "Payment Type",
    accessor: "paymentType",
    sortable: true,
    Cell: ({ row: { original } }) => (
      <span style={{ color: original.terminatedAt ? "red" : "inherit" }}>
        {original.paymentType}
      </span>
    ),
  },
  {
    Header: "Start Date",
    accessor: "createdAt",
    sortable: true,
    Cell: ({ row: { original } }) => (
      <span style={{ color: original.terminatedAt ? "red" : "inherit" }}>
        {original.createdAt}
      </span>
    ),
  },
  {
    Header: "End Date",
    accessor: "terminatedAt",
    sortable: true,
    Cell: ({ row: { original } }) => (
      <>{original.terminatedAt ? original.terminatedAt : ""}</>
    ),
    Cell: ({ row: { original } }) => (
      <span style={{ color: original.terminatedAt ? "red" : "inherit" }}>
        {original.terminatedAt}
      </span>
    ),
  },
];

export { offerColumns };
