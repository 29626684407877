import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  gr: {
    translation: {
      sidebar: {
        dashboard: "Ταμπλό",
        crm: "ΔΠΣ",
        admins: "Διαχειριστές",
        tenants: "Ενοικιαστές",
        managers: "Μανατζερ",
        customers: "Πελάτες",
        subaccounts: "Υπολογαριασμοί",
        sims: "Κάρτες SIM",
        account: "Λογαριασμός",
        profile: "Προφίλ",
        billingProducts: "Τιμολογιακά Προϊόντα",
        invoices: "Τιμολόγια",
        statements: "Κατάσταση Λογαριασμού",
        orders: "Παραγγελίες",
        emails: "Emails",
        reports: "Αναφορές",
        generateReport: "Δημιουργία Αναφοράς",
        automatedReports: "Αυτοματοποιημένες Αναφορές",
        reportHistory: "Ιστορικό Αναφορών",
        alerts: "Συναγερμοί",
        notifications: "Ειδοποιήσεις",
        support: "Υποστήριξη",
        privileges: "Προνόμια",
        settings: "Ρυθμίσεις",
        services: "Υπηρεσίες",
        documentation: "Οδηγίες",
      },
      dashboard: {
        dataVolume: "Όγκος Δεδομένων",
        dataSessions: "Συνεδρίες Δεδομένων",
        alerts: "Ειδοποιήσεις",
        zeroSessions: "Μηδενικές Συνεδρίες",
        sinceYesterday: "Από Χθες",
        sinceLastMonth: "Από τον Προηγούμενο Μήνα",
        sinceLastYear: "Από τον Προηγούμενο Χρόνο",
        averageVolume: "Μέσος Όγκος Δεδομένων",
        activeSims: "Ενεργές SIM",
        dataVolumePerHour: "Όγκος Δεδομένων ανά ώρα",
        topMetrics: "Κορυφαίες Μετρικές",
        simCardsPerOffer: "Κάρτες SIM ανά Πακέτο",
        simInventory: "Κατανομή SIM ανά Κατάσταση",
        dataVolumePerNetwork: "Όγκος Δεδομένων ανά Δίκτυο",
        selectCountry: "Επιλέξτε Χώρα",
        networksDistribution: "Κατανομή Ενεργών Καρτών SIM ανά Δίκτυο",
        watchdogAlerts: "Ειδοποιήσεις Κατανάλωσης",
        noData: "Δεν υπάρχουν δεδομένα",
        daily: "Ημερήσια",
        monthly: "Μηνιαία",
        yearly: "Ετήσια",
      },
      crm: {
        admins: "Διαχειριστές",
        tenants: "Ενοικιαστές",
        managers: "Μάνατζερ",
        customers: "Πελάτες",
        subaccounts: "Υπολογαριασμοί",
        filters: "Φίλτρα",
        fullName: "Ονοματεπώνυμο",
        company: "Εταιρεία",
        email: "Email",
        address: "Διεύθυνση",
        city: "Πόλη",
        country: "Χώρα",
        postcode: "Ταχυδρομικός Κώδικας",
        telephone: "Τηλέφωνο",
        mobile: "Κινητό",
        email: "Email",
        occupation: "Επάγγελμα",
        company: "Εταιρεία",
        tin: "ΑΦΜ",
        username: "Όνομα Χρήστη",
        role: "Ρόλος",
        actions: "Ενέργειες",
        edit: "Επεξεργασία",
        delete: "Διαγραφή",
        billing: "Τιμολόγηση",
        whitelabeling: "Whitelabeling",
        offers: "Πακέτα",
        impersonate: "Είσοδος",
        permissions: "Δικαιώματα",
        sendWelcomeEmail: "Αποστολή Email Καλωσορίσματος",
        resetPassword: "Επαναφορά Κωδικού",
        initializeSIMs: "Αρχικοποίηση SIM",
        initializeAlerts: "Αρχικοποίηση Ειδοποιήσεων",
        newTenant: "Νέος Ενοικιαστής",
        newManager: "Νέος Μανατζερ",
        newCustomer: "Νέος Πελάτης",
        newSubaccount: "Νέος Υπολογαριασμός",
        newModal: {
          newTenant: "Δημιουργία Tenant",
          newManager: "Δημιουργία Manager",
          newCustomer: "Δημιουργία Customer",
          newSubaccount: "Δημιουργία Subaccount",
          fullName: "Ονοματεπώνυμο",
          address: "Διεύθυνση",
          city: "Πόλη",
          country: "Χώρα",
          postcode: "Ταχυδρομικός Κώδικας",
          telephone: "Τηλέφωνο",
          mobile: "Κινητό",
          email: "Email",
          occupation: "Επάγγελμα",
          company: "Εταιρεία",
          tin: "ΑΦΜ",
          username: "Όνομα Χρήστη",
          sendWelcomeEmail: "Αποστολή Email Καλωσορίσματος",
          cancel: "Ακύρωση",
          submit: "Υποβολή",
          next: "Επόμενο",
          previous: "Προγούμενο",
          currency: "Νόμισμα",
          billingFrequency: "Συχνότητα Τιμολόγησης",
          daysToPayInvoice: "Ημέρες Πληρωμής Τιμολογίου",
          minimumAmountToInvoice: "Ελάχιστο Ποσό για Τιμολόγηση",
          invoiceType: "Τύπος Τιμολόγησης",
          preferredLanguage: "Προτιμώμενη Γλώσσα",
          billingEmail: "Email Τιμολόγησης",
        },
        editModal: {
          editTenant: "Επεξεργασία Tenant",
          editManager: "Επεξεργασία Manager",
          editCustomer: "Επεξεργασία Customer",
          editSubaccount: "Επεξεργασία Subaccount",
          fullName: "Ονοματεπώνυμο",
          address: "Διεύθυνση",
          city: "Πόλη",
          country: "Χώρα",
          postcode: "Ταχυδρομικός Κώδικας",
          telephone: "Τηλέφωνο",
          mobile: "Κινητό",
          email: "Email",
          occupation: "Επάγγελμα",
          company: "Εταιρεία",
          tin: "ΑΦΜ",
          username: "Όνομα Χρήστη",
          cancel: "Ακύρωση",
          submit: "Υποβολή",
        },
        billingModal: {
          title: "Ρυθμίσεις Τιμολόγησης",
          currency: "Νόμισμα",
          billingFrequency: "Συχνότητα Τιμολόγησης",
          daysToPayInvoice: "Ημέρες Πληρωμής Τιμολογίου",
          minimumAmountToInvoice: "Ελάχιστο Ποσό για Τιμολόγηση",
          invoiceType: "Τύπος Τιμολόγησης",
          preferredLanguage: "Προτιμώμενη Γλώσσα",
          billingEmail: "Email Τιμολόγησης",
          cancel: "Ακύρωση",
          submit: "Υποβολή",
        },
        whitelabelModal: {
          title: "Ρυθμίσεις Whitelabel",
          enableWhitelabel: "Ενεργοποίηση Whitelabel",
          pricePerMonth: "Τιμή ανά Μήνα",
          url: "URL",
          cancel: "Ακύρωση",
          submit: "Υποβολή",
        },
        roleModal: {
          title: "Ενημέρωση Ρόλου",
          role: "Ρόλος",
          tenant: "Tenant",
          manager: "Manager",
          customer: "Customer",
          cancel: "Ακύρωση",
          submit: "Υποβολή",
        },
        offersModal: {
          title: "Ανάθεση Πακέτων",
          availableOffers: "Διαθέσιμα Πακέτα",
          assignedOffers: "Ανατεθειμένα Πακέτα",
          submit: "Υποβολή",
          cancel: "Ακύρωση",
        },
        "1nceModal": {
          enable1nce: "Ενεργοποίηση 1NCE Ρυθμίσεων",
          title: "Ρυθμίσεις 1NCE",
          organizationId: "ID πελάτη",
          username: "Όνομα Χρήστη",
          password: "Κωδικός",
          confirmPassword: "Επιβεβαίωση Κωδικού",
          changePassword: "Αλλαγή Κωδικού",
          submit: "Υποβολή",
          cancel: "Ακύρωση",
        },
      },
      sims: {
        title: "Κάρτες SIM",
        dataVolume: "Όγκος Δεδομένων",
        dataSessions: "Sessions Δεδομένων",
        zeroSessions: "Μηδενικά Sessions",
        filters: "Φίλτρα",
        simStatus: "Ενεργή σύνδεση",
        simState: "Κατάσταση",
        imsi: "IMSI",
        iccid: "ICCID",
        msisdn: "MSISDN",
        offer: "Πακέτο",
        data: "Δεδομένα",
        min: "Ελάχιστο",
        max: "Μέγιστο",
        search: "Αναζήτηση",
        clear: "Καθαρισμός",
        actions: "Ενέργειες",
        bulk: "Μαζικές Λειτουργίες",
        modify: "Τροποποίηση",
        product: "Προϊόν",
        clearSelection: "Καθαρισμός Επιλεγμένων",
        activate: "Ενεργοποίηση",
        suspend: "Παύση",
        resume: "Επαναφορά",
        terminate: "Τερματισμός",
        testMode: "Δοκιμαστική Λειτουργία",
        sendSms: "Αποστολή SMS",
        watchdogs: "Watchdogs",
        createWatchdog: "Δημιουργία Watchdog",
        removeAllWatchdogs: "Κατάργηση Watchdogs",
        export: "Εξαγωγή",
        simInformation: "Πληροφορίες SIM",
        usage: "Χρήση",
        ipAddress: "Διεύθυνση IP",
        imei: "IMEI",
        status: "Κατάσταση",
        visitProfile: "Επίσκεψη Προφίλ",
        provider: "Πάροχος",
        area: "Περιοχή",
        cell: "Κελί",
        longitude: "Γεωγραφικό Μήκος",
        latitude: "Γεωγραφικό Πλάτος",
        cellRange: "Εύρος Κελιού",
        unit: "Μονάδα",
        sort: "Ταξινόμηση",
        simType: "Τύπος SIM",
        suspendModal: {
          title: "Παύση SIM",
          orderComplete: "Η παύση της SIM ολοκληρώθηκε",
          orderError: "Η παύση της SIM απέτυχε",
          contactAdmin:
            "Παρακαλούμε επικοινωνήστε με τον διαχειριστή του συστήματος",
          cancel: "Ακύρωση",
          submit: "Υποβολή",
          close: "Κλείσιμο",
        },
        resumeModal: {
          title: "Συνέχιση SIM",
          orderComplete: "Η συνέχιση της SIM ολοκληρώθηκε",
          orderError: "Η συνέχιση της SIM απέτυχε",
          contactAdmin:
            "Παρακαλούμε επικοινωνήστε με τον διαχειριστή του συστήματος",
          cancel: "Ακύρωση",
          submit: "Υποβολή",
          close: "Κλείσιμο",
        },
        activateModal: {
          title: "Ενεργοποίηση SIM",
          offer: "Πακέτο",
          selectOffer: "Επιλέξτε Πακέτο",
          success: "Η ενεργοποίηση της SIM ολοκληρώθηκε",
          orderError: "Η ενεργοποίηση της SIM απέτυχε",
          contactAdmin:
            "Παρακαλούμε επικοινωνήστε με τον διαχειριστή του συστήματος",
          cancel: "Ακύρωση",
          submit: "Υποβολή",
          close: "Κλείσιμο",
        },
        terminateModal: {
          title: "Τερματισμός SIM",
          targetState: "Επιθυμητή Κατάσταση",
          onStock: "On Stock",
          terminated: "Διαγραμμένο",
          success: "Ο τερματισμός της SIM ολοκληρώθηκε",
          orderError: "Ο τερματισμός της SIM απέτυχε",
          contactAdmin:
            "Παρακαλούμε επικοινωνήστε με τον διαχειριστή του συστήματος",
          cancel: "Ακύρωση",
          submit: "Υποβολή",
          close: "Κλείσιμο",
        },
      },
      simProfile: {
        simDetails: "Λεπτομέρειες SIM",
        actions: "Ενέργειες",
        hlr: "HLR",
        session: "Καταναλώσεις",
        smsUsage: "Χρήση SMS",
        updateLoc: "Ενημέρωση Τοποθεσίας",
        imeiLock: "Κλείδωμα IMEI",
        usage: "Χρήση",
        imei: "IMEI",
        status: "Κατάσταση",
        dataSession: "Ενεργό Session",
        map: "Χάρτης",
        provider: "Πάροχος",
        imsi: "IMSI",
        iccid: "ICCID",
        msisdn: "MSISDN",
        country: "Χώρα",
        operator: "Πάροχος",
        area: "Περιοχή",
        cell: "Κελί",
        longitude: "Γεωγραφικό Μήκος",
        latitude: "Γεωγραφικό Πλάτος",
        cellRange: "Εύρος Κελιού",
        activity: "Δραστηριότητα",
        selectPeriod: "Επιλέξτε Περίοδο",
        daily: "Ημερήσια",
        monthly: "Μηνιαία",
        yearly: "Ετήσια",
        dataVolume: "Όγκος Δεδομένων",
        dataSessions: "Sessions Δεδομένων",
        alerts: "Ειδοποιήσεις",
        zeroSessions: "Μηδενικά Sessions",
        watchdog: "Watchdog",
        type: "Τύπος",
        amount: "Ποσότητα",
        hlrModal: {
          title: "Προφίλ HLR",
          selectScope: "Επιλέξτε Κατηγορία",
          basicInfo: "Βασικές Πληροφορίες",
          locationInfo: "Πληροφορίες Τοποθεσίας",
          csBasicInfo: "Βασικές Πληροφορίες CS",
          gprsService2g3g: "Υπηρεσίες GPRS 2G/3G",
          telephonyService: "Υπηρεσίες Τηλεφωνίας",
          supplementaryService: "Επιπλέον Υπηρεσίες",
          epcService4G: "Υπηρεσίες EPC 4G",
        },
        sessionModal: {
          title: "Καταναλώσεις Δεδομένων SIM",
          openDate: "Ημερομηνία Έναρξης",
          closeDate: "Ημερομηνία Λήξης",
          consumption: "Κατανάλωση",
          roundedBytes: "Στρογγυλοποιημένα Bytes",
          operator: "Πάροχος",
          cell: "Κελί",
          imei: "IMEI",
          close: "Κλείσιμο",
        },
        smsUsageModal: {
          title: "Χρήση SMS",
          direction: "Κατεύθυνση",
          date: "Ημερομηνία",
          sender: "Αποστολέας",
          message: "Μήνυμα",
          status: "Κατάσταση",
          close: "Κλείσιμο",
        },
      },
      profile: {
        profileDetails: "Λεπτομέρειες Προφίλ",
        contactInformation: "Στοιχεία Επικοινωνίας",
        professionalInformation: "Επαγγελματικές Πληροφορίες",
        activities: "Δραστηριότητες",
        loadMore: "Περισσότερα",
      },
      billingProducts: {
        title: "Τιμολογιακά Προϊόντα",
        name: "Όνομα",
        pricePlan: "Πακέτο Τιμολόγησης",
        price: "Τιμή",
        number: "Πλήθος",
      },
      invoices: {
        title: "Τιμολόγια",
        invoiceNo: "Αριθμός Τιμολογίου",
        date: "Ημερομηνία",
        amount: "Ποσό(συμπ.ΦΠΑ)",
        description: "Περιγραφή",
        paid: "Πληρώθηκε",
        download: "Λήψη",
      },
      statements: {
        title: "Ενημερώσεις Λογαριασμού",
        cutDate: "Ημερομηνία Έκδοσης",
        description: "Περιγραφή",
        amount: "Ποσό(συμπ. ΦΠΑ)",
        balance: "Υπόλοιπο",
      },
      orders: {
        title: "Αιτήσεις",
        open: "Ανοιχτές",
        parked: "Σε Αναμονή",
        finished: "Ολοκληρωμένες",
        error: "Σφάλμα",
        noOrders: "Δεν υπάρχουν αιτήσεις",
        showAllOrders: "Εμφάνιση όλων των αιτήσεων",
      },
      emails: {
        filters: "Φίλτρα",
        category: "Κατηγορία",
        name: "Όνομα",
        address: "Διεύθυνση",
        actions: "Ενέργειες",
        newEmail: "Νέο Email",
        edit: "Επεξεργασία",
        delete: "Διαγραφή",
        cancel: "Ακύρωση",
        submit: "Υποβολή",
        selectCategory: "Επιλέξτε Κατηγορία",
        invoice: "Τιμολόγιο",
        watchdog: "Watchdog",
        updateEmail: "Επεξεργασία Email",
      },
      reports: {
        title: "Αναφορές",
        reportType: "Τύπος Αναφοράς",
        usage: "Χρήση",
        groupBy: "Ομαδοποίηση κατά",
        field: "Πεδίο",
        usageType: "Τύπος Χρήσης",
        selectUsageType: "Επιλέξτε τύπο χρήσης",
        data: "Δεδομένα",
        sms: "SMS",
        all: "Όλα",
        imsi: "IMSI",
        network: "Δίκτυο",
        period: "Περίοδος",
        day: "Ημέρα",
        month: "Μήνας",
        year: "Έτος",
        period: "Περίοδος",
        today: "Σήμερα",
        thisWeek: "Αυτή την εβδομάδα",
        thisMonth: "Αυτό το μήνα",
        thisYear: "Αυτό το έτος",
        lastMonth: "Τελευταίος μήνας",
        lastYear: "Προηγούμενο έτος",
        customer: "Πελάτης",
        select: "Επιλέξτε",
        simSearchCriteria: "Κριτήρια Αναζήτησης SIM",
        all: "Όλα",
        bySpecificImsi: "Ανά συγκεκριμένο IMSI",
        byImsiRange: "Ανά εύρος IMSI",
        byIccid: "Ανά ICCID",
        imsi: "IMSI",
        startImsi: "Αρχή IMSI",
        endImsi: "Τέλος IMSI",
        iccid: "ICCID",
        view: "Προβολή",
        customerId: "ID Πελάτη",
        date: "Ημερομηνία",
        simType: "Τύπος SIM",
        networkCode: "Κωδικός Δικτύου",
        networkName: "Όνομα Δικτύου",
        country: "Χώρα",
        totalBytes: "Συνολικά Bytes",
        roundedBytes: "Στρογγυλοποιημένα Bytes",
        sessions: "Sessions",
        zeroSessions: "Μηδενικά Sessions",
        actions: "Ενέργειες",
        export: "Εξαγωγή",
        automated: {
          title: "Αυτοματοποιημένες Αναφορές",
          name: "Όνομα",
          type: "Τύπος",
          frequency: "Συχνότητα",
          nextReport: "Επόμενη Αναφορά",
          new: "Νέα Αυτοματοποιημένη Αναφορά",
          save: "Αποθήκευση",
          daily: "Ημερήσια",
          weekly: "Εβδομαδιαία",
          monthly: "Μηνιαία",
          edit: "Επεξεργασία",
          delete: "Διαγραφή",
          actions: "Ενέργειες",
        },
        history: {
          title: "Ιστορικό Αναφορών",
          tableHeaders: {
            reportName: "Όνομα Αναφοράς",
            date: "Ημερομηνία",
            status: "Κατάσταση",
            download: "Λήψη",
            actions: "Ενέργειες",
          },
          delete: "Διαγραφή",
          completed: "Ολοκληρώθηκε",
          incomplete: "Μη Ολοκληρωμένη",
        },
      },
      alerts: {
        title: "Ειδοποιήσεις",
        filters: "Φίλτρα",
        alert: "Ειδοποίηση",
        date: "Ημερομηνία",
        imsi: "IMSI",
        enterValue: "Εισάγετε τιμή",
      },
      settings: {
        title: "Ρυθμίσεις",
        profileSettings: "Ρυθμίσεις Προφίλ",
        general: "Γενικά",
        account: "Λογαριασμός",
        profileImage: "Εικόνα Προφίλ",
        whitelabel: "Whitelabel",
        privacyAndSafety: "Απορρήτου και Ασφάλεια",
        notifications: "Ειδοποιήσεις",
        billing: "Τιμολόγηση",
        invoice: "Τιμολόγιο",
        generalSettings: {
          title: "Γενικές Ρυθμίσεις",
          timeZone: "Ζώνη Ώρας",
          dateFormat: "Μορφή Ημερομηνίας",
          timeFormat: "Μορφή Ώρας",
          save: "Αποθήκευση",
        },
        accountSettings: {
          title: "Ρυθμίσεις Λογαριασμού",
          fullName: "Ονοματεπώνυμο",
          address: "Διεύθυνση",
          city: "Πόλη",
          country: "Χώρα",
          postcode: "Ταχυδρομικός Κώδικας",
          telephone: "Τηλέφωνο",
          mobileNumber: "Κινητό",
          email: "Email",
          occupation: "Επάγγελμα",
          company: "Εταιρεία",
          tin: "ΑΦΜ",
          username: "Όνομα Χρήστη",
          changePassword: "Αλλαγή Κωδικού",
          password: "Κωδικός",
          confirmPassword: "Επιβεβαίωση Κωδικού",
          save: "Αποθήκευση",
        },
        profileImageSettings: {
          title: "Ρυθμίσεις Εικόνας Προφίλ",
          upload: "Ανέβασμα",
          save: "Αποθήκευση",
        },
        whitelabelSettings: {
          title: "Ρυθμίσεις Whitelabel",
          backgroundColor: "Χρώμα Φόντου",
          fontColor: "Χρώμα Γραμματοσειράς",
          sidebarPosition: "Θέση Sidebar",
          favicon: "Favicon",
          whitelabelImage: "Εικόνα Whitelabel",
          upload: "Ανέβασμα",
          save: "Αποθήκευση",
        },
        privacySettings: {
          title: "Ρυθμίσεις Απορρήτου",
          enable2FA: "Ενεργοποίηση 2FA",
          save: "Αποθήκευση",
        },
        notificationSettings: {
          title: "Ρυθμίσεις Ειδοποιήσεων",
          receiveNotifications: "Λήψη Ειδοποιήσεων",
          sms: "SMS",
          email: "Email",
          weeklyAnalytics: "Λήψη Εβδομαδιαίων Αναλυτικών",
          checkIfYouWantToReceiveWeeklyAnalytics:
            "Ελέγξτε αν θέλετε να λαμβάνετε εβδομαδιαίες αναλυτικές",
          notifyAboutNewAlerts: "Λήψη ειδοποιήσεων κατανάλωσης",
          checkIfYouWantToReceiveNotificationsWhenThereAreNewAlerts:
            "Επιλέξτε αν επιθυμείτε να ειδοποιείστε όταν ξεπεραστούν τα όρια κατανάλωσης",
          submit: "Υποβολή",
        },
        billingSettings: {
          title: "Ρυθμίσεις Τιμολόγησης",
          selectCurrency: "Επιλέξτε Νόμισμα",
          currency: "Νόμισμα",
          preferredLanguage: "Προτιμώμενη Γλώσσα",
          selectLanguage: "Επιλέξτε Γλώσσα",
          submit: "Υποβολή",
        },
        invoiceSettings: {
          title: "Ρυθμίσεις Τιμολογίου",
          name: "Όνομα",
          showGuis: "Εμφάνιση GUIs",
          showSubtotal: "Εμφάνιση Μερικού Συνόλου",
          showLineOfService: "Εμφάνιση Line of Service",
          save: "Αποθήκευση",
        },
      },
      privileges: {
        title: "Προνόμια",
        name: "Όνομα",
        actions: "Ενέργειες",
        newPrivilege: "Νέο Προνόμιο",
        edit: "Επεξεργασία",
        delete: "Διαγραφή",
      },
      table: {
        page: "Σελίδα",
        of: "από",
        show: "Προβολή",
        goToPage: "Μετάβαση στη σελίδα",
      },
      notifications: {
        title: "Ειδοποιήσεις",
        id: "ID",
        date: "Ημερομηνία",
        message: "Μήνυμα",
        type: "Τύπος",
      },
    },
  },
  en: {
    translation: {
      sidebar: {
        dashboard: "Dashboard",
        crm: "CRM",
        admins: "Admins",
        tenants: "Tenants",
        managers: "Managers",
        customers: "Customers",
        subaccounts: "Subaccounts",
        sims: "SIM Cards",
        account: "Account",
        profile: "Profile",
        billingProducts: "Billing Products",
        invoices: "Invoices",
        statements: "Statements",
        orders: "Orders",
        emails: "Emails",
        reports: "Reports",
        generateReport: "Generate Report",
        automatedReports: "Automated Reports",
        reportHistory: "Report History",
        alerts: "Alerts",
        notifications: "Notifications",
        support: "Support",
        privileges: "Privileges",
        settings: "Settings",
        services: "Services",
        documentation: "Documentation",
      },
      dashboard: {
        dataVolume: "Data Volume",
        dataSessions: "Data Sessions",
        alerts: "Alerts",
        zeroSessions: "Zero Sessions",
        sinceYesterday: "Since Yesterday",
        sinceLastMonth: "Since Last Month",
        sinceLastYear: "Since Last Year",
        averageVolume: "Average Data Volume",
        activeSims: "Active SIMs",
        dataVolumePerHour: "Data Volume per Hour",
        topMetrics: "Top Metrics",
        simCardsPerOffer: "SIM Cards per Offer",
        simInventory: "SIM Distribution by Status",
        dataVolumePerNetwork: "Data Volume per Network",
        selectCountry: "Select Country",
        networksDistribution: "Active SIM Cards per Network Distribution",
        watchdogAlerts: "Consumption Alerts",
        noData: "No Data Available",
        daily: "Daily",
        monthly: "Monthly",
        yearly: "Yearly",
      },
      crm: {
        admins: "Admins",
        tenants: "Tenants",
        managers: "Managers",
        customers: "Customers",
        subaccounts: "Subaccounts",
        filters: "Filters",
        fullName: "Full Name",
        company: "Company",
        email: "Email",
        address: "Address",
        city: "City",
        country: "Country",
        postcode: "Postcode",
        telephone: "Telephone",
        mobile: "Mobile",
        email: "Email",
        occupation: "Occupation",
        company: "Company",
        tin: "Tax ID",
        username: "Username",
        role: "Role",
        actions: "Actions",
        edit: "Edit",
        delete: "Delete",
        billing: "Billing",
        whitelabeling: "Whitelabeling",
        offers: "Offers",
        impersonate: "Impersonate",
        permissions: "Permissions",
        sendWelcomeEmail: "Send Welcome Email",
        resetPassword: "Reset Password",
        initializeSIMs: "Initialize SIMs",
        initializeAlerts: "Initialize Alerts",
        newTenant: "New Tenant",
        newManager: "New Manager",
        newCustomer: "New Customer",
        newSubaccount: "New Subaccount",
        newModal: {
          newTenant: "Create Tenant",
          newManager: "Create Manager",
          newCustomer: "Create Customer",
          newSubaccount: "Create Subaccount",
          fullName: "Full Name",
          address: "Address",
          city: "City",
          country: "Country",
          postcode: "Postcode",
          telephone: "Telephone",
          mobile: "Mobile",
          email: "Email",
          occupation: "Occupation",
          company: "Company",
          tin: "TIN",
          username: "Username",
          sendWelcomeEmail: "Send Welcome Email",
          cancel: "Cancel",
          submit: "Submit",
          next: "Next",
          previous: "Previous",
        },
        editModal: {
          editTenant: "Edit Tenant",
          editManager: "Edit Manager",
          editCustomer: "Edit Customer",
          editSubaccount: "Edit Subaccount",
          fullName: "Full Name",
          address: "Address",
          city: "City",
          country: "Country",
          postcode: "Postcode",
          telephone: "Telephone",
          mobile: "Mobile",
          email: "Email",
          occupation: "Occupation",
          company: "Company",
          tin: "Tax ID",
          username: "Username",
          cancel: "Cancel",
          submit: "Submit",
        },
        billingModal: {
          title: "Billing Settings",
          currency: "Currency",
          billingFrequency: "Billing Frequency",
          daysToPayInvoice: "Days to Pay Invoice",
          minimumAmountToInvoice: "Minimum Amount to Invoice",
          invoiceType: "Invoice Type",
          preferredLanguage: "Preferred Language",
          billingEmail: "Billing Email",
          cancel: "Cancel",
          submit: "Submit",
        },
        whitelabelModal: {
          title: "Whitelabel Settings",
          enableWhitelabel: "Enable Whitelabel",
          pricePerMonth: "Price per Month",
          url: "URL",
          cancel: "Cancel",
          submit: "Submit",
        },
        roleModal: {
          title: "Update Role",
          role: "Role",
          tenant: "Tenant",
          manager: "Manager",
          customer: "Customer",
          cancel: "Cancel",
          submit: "Submit",
        },
        offersModal: {
          title: "Assign Offers",
          availableOffers: "Available Offers",
          assignedOffers: "Assigned Offers",
          submit: "Submit",
          cancel: "Cancel",
        },
        "1nceModal": {
          enable1nce: "Enable 1NCE Connection",
          title: "1NCE Configuration",
          organizationId: "Organization ID",
          username: "Username",
          password: "Password",
          confirmPassword: "Confirm Password",
          changePassword: "Change Password",
          submit: "Submit",
          cancel: "Cancel",
        },
      },
      sims: {
        title: "SIM Cards",
        dataVolume: "Data Volume",
        dataSessions: "Data Sessions",
        zeroSessions: "Zero Sessions",
        filters: "Filters",
        simStatus: "Active Session",
        simState: "SIM State",
        imsi: "IMSI",
        iccid: "ICCID",
        msisdn: "MSISDN",
        offer: "Offer",
        data: "Data",
        min: "Min",
        max: "Max",
        search: "Search",
        clear: "Clear",
        actions: "Actions",
        bulk: "Bulk Operations",
        modify: "Modify",
        product: "Product",
        clearSelection: "Clear Selection",
        activate: "Activate",
        suspend: "Suspend",
        resume: "Resume",
        terminate: "Terminate",
        testMode: "Test Mode",
        sendSms: "Send SMS",
        watchdogs: "Watchdogs",
        createWatchdog: "Create Watchdog",
        removeAllWatchdogs: "Remove All Watchdogs",
        export: "Export",
        simInformation: "SIM Information",
        usage: "Usage",
        ipAddress: "IP Address",
        imei: "IMEI",
        status: "Status",
        visitProfile: "Visit Profile",
        provider: "Provider",
        area: "Area",
        cell: "Cell",
        longitude: "Longitude",
        latitude: "Latitude",
        cellRange: "Cell Range",
        unit: "Unit",
        sort: "Sort",
        simType: "SIM Type",
        suspendModal: {
          title: "Suspend SIM",
          orderComplete: "SIM suspension completed",
          orderError: "SIM suspension failed",
          contactAdmin: "Please contact the system administrator",
          cancel: "Cancel",
          submit: "Submit",
          close: "Close",
        },
        resumeModal: {
          title: "Resume SIM",
          orderComplete: "SIM resumption completed",
          orderError: "SIM resumption failed",
          contactAdmin: "Please contact the system administrator",
          cancel: "Cancel",
          submit: "Submit",
          close: "Close",
        },
        activateModal: {
          title: "Activate SIM",
          offer: "Offer",
          selectOffer: "Select Offer",
          success: "SIM activation completed",
          orderError: "SIM activation failed",
          contactAdmin: "Please contact the system administrator",
          cancel: "Cancel",
          submit: "Submit",
          close: "Close",
        },
        terminateModal: {
          title: "Terminate SIM",
          targetState: "Desired State",
          onStock: "On Stock",
          terminated: "Terminated",
          success: "SIM termination completed",
          orderError: "SIM termination failed",
          contactAdmin: "Please contact the system administrator",
          cancel: "Cancel",
          submit: "Submit",
          close: "Close",
        },
      },
      simProfile: {
        simDetails: "SIM Details",
        actions: "Actions",
        hlr: "HLR",
        session: "Sessions",
        smsUsage: "SMS Usage",
        updateLoc: "Update Location",
        imeiLock: "IMEI Lock",
        usage: "Usage",
        imei: "IMEI",
        status: "Status",
        dataSession: "Active Session",
        map: "Map",
        provider: "Provider",
        imsi: "IMSI",
        iccid: "ICCID",
        msisdn: "MSISDN",
        country: "Country",
        operator: "Network Operator",
        area: "Area",
        cell: "Cell",
        longitude: "Longitude",
        latitude: "Latitude",
        cellRange: "Cell Range",
        activity: "Activity",
        selectPeriod: "Select Period",
        daily: "Daily",
        monthly: "Monthly",
        yearly: "Yearly",
        dataVolume: "Data Volume",
        dataSessions: "Data Sessions",
        alerts: "Alerts",
        zeroSessions: "Zero Sessions",
        watchdog: "Watchdog",
        type: "Type",
        amount: "Amount",
        hlrModal: {
          title: "HLR Profile",
          selectScope: "Select Scope",
          basicInfo: "Basic Info",
          locationInfo: "Location Info",
          csBasicInfo: "CS Basic Info",
          gprsService2g3g: "GPRS Services 2G/3G",
          telephonyService: "Telephony Services",
          supplementaryService: "Supplementary Services",
          epcService4G: "EPC Services 4G",
        },
        sessionModal: {
          title: "SIM Data Sessions",
          openDate: "Open Date",
          closeDate: "Close Date",
          consumption: "Consumption",
          roundedBytes: "Rounded Bytes",
          operator: "Operator",
          cell: "Cell",
          imei: "IMEI",
          close: "Close",
        },
        smsUsageModal: {
          title: "SMS Usage",
          direction: "Direction",
          date: "Date",
          sender: "Sender",
          message: "Message",
          status: "Status",
          close: "Close",
        },
      },
      profile: {
        profileDetails: "Profile Details",
        contactInformation: "Contact Information",
        professionalInformation: "Professional Information",
        activities: "Activities",
        loadMore: "Load More",
      },
      billingProducts: {
        title: "Billing Products",
        name: "Name",
        pricePlan: "Price Plan",
        price: "Price",
        number: "Number",
      },
      invoices: {
        title: "Invoices",
        invoiceNo: "Invoice Number",
        date: "Date",
        amount: "Amount (incl. VAT)",
        description: "Description",
        paid: "Paid",
        download: "Download",
      },
      statements: {
        title: "Account Statements",
        cutDate: "Cut Date",
        description: "Description",
        amount: "Amount (incl. VAT)",
        balance: "Balance",
      },
      orders: {
        title: "Orders",
        open: "Open",
        parked: "Parked",
        finished: "Finished",
        error: "Error",
        noOrders: "No Orders",
        showAllOrders: "Show All Orders",
      },
      emails: {
        filters: "Filters",
        category: "Category",
        name: "Name",
        address: "Address",
        actions: "Actions",
        newEmail: "New Email",
        edit: "Edit",
        delete: "Delete",
        cancel: "Cancel",
        submit: "Submit",
        selectCategory: "Select Category",
        invoice: "Invoice",
        watchdog: "Watchdog",
        updateEmail: "Update Email",
      },
      reports: {
        title: "Reports",
        reportType: "Report Type",
        usage: "Usage",
        groupBy: "Group By",
        field: "Field",
        usageType: "Usage Type",
        selectUsageType: "Select Usage Type",
        data: "Data",
        sms: "SMS",
        all: "All",
        imsi: "IMSI",
        network: "Network",
        period: "Period",
        day: "Day",
        month: "Month",
        year: "Year",
        today: "Today",
        "This Week": "This Week",
        "This Month": "This Month",
        "This Year": "This Year",
        "Last Month": "Last Month",
        "Last Year": "Last Year",
        customer: "Customer",
        select: "Select",
        simSearchCriteria: "SIM Search Criteria",
        all: "All",
        bySpecificImsi: "By Specific IMSI",
        byImsiRange: "By IMSI Range",
        byIccid: "By ICCID",
        imsi: "IMSI",
        startImsi: "Start IMSI",
        endImsi: "End IMSI",
        iccid: "ICCID",
        view: "View",
        customerId: "Customer ID",
        date: "Date",
        simType: "SIM Type",
        networkCode: "Network Code",
        networkName: "Network Name",
        country: "Country",
        totalBytes: "Total Bytes",
        roundedBytes: "Rounded Bytes",
        sessions: "Sessions",
        zeroSessions: "Zero Sessions",
        actions: "Actions",
        export: "Export",
        automated: {
          title: "Automated Reports",
          name: "Name",
          type: "Type",
          frequency: "Frequency",
          nextReport: "Next Report",
          new: "New Automated Report",
          save: "Save",
          daily: "Daily",
          weekly: "Weekly",
          monthly: "Monthly",
          edit: "Edit",
          delete: "Delete",
          actions: "Actions",
        },
        history: {
          title: "Report History",
          tableHeaders: {
            reportName: "Report Name",
            date: "Date",
            status: "Status",
            download: "Download",
            actions: "Actions",
          },
          delete: "Delete",
          completed: "Completed",
          incomplete: "Incomplete",
        },
      },
      alerts: {
        title: "Alerts",
        filters: "Filters",
        alert: "Alert",
        date: "Date",
        imsi: "IMSI",
        enterValue: "Enter Value",
      },
      settings: {
        title: "Settings",
        profileSettings: "Profile Settings",
        general: "General",
        account: "Account",
        profileImage: "Profile Image",
        whitelabel: "Whitelabel",
        privacyAndSafety: "Privacy & Safety",
        notifications: "Notifications",
        billing: "Billing",
        invoice: "Invoice",
        generalSettings: {
          title: "General Settings",
          timeZone: "Time Zone",
          dateFormat: "Date Format",
          timeFormat: "Time Format",
          save: "Save",
        },
        accountSettings: {
          title: "Account Settings",
          fullName: "Full Name",
          address: "Address",
          city: "City",
          country: "Country",
          postcode: "Postcode",
          telephone: "Telephone",
          mobileNumber: "Mobile",
          email: "Email",
          occupation: "Occupation",
          company: "Company",
          tin: "Tax ID",
          username: "Username",
          changePassword: "Change Password",
          password: "Password",
          confirmPassword: "Confirm Password",
          save: "Save",
        },
        profileImageSettings: {
          title: "Profile Image Settings",
          upload: "Upload",
          save: "Save",
        },
        whitelabelSettings: {
          title: "Whitelabel Settings",
          backgroundColor: "Background Color",
          fontColor: "Font Color",
          sidebarPosition: "Sidebar Position",
          favicon: "Favicon",
          whitelabelImage: "Whitelabel Image",
          upload: "Upload",
          save: "Save",
        },
        privacySettings: {
          title: "Privacy Settings",
          enable2FA: "Enable 2FA",
          save: "Save",
        },
        notificationSettings: {
          title: "Notification Settings",
          receiveNotifications: "Receive Notifications",
          sms: "SMS",
          email: "Email",
          weeklyAnalytics: "Receive Weekly Analytics",
          checkIfYouWantToReceiveWeeklyAnalytics:
            "Check if you want to receive weekly analytics",
          notifyAboutNewAlerts: "Notify about new alerts",
          checkIfYouWantToReceiveNotificationsWhenThereAreNewAlerts:
            "Check if you want to receive notifications when there are new alerts",
          submit: "Submit",
        },
        billingSettings: {
          title: "Billing Settings",
          selectCurrency: "Select Currency",
          currency: "Currency",
          preferredLanguage: "Preferred Language",
          selectLanguage: "Select Language",
          submit: "Submit",
        },
        invoiceSettings: {
          title: "Invoice Settings",
          name: "Name",
          showGuis: "Show GUIs",
          showSubtotal: "Show Subtotal",
          showLineOfService: "Show Line of Service",
          save: "Save",
        },
      },
      privileges: {
        title: "Privileges",
        name: "Name",
        actions: "Actions",
        newPrivilege: "New Privilege",
        edit: "Edit",
        delete: "Delete",
      },
      table: {
        page: "Page",
        of: "of",
        show: "Show",
        goToPage: "Go to page",
      },
      notifications: {
        title: "Notifications",
        id: "ID",
        date: "Date",
        message: "Message",
        type: "Type",
      },
    },
  },
  fr: {
    translation: {
      sidebar: {
        dashboard: "Tableau de bord",
        crm: "CRM",
        admins: "Administrateurs",
        tenants: "Locataires",
        managers: "Gestionnaires",
        customers: "Clients",
        subaccounts: "Sous-comptes",
        sims: "Cartes SIM",
        account: "Compte",
        profile: "Profil",
        billingProducts: "Produits de facturation",
        invoices: "Factures",
        statements: "Relevés",
        orders: "Commandes",
        emails: "E-mails",
        reports: "Rapports",
        generateReport: "Générer un rapport",
        automatedReports: "Rapports automatisés",
        reportHistory: "Historique des rapports",
        alerts: "Alertes",
        notifications: "Notifications",
        support: "Support",
        privileges: "Privilèges",
        settings: "Paramètres",
        services: "Services",
        documentation: "Documentation",
      },
      dashboard: {
        dataVolume: "Volume de données",
        dataSessions: "Sessions de données",
        alerts: "Alertes",
        zeroSessions: "Sessions à zéro",
        sinceYesterday: "Depuis hier",
        sinceLastMonth: "Depuis le mois dernier",
        sinceLastYear: "Depuis l'année dernière",
        averageVolume: "Volume de données moyen",
        activeSims: "SIM actives",
        dataVolumePerHour: "Volume de données par heure",
        topMetrics: "Principales métriques",
        simCardsPerOffer: "Cartes SIM par offre",
        simInventory: "Distribution des SIM par statut",
        dataVolumePerNetwork: "Volume de données par réseau",
        selectCountry: "Sélectionnez le pays",
        networksDistribution: "Distribution des SIM actives par réseau",
        watchdogAlerts: "Alertes de consommation",
        noData: "Pas de données disponibles",
        daily: "Quotidien",
        monthly: "Mensuel",
        yearly: "Annuel",
      },
      crm: {
        admins: "Administrateurs",
        tenants: "Locataires",
        managers: "Gestionnaires",
        customers: "Clients",
        subaccounts: "Sous-comptes",
        filters: "Filtres",
        fullName: "Nom complet",
        company: "Entreprise",
        email: "E-mail",
        address: "Adresse",
        city: "Ville",
        country: "Pays",
        postcode: "Code postal",
        telephone: "Téléphone",
        mobile: "Mobile",
        occupation: "Profession",
        tin: "Numéro fiscal",
        username: "Nom d'utilisateur",
        role: "Rôle",
        actions: "Actions",
        edit: "Éditer",
        delete: "Supprimer",
        billing: "Facturation",
        whitelabeling: "Marque blanche",
        offers: "Offres",
        impersonate: "Imiter",
        permissions: "Autorisations",
        sendWelcomeEmail: "Envoyer un e-mail de bienvenue",
        resetPassword: "Réinitialiser le mot de passe",
        initializeSIMs: "Initialiser les SIM",
        initializeAlerts: "Initialiser les alertes",
        newTenant: "Nouveau locataire",
        newManager: "Nouveau gestionnaire",
        newCustomer: "Nouveau client",
        newSubaccount: "Nouveau sous-compte",
        newModal: {
          newTenant: "Créer un locataire",
          newManager: "Créer un gestionnaire",
          newCustomer: "Créer un client",
          newSubaccount: "Créer un sous-compte",
          fullName: "Nom complet",
          address: "Adresse",
          city: "Ville",
          country: "Pays",
          postcode: "Code postal",
          telephone: "Téléphone",
          mobile: "Mobile",
          email: "E-mail",
          occupation: "Profession",
          company: "Entreprise",
          tin: "Numéro fiscal",
          username: "Nom d'utilisateur",
          sendWelcomeEmail: "Envoyer un e-mail de bienvenue",
          cancel: "Annuler",
          submit: "Soumettre",
          next: "Suivant",
          previous: "Précédent",
        },
        editModal: {
          editTenant: "Modifier le locataire",
          editManager: "Modifier le gestionnaire",
          editCustomer: "Modifier le client",
          editSubaccount: "Modifier le sous-compte",
          fullName: "Nom complet",
          address: "Adresse",
          city: "Ville",
          country: "Pays",
          postcode: "Code postal",
          telephone: "Téléphone",
          mobile: "Mobile",
          email: "E-mail",
          occupation: "Profession",
          company: "Entreprise",
          tin: "Numéro fiscal",
          username: "Nom d'utilisateur",
          cancel: "Annuler",
          submit: "Soumettre",
        },
        billingModal: {
          title: "Paramètres de facturation",
          currency: "Devise",
          billingFrequency: "Fréquence de facturation",
          daysToPayInvoice: "Jours pour payer la facture",
          minimumAmountToInvoice: "Montant minimum à facturer",
          invoiceType: "Type de facture",
          preferredLanguage: "Langue préférée",
          billingEmail: "E-mail de facturation",
          cancel: "Annuler",
          submit: "Soumettre",
        },
        whitelabelModal: {
          title: "Paramètres de marque blanche",
          enableWhitelabel: "Activer la marque blanche",
          pricePerMonth: "Prix par mois",
          url: "URL",
          cancel: "Annuler",
          submit: "Soumettre",
        },
        roleModal: {
          title: "Mettre à jour le rôle",
          role: "Rôle",
          tenant: "Locataire",
          manager: "Gestionnaire",
          customer: "Client",
          cancel: "Annuler",
          submit: "Soumettre",
        },
        offersModal: {
          title: "Assigner des offres",
          availableOffers: "Offres disponibles",
          assignedOffers: "Offres assignées",
          submit: "Soumettre",
        },
      },
      sims: {
        title: "Cartes SIM",
        dataVolume: "Volume de données",
        dataSessions: "Sessions de données",
        zeroSessions: "Sessions à zéro",
        filters: "Filtres",
        simStatus: "Session active",
        simState: "État de la SIM",
        imsi: "IMSI",
        iccid: "ICCID",
        msisdn: "MSISDN",
        offer: "Offre",
        data: "Données",
        min: "Min",
        max: "Max",
        search: "Rechercher",
        clear: "Effacer",
        actions: "Actions",
        bulk: "Opérations en vrac",
        modify: "Modifier",
        product: "Produit",
        clearSelection: "Effacer la sélection",
        activate: "Activer",
        suspend: "Suspendre",
        resume: "Reprendre",
        terminate: "Résilier",
        testMode: "Mode test",
        sendSms: "Envoyer un SMS",
        watchdogs: "Watchdogs",
        createWatchdog: "Créer un Watchdog",
        removeAllWatchdogs: "Supprimer tous les Watchdogs",
        export: "Exporter",
        simInformation: "Informations SIM",
        usage: "Utilisation",
        ipAddress: "Adresse IP",
        imei: "IMEI",
        status: "Statut",
        visitProfile: "Visiter le profil",
        provider: "Fournisseur",
        area: "Zone",
        cell: "Cellule",
        longitude: "Longitude",
        latitude: "Latitude",
        cellRange: "Portée de la cellule",
        unit: "Unité",
        sort: "Trier",
        suspendModal: {
          title: "Suspendre la SIM",
          orderComplete: "Suspension de la SIM complétée",
          orderError: "Échec de la suspension de la SIM",
          contactAdmin: "Veuillez contacter l'administrateur",
          cancel: "Annuler",
          submit: "Soumettre",
          close: "Fermer",
        },
        resumeModal: {
          title: "Reprendre la SIM",
          orderComplete: "Reprise de la SIM complétée",
          orderError: "Échec de la reprise de la SIM",
          contactAdmin: "Veuillez contacter l'administrateur",
          cancel: "Annuler",
          submit: "Soumettre",
          close: "Fermer",
        },
        activateModal: {
          title: "Activer la SIM",
          offer: "Offre",
          selectOffer: "Sélectionner l'offre",
          success: "Activation de la SIM complétée",
          orderError: "Échec de l'activation de la SIM",
          contactAdmin: "Veuillez contacter l'administrateur",
          cancel: "Annuler",
          submit: "Soumettre",
          close: "Fermer",
        },
        terminateModal: {
          title: "Résilier la SIM",
          targetState: "État désiré",
          onStock: "En stock",
          terminated: "Résiliée",
          success: "Résiliation de la SIM complétée",
          orderError: "Échec de la résiliation de la SIM",
          contactAdmin: "Veuillez contacter l'administrateur",
          cancel: "Annuler",
          submit: "Soumettre",
          close: "Fermer",
        },
      },
      simProfile: {
        simDetails: "Détails de la SIM",
        actions: "Actions",
        hlr: "HLR",
        session: "Sessions",
        smsUsage: "Utilisation des SMS",
        updateLoc: "Mettre à jour la localisation",
        imeiLock: "Verrouillage IMEI",
        usage: "Utilisation",
        imei: "IMEI",
        status: "Statut",
        dataSession: "Session active",
        map: "Carte",
        provider: "Fournisseur",
        imsi: "IMSI",
        iccid: "ICCID",
        msisdn: "MSISDN",
        country: "Pays",
        operator: "Opérateur de réseau",
        area: "Zone",
        cell: "Cellule",
        longitude: "Longitude",
        latitude: "Latitude",
        cellRange: "Portée de la cellule",
        activity: "Activité",
        selectPeriod: "Sélectionner la période",
        daily: "Quotidien",
        monthly: "Mensuel",
        yearly: "Annuel",
        dataVolume: "Volume de données",
        dataSessions: "Sessions de données",
        alerts: "Alertes",
        zeroSessions: "Sessions à zéro",
        watchdog: "Watchdog",
        type: "Type",
        amount: "Montant",
        hlrModal: {
          title: "Profil HLR",
          selectScope: "Sélectionner la portée",
          basicInfo: "Informations de base",
          locationInfo: "Informations de localisation",
          csBasicInfo: "Informations de base CS",
          gprsService2g3g: "Services GPRS 2G/3G",
          telephonyService: "Services de téléphonie",
          supplementaryService: "Services supplémentaires",
          epcService4G: "Services EPC 4G",
        },
        sessionModal: {
          title: "Sessions de données SIM",
          openDate: "Date d'ouverture",
          closeDate: "Date de fermeture",
          consumption: "Consommation",
          roundedBytes: "Octets arrondis",
          operator: "Opérateur",
          cell: "Cellule",
          imei: "IMEI",
          close: "Fermer",
        },
        smsUsageModal: {
          title: "Utilisation des SMS",
          direction: "Direction",
          date: "Date",
          sender: "Expéditeur",
          message: "Message",
          status: "Statut",
          close: "Fermer",
        },
      },
      profile: {
        profileDetails: "Détails du profil",
        contactInformation: "Informations de contact",
        professionalInformation: "Informations professionnelles",
        activities: "Activités",
        loadMore: "Charger plus",
      },
      billingProducts: {
        title: "Produits de facturation",
        name: "Nom",
        pricePlan: "Plan tarifaire",
        price: "Prix",
        number: "Numéro",
      },
      invoices: {
        title: "Factures",
        invoiceNo: "Numéro de facture",
        date: "Date",
        amount: "Montant (TTC)",
        description: "Description",
        paid: "Payée",
        download: "Télécharger",
      },
      statements: {
        title: "Relevés de compte",
        cutDate: "Date de clôture",
        description: "Description",
        amount: "Montant (TTC)",
        balance: "Solde",
      },
      orders: {
        title: "Commandes",
        open: "Ouvertes",
        parked: "En attente",
        finished: "Terminées",
        error: "Erreur",
        noOrders: "Aucune commande",
        showAllOrders: "Voir toutes les commandes",
      },
      emails: {
        filters: "Filtres",
        category: "Catégorie",
        name: "Nom",
        address: "Adresse",
        actions: "Actions",
        newEmail: "Nouveau courriel",
        edit: "Éditer",
        delete: "Supprimer",
        cancel: "Annuler",
        submit: "Soumettre",
        selectCategory: "Sélectionner la catégorie",
        invoice: "Facture",
        watchdog: "Watchdog",
        updateEmail: "Mettre à jour l'e-mail",
      },
      reports: {
        title: "Rapports",
        reportType: "Type de rapport",
        usage: "Utilisation",
        groupBy: "Grouper par",
        field: "Champ",
        usageType: "Type d'utilisation",
        selectUsageType: "Sélectionner le type d'utilisation",
        data: "Données",
        sms: "SMS",
        all: "Tous",
        imsi: "IMSI",
        network: "Réseau",
        period: "Période",
        day: "Jour",
        month: "Mois",
        year: "Année",
        today: "Aujourd'hui",
        "This Week": "Cette semaine",
        "This Month": "Ce mois-ci",
        "This Year": "Cette année",
        "Last Month": "Le mois dernier",
        "Last Year": "L'année dernière",
        customer: "Client",
        select: "Sélectionner",
        simSearchCriteria: "Critères de recherche de SIM",
        bySpecificImsi: "Par IMSI spécifique",
        byImsiRange: "Par plage IMSI",
        byIccid: "Par ICCID",
        startImsi: "IMSI de début",
        endImsi: "IMSI de fin",
        iccid: "ICCID",
        view: "Voir",
        customerId: "ID client",
        date: "Date",
        simType: "Type de SIM",
        networkCode: "Code réseau",
        networkName: "Nom du réseau",
        country: "Pays",
        totalBytes: "Total octets",
        roundedBytes: "Octets arrondis",
        sessions: "Sessions",
        zeroSessions: "Sessions à zéro",
        actions: "Actions",
        export: "Exporter",
        automated: {
          title: "Rapports automatisés",
          name: "Nom",
          type: "Type",
          frequency: "Fréquence",
          nextReport: "Prochain rapport",
          new: "Nouveau rapport automatisé",
          save: "Enregistrer",
          daily: "Quotidien",
          weekly: "Hebdomadaire",
          monthly: "Mensuel",
          edit: "Éditer",
          delete: "Supprimer",
          actions: "Actions",
        },
        history: {
          title: "Historique des rapports",
          tableHeaders: {
            reportName: "Nom du rapport",
            date: "Date",
            status: "Statut",
            download: "Télécharger",
            actions: "Actions",
          },
          delete: "Supprimer",
          completed: "Complété",
          incomplete: "Incomplet",
        },
      },
      alerts: {
        title: "Alertes",
        filters: "Filtres",
        alert: "Alerte",
        date: "Date",
        imsi: "IMSI",
        enterValue: "Saisir une valeur",
      },
      settings: {
        title: "Paramètres",
        profileSettings: "Paramètres du profil",
        general: "Général",
        account: "Compte",
        profileImage: "Image de profil",
        whitelabel: "Marque blanche",
        privacyAndSafety: "Confidentialité et sécurité",
        notifications: "Notifications",
        billing: "Facturation",
        invoice: "Facture",
        generalSettings: {
          title: "Paramètres généraux",
          timeZone: "Fuseau horaire",
          dateFormat: "Format de date",
          timeFormat: "Format de l'heure",
          save: "Enregistrer",
        },
        accountSettings: {
          title: "Paramètres du compte",
          fullName: "Nom complet",
          address: "Adresse",
          city: "Ville",
          country: "Pays",
          postcode: "Code postal",
          telephone: "Téléphone",
          mobileNumber: "Mobile",
          email: "E-mail",
          occupation: "Profession",
          company: "Entreprise",
          tin: "Numéro fiscal",
          username: "Nom d'utilisateur",
          changePassword: "Changer le mot de passe",
          password: "Mot de passe",
          confirmPassword: "Confirmer le mot de passe",
          save: "Enregistrer",
        },
        profileImageSettings: {
          title: "Paramètres de l'image de profil",
          upload: "Téléverser",
          save: "Enregistrer",
        },
        whitelabelSettings: {
          title: "Paramètres de marque blanche",
          backgroundColor: "Couleur de fond",
          fontColor: "Couleur de la police",
          sidebarPosition: "Position de la barre latérale",
          favicon: "Favicon",
          whitelabelImage: "Image de marque blanche",
          upload: "Téléverser",
          save: "Enregistrer",
        },
        privacySettings: {
          title: "Paramètres de confidentialité",
          enable2FA: "Activer 2FA",
          save: "Enregistrer",
        },
        notificationSettings: {
          title: "Paramètres de notification",
          receiveNotifications: "Recevoir des notifications",
          sms: "SMS",
          email: "E-mail",
          weeklyAnalytics: "Analyses hebdomadaires",
          checkIfYouWantToReceiveWeeklyAnalytics:
            "Cochez si vous souhaitez recevoir des analyses hebdomadaires",
          notifyAboutNewAlerts: "Notifier en cas de nouvelles alertes",
          checkIfYouWantToReceiveNotificationsWhenThereAreNewAlerts:
            "Cochez si vous souhaitez recevoir des notifications en cas de nouvelles alertes",
          submit: "Soumettre",
        },
        billingSettings: {
          title: "Paramètres de facturation",
          selectCurrency: "Sélectionner la devise",
          currency: "Devise",
          preferredLanguage: "Langue préférée",
          selectLanguage: "Sélectionner la langue",
          submit: "Soumettre",
        },
        invoiceSettings: {
          title: "Paramètres de la facture",
          name: "Nom",
          showGuis: "Afficher les interfaces",
          showSubtotal: "Afficher le sous-total",
          showLineOfService: "Afficher la ligne de service",
          save: "Enregistrer",
        },
      },
      privileges: {
        title: "Privilèges",
        name: "Nom",
        actions: "Actions",
        newPrivilege: "Nouveau privilège",
        edit: "Éditer",
        delete: "Supprimer",
      },
      table: {
        page: "Page",
        of: "de",
        show: "Afficher",
        goToPage: "Aller à la page",
      },
      notifications: {
        title: "Notifications",
        id: "ID",
        date: "Date",
        message: "Message",
        type: "Type",
      },
    },
  },
  de: {
    translation: {
      sidebar: {
        dashboard: "Dashboard",
        crm: "CRM",
        admins: "Administratoren",
        tenants: "Mieter",
        managers: "Manager",
        customers: "Kunden",
        subaccounts: "Unterkonten",
        sims: "SIM-Karten",
        account: "Konto",
        profile: "Profil",
        billingProducts: "Abrechnungsprodukte",
        invoices: "Rechnungen",
        statements: "Kontoauszüge",
        orders: "Bestellungen",
        emails: "E-Mails",
        reports: "Berichte",
        generateReport: "Bericht generieren",
        automatedReports: "Automatisierte Berichte",
        reportHistory: "Berichtsverlauf",
        alerts: "Benachrichtigungen",
        notifications: "Benachrichtigungen",
        support: "Support",
        privileges: "Berechtigungen",
        settings: "Einstellungen",
        services: "Dienstleistungen",
        documentation: "Dokumentation",
      },
      dashboard: {
        dataVolume: "Datenvolumen",
        dataSessions: "Datensitzungen",
        alerts: "Warnungen",
        zeroSessions: "Null-Sitzungen",
        sinceYesterday: "Seit gestern",
        sinceLastMonth: "Seit letztem Monat",
        sinceLastYear: "Seit letztem Jahr",
        averageVolume: "Durchschnittliches Datenvolumen",
        activeSims: "Aktive SIMs",
        dataVolumePerHour: "Datenvolumen pro Stunde",
        topMetrics: "Top Metriken",
        simCardsPerOffer: "SIM-Karten pro Angebot",
        simInventory: "SIM-Verteilung nach Status",
        dataVolumePerNetwork: "Datenvolumen pro Netzwerk",
        selectCountry: "Land auswählen",
        networksDistribution: "Aktive SIM-Karten nach Netzwerkverteilung",
        watchdogAlerts: "Verbrauchswarnungen",
        noData: "Keine Daten verfügbar",
        daily: "Täglich",
        monthly: "Monatlich",
        yearly: "Jährlich",
      },
      crm: {
        admins: "Administratoren",
        tenants: "Mieter",
        managers: "Manager",
        customers: "Kunden",
        subaccounts: "Unterkonten",
        filters: "Filter",
        fullName: "Vollständiger Name",
        company: "Firma",
        email: "E-Mail",
        address: "Adresse",
        city: "Stadt",
        country: "Land",
        postcode: "Postleitzahl",
        telephone: "Telefon",
        mobile: "Mobiltelefon",
        occupation: "Beruf",
        tin: "Steuer-ID",
        username: "Benutzername",
        role: "Rolle",
        actions: "Aktionen",
        edit: "Bearbeiten",
        delete: "Löschen",
        billing: "Abrechnung",
        whitelabeling: "Whitelabel",
        offers: "Angebote",
        impersonate: "Imitieren",
        permissions: "Berechtigungen",
        sendWelcomeEmail: "Willkommens-E-Mail senden",
        resetPassword: "Passwort zurücksetzen",
        initializeSIMs: "SIMs initialisieren",
        initializeAlerts: "Warnungen initialisieren",
        newTenant: "Neuer Mieter",
        newManager: "Neuer Manager",
        newCustomer: "Neuer Kunde",
        newSubaccount: "Neues Unterkonto",
        newModal: {
          newTenant: "Mieter erstellen",
          newManager: "Manager erstellen",
          newCustomer: "Kunde erstellen",
          newSubaccount: "Unterkonto erstellen",
          fullName: "Vollständiger Name",
          address: "Adresse",
          city: "Stadt",
          country: "Land",
          postcode: "Postleitzahl",
          telephone: "Telefon",
          mobile: "Mobiltelefon",
          email: "E-Mail",
          occupation: "Beruf",
          company: "Firma",
          tin: "Steuer-ID",
          username: "Benutzername",
          sendWelcomeEmail: "Willkommens-E-Mail senden",
          cancel: "Abbrechen",
          submit: "Einreichen",
          next: "Weiter",
          previous: "Zurück",
        },
        editModal: {
          editTenant: "Mieter bearbeiten",
          editManager: "Manager bearbeiten",
          editCustomer: "Kunde bearbeiten",
          editSubaccount: "Unterkonto bearbeiten",
          fullName: "Vollständiger Name",
          address: "Adresse",
          city: "Stadt",
          country: "Land",
          postcode: "Postleitzahl",
          telephone: "Telefon",
          mobile: "Mobiltelefon",
          email: "E-Mail",
          occupation: "Beruf",
          company: "Firma",
          tin: "Steuer-ID",
          username: "Benutzername",
          cancel: "Abbrechen",
          submit: "Einreichen",
        },
        billingModal: {
          title: "Abrechnungseinstellungen",
          currency: "Währung",
          billingFrequency: "Abrechnungshäufigkeit",
          daysToPayInvoice: "Tage zur Bezahlung der Rechnung",
          minimumAmountToInvoice: "Mindestbetrag zur Rechnungsstellung",
          invoiceType: "Rechnungstyp",
          preferredLanguage: "Bevorzugte Sprache",
          billingEmail: "Abrechnungs-E-Mail",
          cancel: "Abbrechen",
          submit: "Einreichen",
        },
        whitelabelModal: {
          title: "Whitelabel-Einstellungen",
          enableWhitelabel: "Whitelabel aktivieren",
          pricePerMonth: "Preis pro Monat",
          url: "URL",
          cancel: "Abbrechen",
          submit: "Einreichen",
        },
        roleModal: {
          title: "Rolle aktualisieren",
          role: "Rolle",
          tenant: "Mieter",
          manager: "Manager",
          customer: "Kunde",
          cancel: "Abbrechen",
          submit: "Einreichen",
        },
        offersModal: {
          title: "Angebote zuweisen",
          availableOffers: "Verfügbare Angebote",
          assignedOffers: "Zugewiesene Angebote",
          submit: "Einreichen",
        },
      },
      sims: {
        title: "SIM-Karten",
        dataVolume: "Datenvolumen",
        dataSessions: "Datensitzungen",
        zeroSessions: "Null-Sitzungen",
        filters: "Filter",
        simStatus: "Aktive Sitzung",
        simState: "SIM-Status",
        imsi: "IMSI",
        iccid: "ICCID",
        msisdn: "MSISDN",
        offer: "Angebot",
        data: "Daten",
        min: "Min",
        max: "Max",
        search: "Suchen",
        clear: "Löschen",
        actions: "Aktionen",
        bulk: "Massenoperationen",
        modify: "Ändern",
        product: "Produkt",
        clearSelection: "Auswahl löschen",
        activate: "Aktivieren",
        suspend: "Aussetzen",
        resume: "Fortsetzen",
        terminate: "Beenden",
        testMode: "Testmodus",
        sendSms: "SMS senden",
        watchdogs: "Watchdogs",
        createWatchdog: "Watchdog erstellen",
        removeAllWatchdogs: "Alle Watchdogs entfernen",
        export: "Exportieren",
        simInformation: "SIM-Informationen",
        usage: "Nutzung",
        ipAddress: "IP-Adresse",
        imei: "IMEI",
        status: "Status",
        visitProfile: "Profil besuchen",
        provider: "Anbieter",
        area: "Bereich",
        cell: "Zelle",
        longitude: "Längengrad",
        latitude: "Breitengrad",
        cellRange: "Zellenreichweite",
        unit: "Einheit",
        sort: "Sortieren",
        suspendModal: {
          title: "SIM aussetzen",
          orderComplete: "SIM-Aussetzung abgeschlossen",
          orderError: "SIM-Aussetzung fehlgeschlagen",
          contactAdmin: "Bitte kontaktieren Sie den Systemadministrator",
          cancel: "Abbrechen",
          submit: "Einreichen",
          close: "Schließen",
        },
        resumeModal: {
          title: "SIM fortsetzen",
          orderComplete: "SIM-Fortsetzung abgeschlossen",
          orderError: "SIM-Fortsetzung fehlgeschlagen",
          contactAdmin: "Bitte kontaktieren Sie den Systemadministrator",
          cancel: "Abbrechen",
          submit: "Einreichen",
          close: "Schließen",
        },
        activateModal: {
          title: "SIM aktivieren",
          offer: "Angebot",
          selectOffer: "Angebot auswählen",
          success: "SIM-Aktivierung abgeschlossen",
          orderError: "SIM-Aktivierung fehlgeschlagen",
          contactAdmin: "Bitte kontaktieren Sie den Systemadministrator",
          cancel: "Abbrechen",
          submit: "Einreichen",
          close: "Schließen",
        },
        terminateModal: {
          title: "SIM beenden",
          targetState: "Gewünschter Status",
          onStock: "Auf Lager",
          terminated: "Beendet",
          success: "SIM-Beendigung abgeschlossen",
          orderError: "SIM-Beendigung fehlgeschlagen",
          contactAdmin: "Bitte kontaktieren Sie den Systemadministrator",
          cancel: "Abbrechen",
          submit: "Einreichen",
          close: "Schließen",
        },
      },
      simProfile: {
        simDetails: "SIM-Details",
        actions: "Aktionen",
        hlr: "HLR",
        session: "Sitzungen",
        smsUsage: "SMS-Nutzung",
        updateLoc: "Standort aktualisieren",
        imeiLock: "IMEI-Sperre",
        usage: "Nutzung",
        imei: "IMEI",
        status: "Status",
        dataSession: "Aktive Sitzung",
        map: "Karte",
        provider: "Anbieter",
        imsi: "IMSI",
        iccid: "ICCID",
        msisdn: "MSISDN",
        country: "Land",
        operator: "Netzbetreiber",
        area: "Bereich",
        cell: "Zelle",
        longitude: "Längengrad",
        latitude: "Breitengrad",
        cellRange: "Zellenreichweite",
        activity: "Aktivität",
        selectPeriod: "Zeitraum auswählen",
        daily: "Täglich",
        monthly: "Monatlich",
        yearly: "Jährlich",
        dataVolume: "Datenvolumen",
        dataSessions: "Datensitzungen",
        alerts: "Warnungen",
        zeroSessions: "Null-Sitzungen",
        watchdog: "Watchdog",
        type: "Typ",
        amount: "Betrag",
        hlrModal: {
          title: "HLR-Profil",
          selectScope: "Bereich auswählen",
          basicInfo: "Basisinformationen",
          locationInfo: "Standortinformationen",
          csBasicInfo: "CS Basisinformationen",
          gprsService2g3g: "GPRS-Dienste 2G/3G",
          telephonyService: "Telefondienste",
          supplementaryService: "Zusatzdienste",
          epcService4G: "EPC-Dienste 4G",
        },
        sessionModal: {
          title: "SIM-Datensitzungen",
          openDate: "Eröffnungsdatum",
          closeDate: "Schließdatum",
          consumption: "Verbrauch",
          roundedBytes: "Gerundete Bytes",
          operator: "Betreiber",
          cell: "Zelle",
          imei: "IMEI",
          close: "Schließen",
        },
        smsUsageModal: {
          title: "SMS-Nutzung",
          direction: "Richtung",
          date: "Datum",
          sender: "Absender",
          message: "Nachricht",
          status: "Status",
          close: "Schließen",
        },
      },
      profile: {
        profileDetails: "Profildetails",
        contactInformation: "Kontaktinformationen",
        professionalInformation: "Berufliche Informationen",
        activities: "Aktivitäten",
        loadMore: "Mehr laden",
      },
      billingProducts: {
        title: "Abrechnungsprodukte",
        name: "Name",
        pricePlan: "Preismodell",
        price: "Preis",
        number: "Nummer",
      },
      invoices: {
        title: "Rechnungen",
        invoiceNo: "Rechnungsnummer",
        date: "Datum",
        amount: "Betrag (inkl. MwSt.)",
        description: "Beschreibung",
        paid: "Bezahlt",
        download: "Herunterladen",
      },
      statements: {
        title: "Kontoauszüge",
        cutDate: "Abrechnungsdatum",
        description: "Beschreibung",
        amount: "Betrag (inkl. MwSt.)",
        balance: "Saldo",
      },
      orders: {
        title: "Bestellungen",
        open: "Offen",
        parked: "Geparkt",
        finished: "Abgeschlossen",
        error: "Fehler",
        noOrders: "Keine Bestellungen",
        showAllOrders: "Alle Bestellungen anzeigen",
      },
      emails: {
        filters: "Filter",
        category: "Kategorie",
        name: "Name",
        address: "Adresse",
        actions: "Aktionen",
        newEmail: "Neue E-Mail",
        edit: "Bearbeiten",
        delete: "Löschen",
        cancel: "Abbrechen",
        submit: "Einreichen",
        selectCategory: "Kategorie auswählen",
        invoice: "Rechnung",
        watchdog: "Watchdog",
        updateEmail: "E-Mail aktualisieren",
      },
      reports: {
        title: "Berichte",
        reportType: "Berichtstyp",
        usage: "Nutzung",
        groupBy: "Gruppieren nach",
        field: "Feld",
        usageType: "Nutzungsart",
        selectUsageType: "Nutzungsart auswählen",
        data: "Daten",
        sms: "SMS",
        all: "Alle",
        imsi: "IMSI",
        network: "Netzwerk",
        period: "Zeitraum",
        day: "Tag",
        month: "Monat",
        year: "Jahr",
        today: "Heute",
        "This Week": "Diese Woche",
        "This Month": "Dieser Monat",
        "This Year": "Dieses Jahr",
        "Last Month": "Letzter Monat",
        "Last Year": "Letztes Jahr",
        customer: "Kunde",
        select: "Auswählen",
        simSearchCriteria: "SIM-Suchkriterien",
        bySpecificImsi: "Nach spezifischer IMSI",
        byImsiRange: "Nach IMSI-Bereich",
        byIccid: "Nach ICCID",
        startImsi: "Start-IMSI",
        endImsi: "End-IMSI",
        iccid: "ICCID",
        view: "Ansehen",
        customerId: "Kunden-ID",
        date: "Datum",
        simType: "SIM-Typ",
        networkCode: "Netzcode",
        networkName: "Netzname",
        country: "Land",
        totalBytes: "Gesamtbytes",
        roundedBytes: "Gerundete Bytes",
        sessions: "Sitzungen",
        zeroSessions: "Null-Sitzungen",
        actions: "Aktionen",
        export: "Exportieren",
        automated: {
          title: "Automatisierte Berichte",
          name: "Name",
          type: "Typ",
          frequency: "Frequenz",
          nextReport: "Nächster Bericht",
          new: "Neuer automatisierter Bericht",
          save: "Speichern",
          daily: "Täglich",
          weekly: "Wöchentlich",
          monthly: "Monatlich",
          edit: "Bearbeiten",
          delete: "Löschen",
          actions: "Aktionen",
        },
        history: {
          title: "Berichtsverlauf",
          tableHeaders: {
            reportName: "Berichtsname",
            date: "Datum",
            status: "Status",
            download: "Herunterladen",
            actions: "Aktionen",
          },
          delete: "Löschen",
          completed: "Abgeschlossen",
          incomplete: "Unvollständig",
        },
      },
      alerts: {
        title: "Warnungen",
        filters: "Filter",
        alert: "Warnung",
        date: "Datum",
        imsi: "IMSI",
        enterValue: "Wert eingeben",
      },
      settings: {
        title: "Einstellungen",
        profileSettings: "Profileinstellungen",
        general: "Allgemein",
        account: "Konto",
        profileImage: "Profilbild",
        whitelabel: "Whitelabel",
        privacyAndSafety: "Datenschutz & Sicherheit",
        notifications: "Benachrichtigungen",
        billing: "Abrechnung",
        invoice: "Rechnung",
        generalSettings: {
          title: "Allgemeine Einstellungen",
          timeZone: "Zeitzone",
          dateFormat: "Datumsformat",
          timeFormat: "Zeitformat",
          save: "Speichern",
        },
        accountSettings: {
          title: "Kontoeinstellungen",
          fullName: "Vollständiger Name",
          address: "Adresse",
          city: "Stadt",
          country: "Land",
          postcode: "Postleitzahl",
          telephone: "Telefon",
          mobileNumber: "Mobilnummer",
          email: "E-Mail",
          occupation: "Beruf",
          company: "Firma",
          tin: "Steuer-ID",
          username: "Benutzername",
          changePassword: "Passwort ändern",
          password: "Passwort",
          confirmPassword: "Passwort bestätigen",
          save: "Speichern",
        },
        profileImageSettings: {
          title: "Profilbildeinstellungen",
          upload: "Hochladen",
          save: "Speichern",
        },
        whitelabelSettings: {
          title: "Whitelabel-Einstellungen",
          backgroundColor: "Hintergrundfarbe",
          fontColor: "Schriftfarbe",
          sidebarPosition: "Seitenleistenposition",
          favicon: "Favicon",
          whitelabelImage: "Whitelabel-Bild",
          upload: "Hochladen",
          save: "Speichern",
        },
        privacySettings: {
          title: "Datenschutzeinstellungen",
          enable2FA: "2FA aktivieren",
          save: "Speichern",
        },
        notificationSettings: {
          title: "Benachrichtigungseinstellungen",
          receiveNotifications: "Benachrichtigungen erhalten",
          sms: "SMS",
          email: "E-Mail",
          weeklyAnalytics: "Wöchentliche Analysen",
          checkIfYouWantToReceiveWeeklyAnalytics:
            "Aktivieren Sie diese Option, wenn Sie wöchentliche Analysen erhalten möchten",
          notifyAboutNewAlerts: "Über neue Warnungen benachrichtigen",
          checkIfYouWantToReceiveNotificationsWhenThereAreNewAlerts:
            "Aktivieren Sie diese Option, wenn Sie Benachrichtigungen bei neuen Warnungen erhalten möchten",
          submit: "Einreichen",
        },
        billingSettings: {
          title: "Abrechnungseinstellungen",
          selectCurrency: "Währung auswählen",
          currency: "Währung",
          preferredLanguage: "Bevorzugte Sprache",
          selectLanguage: "Sprache auswählen",
          submit: "Einreichen",
        },
        invoiceSettings: {
          title: "Rechnungseinstellungen",
          name: "Name",
          showGuis: "GUIs anzeigen",
          showSubtotal: "Zwischensumme anzeigen",
          showLineOfService: "Dienstleistungslinie anzeigen",
          save: "Speichern",
        },
      },
      privileges: {
        title: "Berechtigungen",
        name: "Name",
        actions: "Aktionen",
        newPrivilege: "Neue Berechtigung",
        edit: "Bearbeiten",
        delete: "Löschen",
      },
      table: {
        page: "Seite",
        of: "von",
        show: "Anzeigen",
        goToPage: "Gehe zu Seite",
      },
      notifications: {
        title: "Benachrichtigungen",
        id: "ID",
        date: "Datum",
        message: "Nachricht",
        type: "Typ",
      },
    },
  },
  nl: {
    translation: {
      sidebar: {
        dashboard: "Dashboard",
        crm: "CRM",
        admins: "Beheerders",
        tenants: "Huurders",
        managers: "Managers",
        customers: "Klanten",
        subaccounts: "Subaccounts",
        sims: "SIM-kaarten",
        account: "Account",
        profile: "Profiel",
        billingProducts: "Factureringsproducten",
        invoices: "Facturen",
        statements: "Rekeningen",
        orders: "Bestellingen",
        emails: "E-mails",
        reports: "Rapporten",
        generateReport: "Genereer rapport",
        automatedReports: "Geautomatiseerde rapporten",
        reportHistory: "Rapportgeschiedenis",
        alerts: "Waarschuwingen",
        notifications: "Meldingen",
        support: "Ondersteuning",
        privileges: "Rechten",
        settings: "Instellingen",
        services: "Diensten",
        documentation: "Documentatie",
      },
      dashboard: {
        dataVolume: "Datavolume",
        dataSessions: "Datasessies",
        alerts: "Waarschuwingen",
        zeroSessions: "Nulsessies",
        sinceYesterday: "Sinds gisteren",
        sinceLastMonth: "Sinds vorige maand",
        sinceLastYear: "Sinds vorig jaar",
        averageVolume: "Gemiddeld datavolume",
        activeSims: "Actieve SIM-kaarten",
        dataVolumePerHour: "Datavolume per uur",
        topMetrics: "Top statistieken",
        simCardsPerOffer: "SIM-kaarten per aanbieding",
        simInventory: "SIM-verdeling per status",
        dataVolumePerNetwork: "Datavolume per netwerk",
        selectCountry: "Land selecteren",
        networksDistribution: "Actieve SIM-kaarten per netwerkverdeling",
        watchdogAlerts: "Verbruikswaarschuwingen",
        noData: "Geen gegevens beschikbaar",
        daily: "Dagelijks",
        monthly: "Maandelijks",
        yearly: "Jaarlijks",
      },
      crm: {
        admins: "Beheerders",
        tenants: "Huurders",
        managers: "Managers",
        customers: "Klanten",
        subaccounts: "Subaccounts",
        filters: "Filters",
        fullName: "Volledige naam",
        company: "Bedrijf",
        email: "E-mail",
        address: "Adres",
        city: "Stad",
        country: "Land",
        postcode: "Postcode",
        telephone: "Telefoon",
        mobile: "Mobiel",
        occupation: "Beroep",
        tin: "Belastingnummer",
        username: "Gebruikersnaam",
        role: "Rol",
        actions: "Acties",
        edit: "Bewerken",
        delete: "Verwijderen",
        billing: "Facturering",
        whitelabeling: "Witlabel",
        offers: "Aanbiedingen",
        impersonate: "Imiteren",
        permissions: "Rechten",
        sendWelcomeEmail: "Verwelkomingsmail versturen",
        resetPassword: "Wachtwoord resetten",
        initializeSIMs: "SIM-kaarten initialiseren",
        initializeAlerts: "Waarschuwingen initialiseren",
        newTenant: "Nieuwe huurder",
        newManager: "Nieuwe manager",
        newCustomer: "Nieuwe klant",
        newSubaccount: "Nieuw subaccount",
        newModal: {
          newTenant: "Huurder aanmaken",
          newManager: "Manager aanmaken",
          newCustomer: "Klant aanmaken",
          newSubaccount: "Subaccount aanmaken",
          fullName: "Volledige naam",
          address: "Adres",
          city: "Stad",
          country: "Land",
          postcode: "Postcode",
          telephone: "Telefoon",
          mobile: "Mobiel",
          email: "E-mail",
          occupation: "Beroep",
          company: "Bedrijf",
          tin: "Belastingnummer",
          username: "Gebruikersnaam",
          sendWelcomeEmail: "Verwelkomingsmail versturen",
          cancel: "Annuleren",
          submit: "Verzenden",
          next: "Volgende",
          previous: "Vorige",
        },
        editModal: {
          editTenant: "Huurder bewerken",
          editManager: "Manager bewerken",
          editCustomer: "Klant bewerken",
          editSubaccount: "Subaccount bewerken",
          fullName: "Volledige naam",
          address: "Adres",
          city: "Stad",
          country: "Land",
          postcode: "Postcode",
          telephone: "Telefoon",
          mobile: "Mobiel",
          email: "E-mail",
          occupation: "Beroep",
          company: "Bedrijf",
          tin: "Belastingnummer",
          username: "Gebruikersnaam",
          cancel: "Annuleren",
          submit: "Verzenden",
        },
        billingModal: {
          title: "Factureringsinstellingen",
          currency: "Valuta",
          billingFrequency: "Factureringsfrequentie",
          daysToPayInvoice: "Dagen om factuur te betalen",
          minimumAmountToInvoice: "Minimaal factuurbedrag",
          invoiceType: "Factuurtype",
          preferredLanguage: "Voorkeurstaal",
          billingEmail: "Facturatie e-mail",
          cancel: "Annuleren",
          submit: "Verzenden",
        },
        whitelabelModal: {
          title: "Witlabel-instellingen",
          enableWhitelabel: "Witlabel inschakelen",
          pricePerMonth: "Prijs per maand",
          url: "URL",
          cancel: "Annuleren",
          submit: "Verzenden",
        },
        roleModal: {
          title: "Rol bijwerken",
          role: "Rol",
          tenant: "Huurder",
          manager: "Manager",
          customer: "Klant",
          cancel: "Annuleren",
          submit: "Verzenden",
        },
        offersModal: {
          title: "Aanbiedingen toewijzen",
          availableOffers: "Beschikbare aanbiedingen",
          assignedOffers: "Toegewezen aanbiedingen",
          submit: "Verzenden",
        },
      },
      sims: {
        title: "SIM-kaarten",
        dataVolume: "Datavolume",
        dataSessions: "Datasessies",
        zeroSessions: "Nulsessies",
        filters: "Filters",
        simStatus: "Actieve sessie",
        simState: "SIM-status",
        imsi: "IMSI",
        iccid: "ICCID",
        msisdn: "MSISDN",
        offer: "Aanbieding",
        data: "Data",
        min: "Min",
        max: "Max",
        search: "Zoeken",
        clear: "Wissen",
        actions: "Acties",
        bulk: "Bulkacties",
        modify: "Wijzigen",
        product: "Product",
        clearSelection: "Selectie wissen",
        activate: "Activeren",
        suspend: "Schorsen",
        resume: "Hervatten",
        terminate: "Beëindigen",
        testMode: "Testmodus",
        sendSms: "SMS versturen",
        watchdogs: "Watchdogs",
        createWatchdog: "Watchdog aanmaken",
        removeAllWatchdogs: "Verwijder alle Watchdogs",
        export: "Exporteren",
        simInformation: "SIM-informatie",
        usage: "Verbruik",
        ipAddress: "IP-adres",
        imei: "IMEI",
        status: "Status",
        visitProfile: "Profiel bezoeken",
        provider: "Provider",
        area: "Gebied",
        cell: "Cel",
        longitude: "Lengtegraad",
        latitude: "Breedtegraad",
        cellRange: "Cell bereik",
        unit: "Eenheid",
        sort: "Sorteren",
        suspendModal: {
          title: "SIM schorsen",
          orderComplete: "SIM-schorsing voltooid",
          orderError: "SIM-schorsing mislukt",
          contactAdmin: "Neem contact op met de systeembeheerder",
          cancel: "Annuleren",
          submit: "Verzenden",
          close: "Sluiten",
        },
        resumeModal: {
          title: "SIM hervatten",
          orderComplete: "SIM-hervatting voltooid",
          orderError: "SIM-hervatting mislukt",
          contactAdmin: "Neem contact op met de systeembeheerder",
          cancel: "Annuleren",
          submit: "Verzenden",
          close: "Sluiten",
        },
        activateModal: {
          title: "SIM activeren",
          offer: "Aanbieding",
          selectOffer: "Selecteer aanbieding",
          success: "SIM-activatie voltooid",
          orderError: "SIM-activatie mislukt",
          contactAdmin: "Neem contact op met de systeembeheerder",
          cancel: "Annuleren",
          submit: "Verzenden",
          close: "Sluiten",
        },
        terminateModal: {
          title: "SIM beëindigen",
          targetState: "Gewenste status",
          onStock: "Op voorraad",
          terminated: "Beëindigd",
          success: "SIM-beëindiging voltooid",
          orderError: "SIM-beëindiging mislukt",
          contactAdmin: "Neem contact op met de systeembeheerder",
          cancel: "Annuleren",
          submit: "Verzenden",
          close: "Sluiten",
        },
      },
      simProfile: {
        simDetails: "SIM-details",
        actions: "Acties",
        hlr: "HLR",
        session: "Sessies",
        smsUsage: "SMS-verbruik",
        updateLoc: "Locatie bijwerken",
        imeiLock: "IMEI-vergrendeling",
        usage: "Verbruik",
        imei: "IMEI",
        status: "Status",
        dataSession: "Actieve sessie",
        map: "Kaart",
        provider: "Provider",
        imsi: "IMSI",
        iccid: "ICCID",
        msisdn: "MSISDN",
        country: "Land",
        operator: "Netwerkoperator",
        area: "Gebied",
        cell: "Cel",
        longitude: "Lengtegraad",
        latitude: "Breedtegraad",
        cellRange: "Cell bereik",
        activity: "Activiteit",
        selectPeriod: "Periode selecteren",
        daily: "Dagelijks",
        monthly: "Maandelijks",
        yearly: "Jaarlijks",
        dataVolume: "Datavolume",
        dataSessions: "Datasessies",
        alerts: "Waarschuwingen",
        zeroSessions: "Nulsessies",
        watchdog: "Watchdog",
        type: "Type",
        amount: "Bedrag",
        hlrModal: {
          title: "HLR-profiel",
          selectScope: "Bereik selecteren",
          basicInfo: "Basisinformatie",
          locationInfo: "Locatie-informatie",
          csBasicInfo: "CS Basisinformatie",
          gprsService2g3g: "GPRS-diensten 2G/3G",
          telephonyService: "Telefoondiensten",
          supplementaryService: "Aanvullende diensten",
          epcService4G: "EPC-diensten 4G",
        },
        sessionModal: {
          title: "SIM-datasessies",
          openDate: "Openingsdatum",
          closeDate: "Sluitingsdatum",
          consumption: "Verbruik",
          roundedBytes: "Afgeronde bytes",
          operator: "Operator",
          cell: "Cel",
          imei: "IMEI",
          close: "Sluiten",
        },
        smsUsageModal: {
          title: "SMS-verbruik",
          direction: "Richting",
          date: "Datum",
          sender: "Afzender",
          message: "Bericht",
          status: "Status",
          close: "Sluiten",
        },
      },
      profile: {
        profileDetails: "Profielgegevens",
        contactInformation: "Contactinformatie",
        professionalInformation: "Professionele informatie",
        activities: "Activiteiten",
        loadMore: "Meer laden",
      },
      billingProducts: {
        title: "Factureringsproducten",
        name: "Naam",
        pricePlan: "Prijsplan",
        price: "Prijs",
        number: "Nummer",
      },
      invoices: {
        title: "Facturen",
        invoiceNo: "Factuurnummer",
        date: "Datum",
        amount: "Bedrag (incl. BTW)",
        description: "Beschrijving",
        paid: "Betaald",
        download: "Downloaden",
      },
      statements: {
        title: "Rekeningen",
        cutDate: "Knipdatum",
        description: "Beschrijving",
        amount: "Bedrag (incl. BTW)",
        balance: "Saldo",
      },
      orders: {
        title: "Bestellingen",
        open: "Open",
        parked: "Gepauzeerd",
        finished: "Voltooid",
        error: "Fout",
        noOrders: "Geen bestellingen",
        showAllOrders: "Toon alle bestellingen",
      },
      emails: {
        filters: "Filters",
        category: "Categorie",
        name: "Naam",
        address: "Adres",
        actions: "Acties",
        newEmail: "Nieuwe e-mail",
        edit: "Bewerken",
        delete: "Verwijderen",
        cancel: "Annuleren",
        submit: "Verzenden",
        selectCategory: "Categorie selecteren",
        invoice: "Factuur",
        watchdog: "Watchdog",
        updateEmail: "E-mail bijwerken",
      },
      reports: {
        title: "Rapporten",
        reportType: "Rapporttype",
        usage: "Verbruik",
        groupBy: "Groeperen op",
        field: "Veld",
        usageType: "Verbruikstype",
        selectUsageType: "Verbruikstype selecteren",
        data: "Data",
        sms: "SMS",
        all: "Alle",
        imsi: "IMSI",
        network: "Netwerk",
        period: "Periode",
        day: "Dag",
        month: "Maand",
        year: "Jaar",
        today: "Vandaag",
        "This Week": "Deze week",
        "This Month": "Deze maand",
        "This Year": "Dit jaar",
        "Last Month": "Vorige maand",
        "Last Year": "Vorig jaar",
        customer: "Klant",
        select: "Selecteren",
        simSearchCriteria: "SIM-zoekcriteria",
        bySpecificImsi: "Op specifieke IMSI",
        byImsiRange: "Op IMSI-bereik",
        byIccid: "Op ICCID",
        startImsi: "Start-IMSI",
        endImsi: "Eind-IMSI",
        iccid: "ICCID",
        view: "Weergeven",
        customerId: "Klant-ID",
        date: "Datum",
        simType: "SIM-type",
        networkCode: "Netwerkcode",
        networkName: "Netwerknaam",
        country: "Land",
        totalBytes: "Totaal bytes",
        roundedBytes: "Afgeronde bytes",
        sessions: "Sessies",
        zeroSessions: "Nulsessies",
        actions: "Acties",
        export: "Exporteren",
        automated: {
          title: "Geautomatiseerde rapporten",
          name: "Naam",
          type: "Type",
          frequency: "Frequentie",
          nextReport: "Volgend rapport",
          new: "Nieuw geautomatiseerd rapport",
          save: "Opslaan",
          daily: "Dagelijks",
          weekly: "Wekelijks",
          monthly: "Maandelijks",
          edit: "Bewerken",
          delete: "Verwijderen",
          actions: "Acties",
        },
        history: {
          title: "Rapportgeschiedenis",
          tableHeaders: {
            reportName: "Rapportnaam",
            date: "Datum",
            status: "Status",
            download: "Downloaden",
            actions: "Acties",
          },
          delete: "Verwijderen",
          completed: "Voltooid",
          incomplete: "Onvolledig",
        },
      },
      alerts: {
        title: "Waarschuwingen",
        filters: "Filters",
        alert: "Waarschuwing",
        date: "Datum",
        imsi: "IMSI",
        enterValue: "Waarde invoeren",
      },
      settings: {
        title: "Instellingen",
        profileSettings: "Profielinstellingen",
        general: "Algemeen",
        account: "Account",
        profileImage: "Profielfoto",
        whitelabel: "Witlabel",
        privacyAndSafety: "Privacy & Veiligheid",
        notifications: "Meldingen",
        billing: "Facturering",
        invoice: "Factuur",
        generalSettings: {
          title: "Algemene instellingen",
          timeZone: "Tijdzone",
          dateFormat: "Datumnotatie",
          timeFormat: "Tijdnotatie",
          save: "Opslaan",
        },
        accountSettings: {
          title: "Accountinstellingen",
          fullName: "Volledige naam",
          address: "Adres",
          city: "Stad",
          country: "Land",
          postcode: "Postcode",
          telephone: "Telefoon",
          mobileNumber: "Mobiel nummer",
          email: "E-mail",
          occupation: "Beroep",
          company: "Bedrijf",
          tin: "Belastingnummer",
          username: "Gebruikersnaam",
          changePassword: "Wachtwoord wijzigen",
          password: "Wachtwoord",
          confirmPassword: "Wachtwoord bevestigen",
          save: "Opslaan",
        },
        profileImageSettings: {
          title: "Profielfotoinstellingen",
          upload: "Uploaden",
          save: "Opslaan",
        },
        whitelabelSettings: {
          title: "Witlabel-instellingen",
          backgroundColor: "Achtergrondkleur",
          fontColor: "Letterkleur",
          sidebarPosition: "Zijbalkpositie",
          favicon: "Favicon",
          whitelabelImage: "Witlabel-afbeelding",
          upload: "Uploaden",
          save: "Opslaan",
        },
        privacySettings: {
          title: "Privacy-instellingen",
          enable2FA: "2FA inschakelen",
          save: "Opslaan",
        },
        notificationSettings: {
          title: "Meldingsinstellingen",
          receiveNotifications: "Meldingen ontvangen",
          sms: "SMS",
          email: "E-mail",
          weeklyAnalytics: "Wekelijkse analyses",
          checkIfYouWantToReceiveWeeklyAnalytics:
            "Aanvinken als u wekelijks analyses wilt ontvangen",
          notifyAboutNewAlerts: "Melden over nieuwe waarschuwingen",
          checkIfYouWantToReceiveNotificationsWhenThereAreNewAlerts:
            "Aanvinken als u meldingen wilt ontvangen bij nieuwe waarschuwingen",
          submit: "Verzenden",
        },
        billingSettings: {
          title: "Factureringsinstellingen",
          selectCurrency: "Valuta selecteren",
          currency: "Valuta",
          preferredLanguage: "Voorkeurstaal",
          selectLanguage: "Taal selecteren",
          submit: "Verzenden",
        },
        invoiceSettings: {
          title: "Factuurinstellingen",
          name: "Naam",
          showGuis: "GUI's weergeven",
          showSubtotal: "Subtotaal weergeven",
          showLineOfService: "Dienstregel weergeven",
          save: "Opslaan",
        },
      },
      privileges: {
        title: "Rechten",
        name: "Naam",
        actions: "Acties",
        newPrivilege: "Nieuw recht",
        edit: "Bewerken",
        delete: "Verwijderen",
      },
      table: {
        page: "Pagina",
        of: "van",
        show: "Weergeven",
        goToPage: "Ga naar pagina",
      },
      notifications: {
        title: "Meldingen",
        id: "ID",
        date: "Datum",
        message: "Bericht",
        type: "Type",
      },
    },
  },
  it: {
    translation: {
      sidebar: {
        dashboard: "Dashboard",
        crm: "CRM",
        admins: "Amministratori",
        tenants: "Inquilini",
        managers: "Manager",
        customers: "Clienti",
        subaccounts: "Sottoconti",
        sims: "Carte SIM",
        account: "Account",
        profile: "Profilo",
        billingProducts: "Prodotti di fatturazione",
        invoices: "Fatture",
        statements: "Estratti conto",
        orders: "Ordini",
        emails: "Email",
        reports: "Report",
        generateReport: "Genera report",
        automatedReports: "Report automatizzati",
        reportHistory: "Cronologia report",
        alerts: "Avvisi",
        notifications: "Notifiche",
        support: "Supporto",
        privileges: "Privilegi",
        settings: "Impostazioni",
        services: "Servizi",
        documentation: "Documentazione",
      },
      dashboard: {
        dataVolume: "Volume dati",
        dataSessions: "Sessioni dati",
        alerts: "Avvisi",
        zeroSessions: "Sessioni zero",
        sinceYesterday: "Da ieri",
        sinceLastMonth: "Dallo scorso mese",
        sinceLastYear: "Dallo scorso anno",
        averageVolume: "Volume dati medio",
        activeSims: "SIM attive",
        dataVolumePerHour: "Volume dati per ora",
        topMetrics: "Migliori metriche",
        simCardsPerOffer: "SIM per offerta",
        simInventory: "Distribuzione SIM per stato",
        dataVolumePerNetwork: "Volume dati per rete",
        selectCountry: "Seleziona Paese",
        networksDistribution: "Distribuzione SIM attive per rete",
        watchdogAlerts: "Avvisi di consumo",
        noData: "Nessun dato disponibile",
        daily: "Giornaliero",
        monthly: "Mensile",
        yearly: "Annuale",
      },
      crm: {
        admins: "Amministratori",
        tenants: "Inquilini",
        managers: "Manager",
        customers: "Clienti",
        subaccounts: "Sottoconti",
        filters: "Filtri",
        fullName: "Nome completo",
        company: "Azienda",
        email: "Email",
        address: "Indirizzo",
        city: "Città",
        country: "Paese",
        postcode: "CAP",
        telephone: "Telefono",
        mobile: "Cellulare",
        occupation: "Occupazione",
        tin: "Codice fiscale",
        username: "Nome utente",
        role: "Ruolo",
        actions: "Azioni",
        edit: "Modifica",
        delete: "Elimina",
        billing: "Fatturazione",
        whitelabeling: "Whitelabel",
        offers: "Offerte",
        impersonate: "Impersona",
        permissions: "Permessi",
        sendWelcomeEmail: "Invia email di benvenuto",
        resetPassword: "Reimposta password",
        initializeSIMs: "Inizializza SIM",
        initializeAlerts: "Inizializza avvisi",
        newTenant: "Nuovo inquilino",
        newManager: "Nuovo manager",
        newCustomer: "Nuovo cliente",
        newSubaccount: "Nuovo sottoconto",
        newModal: {
          newTenant: "Crea inquilino",
          newManager: "Crea manager",
          newCustomer: "Crea cliente",
          newSubaccount: "Crea sottoconto",
          fullName: "Nome completo",
          address: "Indirizzo",
          city: "Città",
          country: "Paese",
          postcode: "CAP",
          telephone: "Telefono",
          mobile: "Cellulare",
          email: "Email",
          occupation: "Occupazione",
          company: "Azienda",
          tin: "Codice fiscale",
          username: "Nome utente",
          sendWelcomeEmail: "Invia email di benvenuto",
          cancel: "Annulla",
          submit: "Invia",
          next: "Avanti",
          previous: "Indietro",
        },
        editModal: {
          editTenant: "Modifica inquilino",
          editManager: "Modifica manager",
          editCustomer: "Modifica cliente",
          editSubaccount: "Modifica sottoconto",
          fullName: "Nome completo",
          address: "Indirizzo",
          city: "Città",
          country: "Paese",
          postcode: "CAP",
          telephone: "Telefono",
          mobile: "Cellulare",
          email: "Email",
          occupation: "Occupazione",
          company: "Azienda",
          tin: "Codice fiscale",
          username: "Nome utente",
          cancel: "Annulla",
          submit: "Invia",
        },
        billingModal: {
          title: "Impostazioni di fatturazione",
          currency: "Valuta",
          billingFrequency: "Frequenza di fatturazione",
          daysToPayInvoice: "Giorni per pagare la fattura",
          minimumAmountToInvoice: "Importo minimo per fatturare",
          invoiceType: "Tipo di fattura",
          preferredLanguage: "Lingua preferita",
          billingEmail: "Email per la fatturazione",
          cancel: "Annulla",
          submit: "Invia",
        },
        whitelabelModal: {
          title: "Impostazioni Whitelabel",
          enableWhitelabel: "Abilita Whitelabel",
          pricePerMonth: "Prezzo per mese",
          url: "URL",
          cancel: "Annulla",
          submit: "Invia",
        },
        roleModal: {
          title: "Aggiorna ruolo",
          role: "Ruolo",
          tenant: "Inquilino",
          manager: "Manager",
          customer: "Cliente",
          cancel: "Annulla",
          submit: "Invia",
        },
        offersModal: {
          title: "Assegna offerte",
          availableOffers: "Offerte disponibili",
          assignedOffers: "Offerte assegnate",
          submit: "Invia",
        },
      },
      sims: {
        title: "Carte SIM",
        dataVolume: "Volume dati",
        dataSessions: "Sessioni dati",
        zeroSessions: "Sessioni zero",
        filters: "Filtri",
        simStatus: "Sessione attiva",
        simState: "Stato SIM",
        imsi: "IMSI",
        iccid: "ICCID",
        msisdn: "MSISDN",
        offer: "Offerta",
        data: "Dati",
        min: "Min",
        max: "Max",
        search: "Cerca",
        clear: "Pulisci",
        actions: "Azioni",
        bulk: "Operazioni collettive",
        modify: "Modifica",
        product: "Prodotto",
        clearSelection: "Pulisci selezione",
        activate: "Attiva",
        suspend: "Sospendi",
        resume: "Riprendi",
        terminate: "Termina",
        testMode: "Modalità test",
        sendSms: "Invia SMS",
        watchdogs: "Watchdogs",
        createWatchdog: "Crea Watchdog",
        removeAllWatchdogs: "Rimuovi tutti i Watchdog",
        export: "Esporta",
        simInformation: "Informazioni SIM",
        usage: "Utilizzo",
        ipAddress: "Indirizzo IP",
        imei: "IMEI",
        status: "Stato",
        visitProfile: "Visita profilo",
        provider: "Provider",
        area: "Area",
        cell: "Cella",
        longitude: "Longitudine",
        latitude: "Latitudine",
        cellRange: "Raggio cella",
        unit: "Unità",
        sort: "Ordina",
        suspendModal: {
          title: "Sospendi SIM",
          orderComplete: "Sospensione della SIM completata",
          orderError: "Sospensione della SIM fallita",
          contactAdmin: "Contatta l'amministratore di sistema",
          cancel: "Annulla",
          submit: "Invia",
          close: "Chiudi",
        },
        resumeModal: {
          title: "Riprendi SIM",
          orderComplete: "Ripresa della SIM completata",
          orderError: "Ripresa della SIM fallita",
          contactAdmin: "Contatta l'amministratore di sistema",
          cancel: "Annulla",
          submit: "Invia",
          close: "Chiudi",
        },
        activateModal: {
          title: "Attiva SIM",
          offer: "Offerta",
          selectOffer: "Seleziona offerta",
          success: "Attivazione della SIM completata",
          orderError: "Attivazione della SIM fallita",
          contactAdmin: "Contatta l'amministratore di sistema",
          cancel: "Annulla",
          submit: "Invia",
          close: "Chiudi",
        },
        terminateModal: {
          title: "Termina SIM",
          targetState: "Stato desiderato",
          onStock: "In stock",
          terminated: "Terminata",
          success: "Terminazione della SIM completata",
          orderError: "Terminazione della SIM fallita",
          contactAdmin: "Contatta l'amministratore di sistema",
          cancel: "Annulla",
          submit: "Invia",
          close: "Chiudi",
        },
      },
      simProfile: {
        simDetails: "Dettagli SIM",
        actions: "Azioni",
        hlr: "HLR",
        session: "Sessioni",
        smsUsage: "Utilizzo SMS",
        updateLoc: "Aggiorna posizione",
        imeiLock: "Blocco IMEI",
        usage: "Utilizzo",
        imei: "IMEI",
        status: "Stato",
        dataSession: "Sessione attiva",
        map: "Mappa",
        provider: "Provider",
        imsi: "IMSI",
        iccid: "ICCID",
        msisdn: "MSISDN",
        country: "Paese",
        operator: "Operatore di rete",
        area: "Area",
        cell: "Cella",
        longitude: "Longitudine",
        latitude: "Latitudine",
        cellRange: "Raggio cella",
        activity: "Attività",
        selectPeriod: "Seleziona periodo",
        daily: "Giornaliero",
        monthly: "Mensile",
        yearly: "Annuale",
        dataVolume: "Volume dati",
        dataSessions: "Sessioni dati",
        alerts: "Avvisi",
        zeroSessions: "Sessioni zero",
        watchdog: "Watchdog",
        type: "Tipo",
        amount: "Quantità",
        hlrModal: {
          title: "Profilo HLR",
          selectScope: "Seleziona ambito",
          basicInfo: "Informazioni di base",
          locationInfo: "Informazioni sulla posizione",
          csBasicInfo: "Informazioni di base CS",
          gprsService2g3g: "Servizi GPRS 2G/3G",
          telephonyService: "Servizi di telefonia",
          supplementaryService: "Servizi supplementari",
          epcService4G: "Servizi EPC 4G",
        },
        sessionModal: {
          title: "Sessioni dati SIM",
          openDate: "Data di apertura",
          closeDate: "Data di chiusura",
          consumption: "Consumo",
          roundedBytes: "Byte arrotondati",
          operator: "Operatore",
          cell: "Cella",
          imei: "IMEI",
          close: "Chiudi",
        },
        smsUsageModal: {
          title: "Utilizzo SMS",
          direction: "Direzione",
          date: "Data",
          sender: "Mittente",
          message: "Messaggio",
          status: "Stato",
          close: "Chiudi",
        },
      },
      profile: {
        profileDetails: "Dettagli profilo",
        contactInformation: "Informazioni di contatto",
        professionalInformation: "Informazioni professionali",
        activities: "Attività",
        loadMore: "Carica altro",
      },
      billingProducts: {
        title: "Prodotti di fatturazione",
        name: "Nome",
        pricePlan: "Piano tariffario",
        price: "Prezzo",
        number: "Numero",
      },
      invoices: {
        title: "Fatture",
        invoiceNo: "Numero fattura",
        date: "Data",
        amount: "Importo (incl. IVA)",
        description: "Descrizione",
        paid: "Pagata",
        download: "Scarica",
      },
      statements: {
        title: "Estratti conto",
        cutDate: "Data di taglio",
        description: "Descrizione",
        amount: "Importo (incl. IVA)",
        balance: "Saldo",
      },
      orders: {
        title: "Ordini",
        open: "Aperti",
        parked: "In sospeso",
        finished: "Completati",
        error: "Errore",
        noOrders: "Nessun ordine",
        showAllOrders: "Mostra tutti gli ordini",
      },
      emails: {
        filters: "Filtri",
        category: "Categoria",
        name: "Nome",
        address: "Indirizzo",
        actions: "Azioni",
        newEmail: "Nuova email",
        edit: "Modifica",
        delete: "Elimina",
        cancel: "Annulla",
        submit: "Invia",
        selectCategory: "Seleziona categoria",
        invoice: "Fattura",
        watchdog: "Watchdog",
        updateEmail: "Aggiorna email",
      },
      reports: {
        title: "Report",
        reportType: "Tipo di report",
        usage: "Utilizzo",
        groupBy: "Raggruppa per",
        field: "Campo",
        usageType: "Tipo di utilizzo",
        selectUsageType: "Seleziona tipo di utilizzo",
        data: "Dati",
        sms: "SMS",
        all: "Tutti",
        imsi: "IMSI",
        network: "Rete",
        period: "Periodo",
        day: "Giorno",
        month: "Mese",
        year: "Anno",
        today: "Oggi",
        "This Week": "Questa settimana",
        "This Month": "Questo mese",
        "This Year": "Quest'anno",
        "Last Month": "Il mese scorso",
        "Last Year": "L'anno scorso",
        customer: "Cliente",
        select: "Seleziona",
        simSearchCriteria: "Criteri di ricerca SIM",
        bySpecificImsi: "Per IMSI specifica",
        byImsiRange: "Per intervallo IMSI",
        byIccid: "Per ICCID",
        startImsi: "IMSI iniziale",
        endImsi: "IMSI finale",
        iccid: "ICCID",
        view: "Visualizza",
        customerId: "ID cliente",
        date: "Data",
        simType: "Tipo di SIM",
        networkCode: "Codice rete",
        networkName: "Nome rete",
        country: "Paese",
        totalBytes: "Byte totali",
        roundedBytes: "Byte arrotondati",
        sessions: "Sessioni",
        zeroSessions: "Sessioni zero",
        actions: "Azioni",
        export: "Esporta",
        automated: {
          title: "Report automatizzati",
          name: "Nome",
          type: "Tipo",
          frequency: "Frequenza",
          nextReport: "Prossimo report",
          new: "Nuovo report automatizzato",
          save: "Salva",
          daily: "Giornaliero",
          weekly: "Settimanale",
          monthly: "Mensile",
          edit: "Modifica",
          delete: "Elimina",
          actions: "Azioni",
        },
        history: {
          title: "Cronologia report",
          tableHeaders: {
            reportName: "Nome report",
            date: "Data",
            status: "Stato",
            download: "Scarica",
            actions: "Azioni",
          },
          delete: "Elimina",
          completed: "Completato",
          incomplete: "Incompleto",
        },
      },
      alerts: {
        title: "Avvisi",
        filters: "Filtri",
        alert: "Avviso",
        date: "Data",
        imsi: "IMSI",
        enterValue: "Inserisci valore",
      },
      settings: {
        title: "Impostazioni",
        profileSettings: "Impostazioni profilo",
        general: "Generale",
        account: "Account",
        profileImage: "Immagine del profilo",
        whitelabel: "Whitelabel",
        privacyAndSafety: "Privacy e sicurezza",
        notifications: "Notifiche",
        billing: "Fatturazione",
        invoice: "Fattura",
        generalSettings: {
          title: "Impostazioni generali",
          timeZone: "Fuso orario",
          dateFormat: "Formato data",
          timeFormat: "Formato orario",
          save: "Salva",
        },
        accountSettings: {
          title: "Impostazioni account",
          fullName: "Nome completo",
          address: "Indirizzo",
          city: "Città",
          country: "Paese",
          postcode: "CAP",
          telephone: "Telefono",
          mobileNumber: "Numero di cellulare",
          email: "Email",
          occupation: "Occupazione",
          company: "Azienda",
          tin: "Codice fiscale",
          username: "Nome utente",
          changePassword: "Cambia password",
          password: "Password",
          confirmPassword: "Conferma password",
          save: "Salva",
        },
        profileImageSettings: {
          title: "Impostazioni immagine del profilo",
          upload: "Carica",
          save: "Salva",
        },
        whitelabelSettings: {
          title: "Impostazioni Whitelabel",
          backgroundColor: "Colore di sfondo",
          fontColor: "Colore del carattere",
          sidebarPosition: "Posizione della barra laterale",
          favicon: "Favicon",
          whitelabelImage: "Immagine Whitelabel",
          upload: "Carica",
          save: "Salva",
        },
        privacySettings: {
          title: "Impostazioni privacy",
          enable2FA: "Abilita 2FA",
          save: "Salva",
        },
        notificationSettings: {
          title: "Impostazioni di notifica",
          receiveNotifications: "Ricevi notifiche",
          sms: "SMS",
          email: "Email",
          weeklyAnalytics: "Analisi settimanale",
          checkIfYouWantToReceiveWeeklyAnalytics:
            "Seleziona se desideri ricevere analisi settimanali",
          notifyAboutNewAlerts: "Notifica per nuovi avvisi",
          checkIfYouWantToReceiveNotificationsWhenThereAreNewAlerts:
            "Seleziona se desideri ricevere notifiche per nuovi avvisi",
          submit: "Invia",
        },
        billingSettings: {
          title: "Impostazioni di fatturazione",
          selectCurrency: "Seleziona valuta",
          currency: "Valuta",
          preferredLanguage: "Lingua preferita",
          selectLanguage: "Seleziona lingua",
          submit: "Invia",
        },
        invoiceSettings: {
          title: "Impostazioni fattura",
          name: "Nome",
          showGuis: "Mostra GUI",
          showSubtotal: "Mostra subtotale",
          showLineOfService: "Mostra linea di servizio",
          save: "Salva",
        },
      },
      privileges: {
        title: "Privilegi",
        name: "Nome",
        actions: "Azioni",
        newPrivilege: "Nuovo privilegio",
        edit: "Modifica",
        delete: "Elimina",
      },
      table: {
        page: "Pagina",
        of: "di",
        show: "Mostra",
        goToPage: "Vai alla pagina",
      },
      notifications: {
        title: "Notifiche",
        id: "ID",
        date: "Data",
        message: "Messaggio",
        type: "Tipo",
      },
    },
  },
  es: {
    translation: {
      sidebar: {
        dashboard: "Tablero",
        crm: "CRM",
        admins: "Administradores",
        tenants: "Inquilinos",
        managers: "Gerentes",
        customers: "Clientes",
        subaccounts: "Subcuentas",
        sims: "Tarjetas SIM",
        account: "Cuenta",
        profile: "Perfil",
        billingProducts: "Productos de facturación",
        invoices: "Facturas",
        statements: "Extractos",
        orders: "Pedidos",
        emails: "Correos electrónicos",
        reports: "Informes",
        generateReport: "Generar informe",
        automatedReports: "Informes automatizados",
        reportHistory: "Historial de informes",
        alerts: "Alertas",
        notifications: "Notificaciones",
        support: "Soporte",
        privileges: "Privilegios",
        settings: "Configuraciones",
        services: "Servicios",
        documentation: "Documentación",
      },
      dashboard: {
        dataVolume: "Volumen de datos",
        dataSessions: "Sesiones de datos",
        alerts: "Alertas",
        zeroSessions: "Sesiones cero",
        sinceYesterday: "Desde ayer",
        sinceLastMonth: "Desde el mes pasado",
        sinceLastYear: "Desde el año pasado",
        averageVolume: "Volumen de datos promedio",
        activeSims: "SIMs activas",
        dataVolumePerHour: "Volumen de datos por hora",
        topMetrics: "Principales métricas",
        simCardsPerOffer: "SIMs por oferta",
        simInventory: "Distribución de SIM por estado",
        dataVolumePerNetwork: "Volumen de datos por red",
        selectCountry: "Seleccionar país",
        networksDistribution: "Distribución de SIM activas por red",
        watchdogAlerts: "Alertas de consumo",
        noData: "No hay datos disponibles",
        daily: "Diario",
        monthly: "Mensual",
        yearly: "Anual",
      },
      crm: {
        admins: "Administradores",
        tenants: "Inquilinos",
        managers: "Gerentes",
        customers: "Clientes",
        subaccounts: "Subcuentas",
        filters: "Filtros",
        fullName: "Nombre completo",
        company: "Empresa",
        email: "Correo electrónico",
        address: "Dirección",
        city: "Ciudad",
        country: "País",
        postcode: "Código postal",
        telephone: "Teléfono",
        mobile: "Móvil",
        occupation: "Ocupación",
        tin: "Número de Identificación Fiscal",
        username: "Nombre de usuario",
        role: "Rol",
        actions: "Acciones",
        edit: "Editar",
        delete: "Eliminar",
        billing: "Facturación",
        whitelabeling: "Marca blanca",
        offers: "Ofertas",
        impersonate: "Suplantar",
        permissions: "Permisos",
        sendWelcomeEmail: "Enviar correo de bienvenida",
        resetPassword: "Restablecer contraseña",
        initializeSIMs: "Inicializar SIMs",
        initializeAlerts: "Inicializar alertas",
        newTenant: "Nuevo inquilino",
        newManager: "Nuevo gerente",
        newCustomer: "Nuevo cliente",
        newSubaccount: "Nueva subcuenta",
        newModal: {
          newTenant: "Crear inquilino",
          newManager: "Crear gerente",
          newCustomer: "Crear cliente",
          newSubaccount: "Crear subcuenta",
          fullName: "Nombre completo",
          address: "Dirección",
          city: "Ciudad",
          country: "País",
          postcode: "Código postal",
          telephone: "Teléfono",
          mobile: "Móvil",
          email: "Correo electrónico",
          occupation: "Ocupación",
          company: "Empresa",
          tin: "Número de Identificación Fiscal",
          username: "Nombre de usuario",
          sendWelcomeEmail: "Enviar correo de bienvenida",
          cancel: "Cancelar",
          submit: "Enviar",
          next: "Siguiente",
          previous: "Anterior",
        },
        editModal: {
          editTenant: "Editar inquilino",
          editManager: "Editar gerente",
          editCustomer: "Editar cliente",
          editSubaccount: "Editar subcuenta",
          fullName: "Nombre completo",
          address: "Dirección",
          city: "Ciudad",
          country: "País",
          postcode: "Código postal",
          telephone: "Teléfono",
          mobile: "Móvil",
          email: "Correo electrónico",
          occupation: "Ocupación",
          company: "Empresa",
          tin: "Número de Identificación Fiscal",
          username: "Nombre de usuario",
          cancel: "Cancelar",
          submit: "Enviar",
        },
        billingModal: {
          title: "Configuración de facturación",
          currency: "Moneda",
          billingFrequency: "Frecuencia de facturación",
          daysToPayInvoice: "Días para pagar la factura",
          minimumAmountToInvoice: "Monto mínimo para facturar",
          invoiceType: "Tipo de factura",
          preferredLanguage: "Idioma preferido",
          billingEmail: "Correo de facturación",
          cancel: "Cancelar",
          submit: "Enviar",
        },
        whitelabelModal: {
          title: "Configuración de marca blanca",
          enableWhitelabel: "Habilitar marca blanca",
          pricePerMonth: "Precio por mes",
          url: "URL",
          cancel: "Cancelar",
          submit: "Enviar",
        },
        roleModal: {
          title: "Actualizar rol",
          role: "Rol",
          tenant: "Inquilino",
          manager: "Gerente",
          customer: "Cliente",
          cancel: "Cancelar",
          submit: "Enviar",
        },
        offersModal: {
          title: "Asignar ofertas",
          availableOffers: "Ofertas disponibles",
          assignedOffers: "Ofertas asignadas",
          submit: "Enviar",
        },
      },
      sims: {
        title: "Tarjetas SIM",
        dataVolume: "Volumen de datos",
        dataSessions: "Sesiones de datos",
        zeroSessions: "Sesiones cero",
        filters: "Filtros",
        simStatus: "Sesión activa",
        simState: "Estado SIM",
        imsi: "IMSI",
        iccid: "ICCID",
        msisdn: "MSISDN",
        offer: "Oferta",
        data: "Datos",
        min: "Mín",
        max: "Máx",
        search: "Buscar",
        clear: "Limpiar",
        actions: "Acciones",
        bulk: "Operaciones masivas",
        modify: "Modificar",
        product: "Producto",
        clearSelection: "Limpiar selección",
        activate: "Activar",
        suspend: "Suspender",
        resume: "Reanudar",
        terminate: "Terminar",
        testMode: "Modo de prueba",
        sendSms: "Enviar SMS",
        watchdogs: "Watchdogs",
        createWatchdog: "Crear Watchdog",
        removeAllWatchdogs: "Eliminar todos los Watchdogs",
        export: "Exportar",
        simInformation: "Información SIM",
        usage: "Uso",
        ipAddress: "Dirección IP",
        imei: "IMEI",
        status: "Estado",
        visitProfile: "Visitar perfil",
        provider: "Proveedor",
        area: "Área",
        cell: "Celda",
        longitude: "Longitud",
        latitude: "Latitud",
        cellRange: "Rango de celda",
        unit: "Unidad",
        sort: "Ordenar",
        suspendModal: {
          title: "Suspender SIM",
          orderComplete: "Suspensión de SIM completada",
          orderError: "Suspensión de SIM fallida",
          contactAdmin: "Contacta al administrador del sistema",
          cancel: "Cancelar",
          submit: "Enviar",
          close: "Cerrar",
        },
        resumeModal: {
          title: "Reanudar SIM",
          orderComplete: "Reanudación de SIM completada",
          orderError: "Reanudación de SIM fallida",
          contactAdmin: "Contacta al administrador del sistema",
          cancel: "Cancelar",
          submit: "Enviar",
          close: "Cerrar",
        },
        activateModal: {
          title: "Activar SIM",
          offer: "Oferta",
          selectOffer: "Seleccionar oferta",
          success: "Activación de SIM completada",
          orderError: "Activación de SIM fallida",
          contactAdmin: "Contacta al administrador del sistema",
          cancel: "Cancelar",
          submit: "Enviar",
          close: "Cerrar",
        },
        terminateModal: {
          title: "Terminar SIM",
          targetState: "Estado deseado",
          onStock: "En stock",
          terminated: "Terminada",
          success: "Terminación de SIM completada",
          orderError: "Terminación de SIM fallida",
          contactAdmin: "Contacta al administrador del sistema",
          cancel: "Cancelar",
          submit: "Enviar",
          close: "Cerrar",
        },
      },
      simProfile: {
        simDetails: "Detalles de la SIM",
        actions: "Acciones",
        hlr: "HLR",
        session: "Sesiones",
        smsUsage: "Uso de SMS",
        updateLoc: "Actualizar ubicación",
        imeiLock: "Bloqueo de IMEI",
        usage: "Uso",
        imei: "IMEI",
        status: "Estado",
        dataSession: "Sesión activa",
        map: "Mapa",
        provider: "Proveedor",
        imsi: "IMSI",
        iccid: "ICCID",
        msisdn: "MSISDN",
        country: "País",
        operator: "Operador de red",
        area: "Área",
        cell: "Celda",
        longitude: "Longitud",
        latitude: "Latitud",
        cellRange: "Rango de celda",
        activity: "Actividad",
        selectPeriod: "Seleccionar período",
        daily: "Diario",
        monthly: "Mensual",
        yearly: "Anual",
        dataVolume: "Volumen de datos",
        dataSessions: "Sesiones de datos",
        alerts: "Alertas",
        zeroSessions: "Sesiones cero",
        watchdog: "Watchdog",
        type: "Tipo",
        amount: "Cantidad",
        hlrModal: {
          title: "Perfil HLR",
          selectScope: "Seleccionar alcance",
          basicInfo: "Información básica",
          locationInfo: "Información de ubicación",
          csBasicInfo: "Información básica de CS",
          gprsService2g3g: "Servicios GPRS 2G/3G",
          telephonyService: "Servicios de telefonía",
          supplementaryService: "Servicios complementarios",
          epcService4G: "Servicios EPC 4G",
        },
        sessionModal: {
          title: "Sesiones de datos de SIM",
          openDate: "Fecha de apertura",
          closeDate: "Fecha de cierre",
          consumption: "Consumo",
          roundedBytes: "Bytes redondeados",
          operator: "Operador",
          cell: "Celda",
          imei: "IMEI",
          close: "Cerrar",
        },
        smsUsageModal: {
          title: "Uso de SMS",
          direction: "Dirección",
          date: "Fecha",
          sender: "Remitente",
          message: "Mensaje",
          status: "Estado",
          close: "Cerrar",
        },
      },
      profile: {
        profileDetails: "Detalles del perfil",
        contactInformation: "Información de contacto",
        professionalInformation: "Información profesional",
        activities: "Actividades",
        loadMore: "Cargar más",
      },
      billingProducts: {
        title: "Productos de facturación",
        name: "Nombre",
        pricePlan: "Plan de precios",
        price: "Precio",
        number: "Número",
      },
      invoices: {
        title: "Facturas",
        invoiceNo: "Número de factura",
        date: "Fecha",
        amount: "Monto (incl. IVA)",
        description: "Descripción",
        paid: "Pagado",
        download: "Descargar",
      },
      statements: {
        title: "Extractos de cuenta",
        cutDate: "Fecha de corte",
        description: "Descripción",
        amount: "Monto (incl. IVA)",
        balance: "Saldo",
      },
      orders: {
        title: "Pedidos",
        open: "Abierto",
        parked: "Aplazado",
        finished: "Finalizado",
        error: "Error",
        noOrders: "No hay pedidos",
        showAllOrders: "Mostrar todos los pedidos",
      },
      emails: {
        filters: "Filtros",
        category: "Categoría",
        name: "Nombre",
        address: "Dirección",
        actions: "Acciones",
        newEmail: "Nuevo correo electrónico",
        edit: "Editar",
        delete: "Eliminar",
        cancel: "Cancelar",
        submit: "Enviar",
        selectCategory: "Seleccionar categoría",
        invoice: "Factura",
        watchdog: "Watchdog",
        updateEmail: "Actualizar correo electrónico",
      },
      reports: {
        title: "Informes",
        reportType: "Tipo de informe",
        usage: "Uso",
        groupBy: "Agrupar por",
        field: "Campo",
        usageType: "Tipo de uso",
        selectUsageType: "Seleccionar tipo de uso",
        data: "Datos",
        sms: "SMS",
        all: "Todos",
        imsi: "IMSI",
        network: "Red",
        period: "Período",
        day: "Día",
        month: "Mes",
        year: "Año",
        today: "Hoy",
        "This Week": "Esta semana",
        "This Month": "Este mes",
        "This Year": "Este año",
        "Last Month": "Mes pasado",
        "Last Year": "Año pasado",
        customer: "Cliente",
        select: "Seleccionar",
        simSearchCriteria: "Criterios de búsqueda de SIM",
        bySpecificImsi: "Por IMSI específica",
        byImsiRange: "Por rango de IMSI",
        byIccid: "Por ICCID",
        startImsi: "IMSI de inicio",
        endImsi: "IMSI de fin",
        iccid: "ICCID",
        view: "Ver",
        customerId: "ID del cliente",
        date: "Fecha",
        simType: "Tipo de SIM",
        networkCode: "Código de red",
        networkName: "Nombre de la red",
        country: "País",
        totalBytes: "Total de bytes",
        roundedBytes: "Bytes redondeados",
        sessions: "Sesiones",
        zeroSessions: "Sesiones cero",
        actions: "Acciones",
        export: "Exportar",
        automated: {
          title: "Informes automatizados",
          name: "Nombre",
          type: "Tipo",
          frequency: "Frecuencia",
          nextReport: "Próximo informe",
          new: "Nuevo informe automatizado",
          save: "Guardar",
          daily: "Diario",
          weekly: "Semanal",
          monthly: "Mensual",
          edit: "Editar",
          delete: "Eliminar",
          actions: "Acciones",
        },
        history: {
          title: "Historial de informes",
          tableHeaders: {
            reportName: "Nombre del informe",
            date: "Fecha",
            status: "Estado",
            download: "Descargar",
            actions: "Acciones",
          },
          delete: "Eliminar",
          completed: "Completado",
          incomplete: "Incompleto",
        },
      },
      alerts: {
        title: "Alertas",
        filters: "Filtros",
        alert: "Alerta",
        date: "Fecha",
        imsi: "IMSI",
        enterValue: "Ingrese valor",
      },
      settings: {
        title: "Configuraciones",
        profileSettings: "Configuración de perfil",
        general: "General",
        account: "Cuenta",
        profileImage: "Imagen de perfil",
        whitelabel: "Marca blanca",
        privacyAndSafety: "Privacidad y seguridad",
        notifications: "Notificaciones",
        billing: "Facturación",
        invoice: "Factura",
        generalSettings: {
          title: "Configuración general",
          timeZone: "Zona horaria",
          dateFormat: "Formato de fecha",
          timeFormat: "Formato de hora",
          save: "Guardar",
        },
        accountSettings: {
          title: "Configuración de cuenta",
          fullName: "Nombre completo",
          address: "Dirección",
          city: "Ciudad",
          country: "País",
          postcode: "Código postal",
          telephone: "Teléfono",
          mobileNumber: "Número de móvil",
          email: "Correo electrónico",
          occupation: "Ocupación",
          company: "Empresa",
          tin: "Número de Identificación Fiscal",
          username: "Nombre de usuario",
          changePassword: "Cambiar contraseña",
          password: "Contraseña",
          confirmPassword: "Confirmar contraseña",
          save: "Guardar",
        },
        profileImageSettings: {
          title: "Configuración de imagen de perfil",
          upload: "Subir",
          save: "Guardar",
        },
        whitelabelSettings: {
          title: "Configuración de marca blanca",
          backgroundColor: "Color de fondo",
          fontColor: "Color de la fuente",
          sidebarPosition: "Posición de la barra lateral",
          favicon: "Favicon",
          whitelabelImage: "Imagen de marca blanca",
          upload: "Subir",
          save: "Guardar",
        },
        privacySettings: {
          title: "Configuración de privacidad",
          enable2FA: "Habilitar 2FA",
          save: "Guardar",
        },
        notificationSettings: {
          title: "Configuración de notificaciones",
          receiveNotifications: "Recibir notificaciones",
          sms: "SMS",
          email: "Correo electrónico",
          weeklyAnalytics: "Análisis semanales",
          checkIfYouWantToReceiveWeeklyAnalytics:
            "Marque si desea recibir análisis semanales",
          notifyAboutNewAlerts: "Notificar sobre nuevas alertas",
          checkIfYouWantToReceiveNotificationsWhenThereAreNewAlerts:
            "Marque si desea recibir notificaciones cuando haya nuevas alertas",
          submit: "Enviar",
        },
        billingSettings: {
          title: "Configuración de facturación",
          selectCurrency: "Seleccionar moneda",
          currency: "Moneda",
          preferredLanguage: "Idioma preferido",
          selectLanguage: "Seleccionar idioma",
          submit: "Enviar",
        },
        invoiceSettings: {
          title: "Configuración de factura",
          name: "Nombre",
          showGuis: "Mostrar GUI",
          showSubtotal: "Mostrar subtotal",
          showLineOfService: "Mostrar línea de servicio",
          save: "Guardar",
        },
      },
      privileges: {
        title: "Privilegios",
        name: "Nombre",
        actions: "Acciones",
        newPrivilege: "Nuevo privilegio",
        edit: "Editar",
        delete: "Eliminar",
      },
      table: {
        page: "Página",
        of: "de",
        show: "Mostrar",
        goToPage: "Ir a la página",
      },
      notifications: {
        title: "Notificaciones",
        id: "ID",
        date: "Fecha",
        message: "Mensaje",
        type: "Tipo",
      },
    },
  },
  ar: {
    translation: {
      sidebar: {
        dashboard: "لوحة التحكم",
        crm: "إدارة علاقات العملاء",
        admins: "المسؤولون",
        tenants: "المستأجرون",
        managers: "المديرون",
        customers: "العملاء",
        subaccounts: "الحسابات الفرعية",
        sims: "شرائح SIM",
        account: "الحساب",
        profile: "الملف الشخصي",
        billingProducts: "منتجات الفوترة",
        invoices: "الفواتير",
        statements: "الكشوفات",
        orders: "الطلبات",
        emails: "البريد الإلكتروني",
        reports: "التقارير",
        generateReport: "إنشاء تقرير",
        automatedReports: "تقارير آلية",
        reportHistory: "سجل التقارير",
        alerts: "التنبيهات",
        notifications: "الإشعارات",
        support: "الدعم",
        privileges: "الامتيازات",
        settings: "الإعدادات",
        services: "خدمات",
        documentation: "التوثيق",
      },
      dashboard: {
        dataVolume: "حجم البيانات",
        dataSessions: "جلسات البيانات",
        alerts: "التنبيهات",
        zeroSessions: "جلسات صفرية",
        sinceYesterday: "منذ الأمس",
        sinceLastMonth: "منذ الشهر الماضي",
        sinceLastYear: "منذ العام الماضي",
        averageVolume: "متوسط حجم البيانات",
        activeSims: "شرائح SIM النشطة",
        dataVolumePerHour: "حجم البيانات لكل ساعة",
        topMetrics: "أهم المقاييس",
        simCardsPerOffer: "شرائح SIM لكل عرض",
        simInventory: "توزيع شرائح SIM حسب الحالة",
        dataVolumePerNetwork: "حجم البيانات لكل شبكة",
        selectCountry: "اختر البلد",
        networksDistribution: "توزيع شرائح SIM النشطة حسب الشبكة",
        watchdogAlerts: "تنبيهات الاستهلاك",
        noData: "لا توجد بيانات متاحة",
        daily: "يومي",
        monthly: "شهري",
        yearly: "سنوي",
      },
      crm: {
        admins: "المسؤولون",
        tenants: "المستأجرون",
        managers: "المديرون",
        customers: "العملاء",
        subaccounts: "الحسابات الفرعية",
        filters: "فلاتر",
        fullName: "الاسم الكامل",
        company: "الشركة",
        email: "البريد الإلكتروني",
        address: "العنوان",
        city: "المدينة",
        country: "البلد",
        postcode: "الرمز البريدي",
        telephone: "الهاتف",
        mobile: "الهاتف المحمول",
        occupation: "المهنة",
        tin: "الرقم الضريبي",
        username: "اسم المستخدم",
        role: "الدور",
        actions: "الإجراءات",
        edit: "تعديل",
        delete: "حذف",
        billing: "الفوترة",
        whitelabeling: "العلامة البيضاء",
        offers: "العروض",
        impersonate: "انتحال",
        permissions: "الأذونات",
        sendWelcomeEmail: "إرسال بريد الترحيب",
        resetPassword: "إعادة تعيين كلمة المرور",
        initializeSIMs: "تهيئة شرائح SIM",
        initializeAlerts: "تهيئة التنبيهات",
        newTenant: "مستأجر جديد",
        newManager: "مدير جديد",
        newCustomer: "عميل جديد",
        newSubaccount: "حساب فرعي جديد",
        newModal: {
          newTenant: "إنشاء مستأجر",
          newManager: "إنشاء مدير",
          newCustomer: "إنشاء عميل",
          newSubaccount: "إنشاء حساب فرعي",
          fullName: "الاسم الكامل",
          address: "العنوان",
          city: "المدينة",
          country: "البلد",
          postcode: "الرمز البريدي",
          telephone: "الهاتف",
          mobile: "الهاتف المحمول",
          email: "البريد الإلكتروني",
          occupation: "المهنة",
          company: "الشركة",
          tin: "الرقم الضريبي",
          username: "اسم المستخدم",
          sendWelcomeEmail: "إرسال بريد الترحيب",
          cancel: "إلغاء",
          submit: "إرسال",
          next: "التالي",
          previous: "السابق",
        },
        editModal: {
          editTenant: "تعديل المستأجر",
          editManager: "تعديل المدير",
          editCustomer: "تعديل العميل",
          editSubaccount: "تعديل الحساب الفرعي",
          fullName: "الاسم الكامل",
          address: "العنوان",
          city: "المدينة",
          country: "البلد",
          postcode: "الرمز البريدي",
          telephone: "الهاتف",
          mobile: "الهاتف المحمول",
          email: "البريد الإلكتروني",
          occupation: "المهنة",
          company: "الشركة",
          tin: "الرقم الضريبي",
          username: "اسم المستخدم",
          cancel: "إلغاء",
          submit: "إرسال",
        },
        billingModal: {
          title: "إعدادات الفوترة",
          currency: "العملة",
          billingFrequency: "تكرار الفوترة",
          daysToPayInvoice: "أيام لسداد الفاتورة",
          minimumAmountToInvoice: "الحد الأدنى للفوترة",
          invoiceType: "نوع الفاتورة",
          preferredLanguage: "اللغة المفضلة",
          billingEmail: "البريد الإلكتروني للفوترة",
          cancel: "إلغاء",
          submit: "إرسال",
        },
        whitelabelModal: {
          title: "إعدادات العلامة البيضاء",
          enableWhitelabel: "تمكين العلامة البيضاء",
          pricePerMonth: "السعر لكل شهر",
          url: "الرابط",
          cancel: "إلغاء",
          submit: "إرسال",
        },
        roleModal: {
          title: "تحديث الدور",
          role: "الدور",
          tenant: "المستأجر",
          manager: "المدير",
          customer: "العميل",
          cancel: "إلغاء",
          submit: "إرسال",
        },
        offersModal: {
          title: "تعيين العروض",
          availableOffers: "العروض المتاحة",
          assignedOffers: "العروض المعينة",
          submit: "إرسال",
        },
      },
      sims: {
        title: "شرائح SIM",
        dataVolume: "حجم البيانات",
        dataSessions: "جلسات البيانات",
        zeroSessions: "جلسات صفرية",
        filters: "فلاتر",
        simStatus: "جلسة نشطة",
        simState: "حالة SIM",
        imsi: "IMSI",
        iccid: "ICCID",
        msisdn: "MSISDN",
        offer: "العرض",
        data: "البيانات",
        min: "الحد الأدنى",
        max: "الحد الأقصى",
        search: "بحث",
        clear: "مسح",
        actions: "الإجراءات",
        bulk: "عمليات مجمعة",
        modify: "تعديل",
        product: "المنتج",
        clearSelection: "مسح التحديد",
        activate: "تفعيل",
        suspend: "تعليق",
        resume: "استئناف",
        terminate: "إنهاء",
        testMode: "وضع الاختبار",
        sendSms: "إرسال رسالة نصية",
        watchdogs: "المراقبون",
        createWatchdog: "إنشاء مراقب",
        removeAllWatchdogs: "إزالة جميع المراقبين",
        export: "تصدير",
        simInformation: "معلومات SIM",
        usage: "الاستخدام",
        ipAddress: "عنوان IP",
        imei: "IMEI",
        status: "الحالة",
        visitProfile: "زيارة الملف الشخصي",
        provider: "مزود الخدمة",
        area: "المنطقة",
        cell: "الخلية",
        longitude: "خط الطول",
        latitude: "خط العرض",
        cellRange: "نطاق الخلية",
        unit: "الوحدة",
        sort: "ترتيب",
        suspendModal: {
          title: "تعليق SIM",
          orderComplete: "تم تعليق SIM بنجاح",
          orderError: "فشل في تعليق SIM",
          contactAdmin: "يرجى الاتصال بمسؤول النظام",
          cancel: "إلغاء",
          submit: "إرسال",
          close: "إغلاق",
        },
        resumeModal: {
          title: "استئناف SIM",
          orderComplete: "تم استئناف SIM بنجاح",
          orderError: "فشل في استئناف SIM",
          contactAdmin: "يرجى الاتصال بمسؤول النظام",
          cancel: "إلغاء",
          submit: "إرسال",
          close: "إغلاق",
        },
        activateModal: {
          title: "تفعيل SIM",
          offer: "العرض",
          selectOffer: "اختر العرض",
          success: "تم تفعيل SIM بنجاح",
          orderError: "فشل في تفعيل SIM",
          contactAdmin: "يرجى الاتصال بمسؤول النظام",
          cancel: "إلغاء",
          submit: "إرسال",
          close: "إغلاق",
        },
        terminateModal: {
          title: "إنهاء SIM",
          targetState: "الحالة المطلوبة",
          onStock: "في المخزون",
          terminated: "تم إنهاؤها",
          success: "تم إنهاء SIM بنجاح",
          orderError: "فشل في إنهاء SIM",
          contactAdmin: "يرجى الاتصال بمسؤول النظام",
          cancel: "إلغاء",
          submit: "إرسال",
          close: "إغلاق",
        },
      },
      simProfile: {
        simDetails: "تفاصيل SIM",
        actions: "الإجراءات",
        hlr: "HLR",
        session: "الجلسات",
        smsUsage: "استخدام الرسائل النصية",
        updateLoc: "تحديث الموقع",
        imeiLock: "قفل IMEI",
        usage: "الاستخدام",
        imei: "IMEI",
        status: "الحالة",
        dataSession: "الجلسة النشطة",
        map: "خريطة",
        provider: "مزود الخدمة",
        imsi: "IMSI",
        iccid: "ICCID",
        msisdn: "MSISDN",
        country: "البلد",
        operator: "مشغل الشبكة",
        area: "المنطقة",
        cell: "الخلية",
        longitude: "خط الطول",
        latitude: "خط العرض",
        cellRange: "نطاق الخلية",
        activity: "النشاط",
        selectPeriod: "اختر الفترة",
        daily: "يومي",
        monthly: "شهري",
        yearly: "سنوي",
        dataVolume: "حجم البيانات",
        dataSessions: "جلسات البيانات",
        alerts: "التنبيهات",
        zeroSessions: "جلسات صفرية",
        watchdog: "المراقب",
        type: "النوع",
        amount: "الكمية",
        hlrModal: {
          title: "ملف HLR",
          selectScope: "اختر النطاق",
          basicInfo: "معلومات أساسية",
          locationInfo: "معلومات الموقع",
          csBasicInfo: "معلومات CS الأساسية",
          gprsService2g3g: "خدمات GPRS 2G/3G",
          telephonyService: "خدمات الهاتف",
          supplementaryService: "الخدمات التكميلية",
          epcService4G: "خدمات EPC 4G",
        },
        sessionModal: {
          title: "جلسات بيانات SIM",
          openDate: "تاريخ الافتتاح",
          closeDate: "تاريخ الإغلاق",
          consumption: "الاستهلاك",
          roundedBytes: "بايت مقرب",
          operator: "المشغل",
          cell: "الخلية",
          imei: "IMEI",
          close: "إغلاق",
        },
        smsUsageModal: {
          title: "استخدام الرسائل النصية",
          direction: "الاتجاه",
          date: "التاريخ",
          sender: "المرسل",
          message: "الرسالة",
          status: "الحالة",
          close: "إغلاق",
        },
      },
      profile: {
        profileDetails: "تفاصيل الملف الشخصي",
        contactInformation: "معلومات الاتصال",
        professionalInformation: "معلومات مهنية",
        activities: "الأنشطة",
        loadMore: "تحميل المزيد",
      },
      billingProducts: {
        title: "منتجات الفوترة",
        name: "الاسم",
        pricePlan: "الخطة السعرية",
        price: "السعر",
        number: "الرقم",
      },
      invoices: {
        title: "الفواتير",
        invoiceNo: "رقم الفاتورة",
        date: "التاريخ",
        amount: "المبلغ (شامل ضريبة القيمة المضافة)",
        description: "الوصف",
        paid: "مدفوع",
        download: "تحميل",
      },
      statements: {
        title: "كشف الحساب",
        cutDate: "تاريخ القطع",
        description: "الوصف",
        amount: "المبلغ (شامل ضريبة القيمة المضافة)",
        balance: "الرصيد",
      },
      orders: {
        title: "الطلبات",
        open: "مفتوح",
        parked: "مؤجل",
        finished: "مكتمل",
        error: "خطأ",
        noOrders: "لا توجد طلبات",
        showAllOrders: "عرض جميع الطلبات",
      },
      emails: {
        filters: "فلاتر",
        category: "الفئة",
        name: "الاسم",
        address: "العنوان",
        actions: "الإجراءات",
        newEmail: "بريد إلكتروني جديد",
        edit: "تعديل",
        delete: "حذف",
        cancel: "إلغاء",
        submit: "إرسال",
        selectCategory: "اختر الفئة",
        invoice: "الفاتورة",
        watchdog: "المراقب",
        updateEmail: "تحديث البريد الإلكتروني",
      },
      reports: {
        title: "التقارير",
        reportType: "نوع التقرير",
        usage: "الاستخدام",
        groupBy: "تجميع حسب",
        field: "الحقل",
        usageType: "نوع الاستخدام",
        selectUsageType: "اختر نوع الاستخدام",
        data: "البيانات",
        sms: "الرسائل النصية",
        all: "الكل",
        imsi: "IMSI",
        network: "الشبكة",
        period: "الفترة",
        day: "يوم",
        month: "شهر",
        year: "سنة",
        today: "اليوم",
        "This Week": "هذا الأسبوع",
        "This Month": "هذا الشهر",
        "This Year": "هذا العام",
        "Last Month": "الشهر الماضي",
        "Last Year": "العام الماضي",
        customer: "العميل",
        select: "اختر",
        simSearchCriteria: "معايير البحث عن SIM",
        bySpecificImsi: "حسب IMSI محددة",
        byImsiRange: "حسب نطاق IMSI",
        byIccid: "حسب ICCID",
        startImsi: "IMSI البداية",
        endImsi: "IMSI النهاية",
        iccid: "ICCID",
        view: "عرض",
        customerId: "رقم العميل",
        date: "التاريخ",
        simType: "نوع SIM",
        networkCode: "رمز الشبكة",
        networkName: "اسم الشبكة",
        country: "البلد",
        totalBytes: "إجمالي البايتات",
        roundedBytes: "بايت مقرب",
        sessions: "الجلسات",
        zeroSessions: "جلسات صفرية",
        actions: "الإجراءات",
        export: "تصدير",
        automated: {
          title: "التقارير الآلية",
          name: "الاسم",
          type: "النوع",
          frequency: "التكرار",
          nextReport: "التقرير التالي",
          new: "تقرير جديد",
          save: "حفظ",
          daily: "يومي",
          weekly: "أسبوعي",
          monthly: "شهري",
          edit: "تعديل",
          delete: "حذف",
          actions: "الإجراءات",
        },
        history: {
          title: "سجل التقارير",
          tableHeaders: {
            reportName: "اسم التقرير",
            date: "التاريخ",
            status: "الحالة",
            download: "تحميل",
            actions: "الإجراءات",
          },
          delete: "حذف",
          completed: "مكتمل",
          incomplete: "غير مكتمل",
        },
      },
      alerts: {
        title: "التنبيهات",
        filters: "فلاتر",
        alert: "التنبيه",
        date: "التاريخ",
        imsi: "IMSI",
        enterValue: "أدخل القيمة",
      },
      settings: {
        title: "الإعدادات",
        profileSettings: "إعدادات الملف الشخصي",
        general: "عام",
        account: "الحساب",
        profileImage: "صورة الملف الشخصي",
        whitelabel: "العلامة البيضاء",
        privacyAndSafety: "الخصوصية والأمان",
        notifications: "الإشعارات",
        billing: "الفوترة",
        invoice: "الفاتورة",
        generalSettings: {
          title: "الإعدادات العامة",
          timeZone: "المنطقة الزمنية",
          dateFormat: "تنسيق التاريخ",
          timeFormat: "تنسيق الوقت",
          save: "حفظ",
        },
        accountSettings: {
          title: "إعدادات الحساب",
          fullName: "الاسم الكامل",
          address: "العنوان",
          city: "المدينة",
          country: "البلد",
          postcode: "الرمز البريدي",
          telephone: "الهاتف",
          mobileNumber: "رقم الهاتف المحمول",
          email: "البريد الإلكتروني",
          occupation: "المهنة",
          company: "الشركة",
          tin: "الرقم الضريبي",
          username: "اسم المستخدم",
          changePassword: "تغيير كلمة المرور",
          password: "كلمة المرور",
          confirmPassword: "تأكيد كلمة المرور",
          save: "حفظ",
        },
        profileImageSettings: {
          title: "إعدادات صورة الملف الشخصي",
          upload: "رفع",
          save: "حفظ",
        },
        whitelabelSettings: {
          title: "إعدادات العلامة البيضاء",
          backgroundColor: "لون الخلفية",
          fontColor: "لون الخط",
          sidebarPosition: "موقع الشريط الجانبي",
          favicon: "الأيقونة المفضلة",
          whitelabelImage: "صورة العلامة البيضاء",
          upload: "رفع",
          save: "حفظ",
        },
        privacySettings: {
          title: "إعدادات الخصوصية",
          enable2FA: "تمكين المصادقة الثنائية",
          save: "حفظ",
        },
        notificationSettings: {
          title: "إعدادات الإشعارات",
          receiveNotifications: "تلقي الإشعارات",
          sms: "رسالة نصية",
          email: "البريد الإلكتروني",
          weeklyAnalytics: "تحليلات أسبوعية",
          checkIfYouWantToReceiveWeeklyAnalytics:
            "حدد إذا كنت ترغب في تلقي التحليلات الأسبوعية",
          notifyAboutNewAlerts: "إخطار حول التنبيهات الجديدة",
          checkIfYouWantToReceiveNotificationsWhenThereAreNewAlerts:
            "حدد إذا كنت ترغب في تلقي الإشعارات عند وجود تنبيهات جديدة",
          submit: "إرسال",
        },
        billingSettings: {
          title: "إعدادات الفوترة",
          selectCurrency: "اختر العملة",
          currency: "العملة",
          preferredLanguage: "اللغة المفضلة",
          selectLanguage: "اختر اللغة",
          submit: "إرسال",
        },
        invoiceSettings: {
          title: "إعدادات الفاتورة",
          name: "الاسم",
          showGuis: "عرض واجهات المستخدم",
          showSubtotal: "عرض الإجمالي الفرعي",
          showLineOfService: "عرض سطر الخدمة",
          save: "حفظ",
        },
      },
      privileges: {
        title: "الامتيازات",
        name: "الاسم",
        actions: "الإجراءات",
        newPrivilege: "امتياز جديد",
        edit: "تعديل",
        delete: "حذف",
      },
      table: {
        page: "الصفحة",
        of: "من",
        show: "عرض",
        goToPage: "الذهاب إلى الصفحة",
      },
      notifications: {
        title: "الإشعارات",
        id: "الرقم",
        date: "التاريخ",
        message: "الرسالة",
        type: "النوع",
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
