import SelectColumnFilter from "../utils/SelectColumnFilter";
import NumberRangeColumnFilter from "../utils/NumberRangeColumnFilter";
import { filter } from "d3";

const getSimData = (t) => {
  const simColumns = [
    {
      id: "status",
      Header: t("sims.simStatus"),
      accessor: "status",
      sortable: true,
      // Filter: SelectColumnFilter,
      // filter: "includes",
      // filterType: "select",
      canRenderFilter: false,
      Cell: ({ row: { original } }) => (
        <div
          className={`circle ${
            original.status === "Active"
              ? "pulse green"
              : original.status === "Suspended"
              ? "yellow"
              : original.status === "Productive"
              ? "blue"
              : original.status === "OnStock"
              ? "grey"
              : original.status === "Deleted"
              ? "red"
              : original.status === "TestProductive"
              ? "blue"
              : original.status === "AutoSuspended"
              ? "yellow"
              : null
          }`}
        ></div>
      ),
    },
    {
      Header: t("sims.imsi"),
      accessor: "imsi",
      sortable: true,
      canRenderFilter: true,
      filterType: "text",
    },
    {
      Header: t("sims.iccid"),
      accessor: "iccid",
      sortable: true,
      canRenderFilter: true,
      filterType: "text",
    },
    {
      Header: t("sims.msisdn"),
      accessor: "msisdn",
      sortable: true,
      Cell: ({ value }) => (value ? value : "N/A"),
      canRenderFilter: true,
      filterType: "text",
    },
    {
      id: "state",
      Header: t("sims.simState"),
      accessor: "state",
      sortable: true,
      Filter: SelectColumnFilter,
      filter: "includes",
      filterType: "select",
      canRenderFilter: true,
    },
    {
      Header: t("sims.offer"),
      accessor: "offer.name", // Access the name property directly
      sortable: true,
      canRenderFilter: true,
      filterType: "text",
      Cell: ({ value }) => (value ? value : "N/A"),
    },
    {
      Header: t("sims.data"),
      accessor: "data",
      sortable: true,
      Filter: NumberRangeColumnFilter,
      filter: "between",
      canRenderFilter: true,
      filterType: "between",
      Cell: ({ row: { original } }) => (
        <>
          {original.totalMBytes === null
            ? ""
            : `${parseFloat(original.totalMBytes).toFixed(2)}MB`}
        </>
      ),
    },
    {
      id: "sim_type",
      Header: t("sims.simType"),
      accessor: "simType",
      sortable: true,
      canRenderFilter: true,
      filterType: "select",
      filter: "includes", // Ensures multi-value filtering
      canRenderFilter: true,
      getOptions: () => [
        { value: "NextM2M SIM T2", label: t("sim.nextm2m_t2") },
        { value: "Jersey Embedded Sim", label: t("sim.jersey_embedded_sim") },
        { value: "NextM2M SIM T3", label: t("sim.nextm2m_t3") },
        { value: "IoT SIM Card Industrial", label: t("sim.sim_industrial") },
        { value: "IoT SIM Chip Industrial", label: t("sim.sim_business") },
      ],
    },
  ];

  return { simColumns };
};

export default getSimData;
