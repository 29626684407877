import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const NewDiscount = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.name && errors.name)}
        />
        {!!touched.name && (
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          type="text"
          name="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.description && errors.description)}
        />
        {!!touched.description && (
          <Form.Control.Feedback type="invalid">
            {errors.description}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Percentage</Form.Label>
        <Form.Control
          type="text"
          name="percentage"
          value={values.percentage}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.percentage && errors.percentage)}
        />
        {!!touched.percentage && (
          <Form.Control.Feedback type="invalid">
            {errors.percentage}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Row className="mb-3">
        <Form.Label>Binding Period</Form.Label>
        <Form.Group as={Col} lg={6} md={12}>
          <Form.Control
            type="text"
            name="bindingPeriod.amount"
            value={values.bindingPeriod.amount}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(
              touched.bindingPeriod?.amount && errors.bindingPeriod?.amount
            )}
          />
          {!!touched.bindingPeriod?.amount && (
            <Form.Control.Feedback type="invalid">
              {errors.bindingPeriod?.amount}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group as={Col} lg={6} md={12}>
          <Form.Select
            name="bindingPeriod.period"
            value={values.bindingPeriod.period}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(
              touched.bindingPeriod?.period && errors.bindingPeriod?.period
            )}
          >
            <option value="">Select Period Type</option>
            <option value="day">Days</option>
            <option value="month">Months</option>
          </Form.Select>
          {!!touched.bindingPeriod?.period && (
            <Form.Control.Feedback type="invalid">
              {errors.bindingPeriod?.period}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Row>
    </>
  );
};

export default NewDiscount;
