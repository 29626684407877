import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWhitelabel } from "../../../contexts/WhitelabelContext";
import NotyfContext from "../../../contexts/NotyfContext";
import * as Yup from "yup";
import { Button, Form, Modal, Offcanvas } from "react-bootstrap";
import useWindowSize from "../../../hooks/useWindowSize";
import { Formik } from "formik";
import { createService } from "../../../redux/slices/services";

const NewServiceModal = ({ showNewServiceModal, setShowNewServiceModal }) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const { backgroundColor, fontColor } = useWhitelabel();
  const notyf = useContext(NotyfContext);
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  const [internalShow, setInternalShow] = useState(showNewServiceModal);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (showNewServiceModal && !isDesktop) {
      setFadeOut(false);
      setInternalShow(true);
    }
  }, [showNewServiceModal]);

  const handleHide = () => {
    setFadeOut(true);
    setTimeout(() => {
      setInternalShow(false);
      setShowNewServiceModal(false);
    }, 300);
  };

  const initialValues = {
    type: "",
    name: "",
    unitType: "",
    groupTag: "",
    description: "",
  };

  const validation = Yup.object().shape({
    type: Yup.string().required("Type is required"),
    name: Yup.string().required("Name is required"),
    unitType: Yup.string().required("Unit Type is required"),
    groupTag: Yup.string().nullable().notRequired(),
    description: Yup.string().nullable().notRequired(),
  });

  if (isDesktop) {
    return (
      <Modal
        show={showNewServiceModal}
        centered
        backdrop="static"
        scrollable
        size="lg"
        onHide={() => setShowNewServiceModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>New Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, { setValues }) => {
              await dispatch(createService({ data: values }))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Service created successfully",
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  setShowNewServiceModal(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setValues,
            }) => {
              console.log(errors);
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Type</Form.Label>
                    <Form.Select
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(touched.type && errors.type)}
                    >
                      <option value="">Select Service Type</option>
                      <option value="Zone">Zone</option>
                      <option value="System">System</option>
                      <option value="Sms">SMS</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(touched.name && errors.name)}
                    />
                    {!!touched.name && (
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Group Tag</Form.Label>
                    <Form.Control
                      name="groupTag"
                      value={values.groupTag}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(touched.groupTag && errors.groupTag)}
                    />
                    {!!touched.groupTag && (
                      <Form.Control.Feedback type="invalid">
                        {errors.groupTag}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(
                        touched.description && errors.description
                      )}
                    />
                    {!!touched.description && (
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Unit Type</Form.Label>
                    <Form.Select
                      name="unitType"
                      value={values.unitType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(touched.unitType && errors.unitType)}
                    >
                      <option value="">Select Unit Type</option>
                      {values.type === "Zone" && (
                        <>
                          <option value="byte">byte</option>
                          <option value="kb">kb</option>
                          <option value="5 kb">5 kb</option>
                          <option value="10 kb">10 kb</option>
                          <option value="20 kb">20 kb</option>
                          <option value="25 kb">25 kb</option>
                          <option value="30 kb">30 kb</option>
                          <option value="40 kb">40 kb</option>
                          <option value="50 kb">50 kb</option>
                          <option value="60 kb">60 kb</option>
                          <option value="70 kb">70 kb</option>
                          <option value="100 kb">100 kb</option>
                          <option value="mb">MB</option>
                          <option value="GB">GB</option>
                        </>
                      )}
                      {values.type !== "Zone" && (
                        <option value="unit">Unit</option>
                      )}
                    </Form.Select>
                  </Form.Group>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      style={{
                        "--dynamic-bg-color": backgroundColor,
                        "--dynamic-font-color": fontColor,
                        border: "none",
                      }}
                      onClick={() => {
                        setValues(initialValues);
                        setShowNewServiceModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      style={{
                        "--dynamic-bg-color": backgroundColor,
                        "--dynamic-font-color": fontColor,
                        border: "none",
                      }}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  } else {
    return (
      <Offcanvas
        show={internalShow}
        placement="bottom"
        backdrop="static"
        scrollable
        className={`fullscreen-offcanvas ${fadeOut ? "fade-out" : ""}`}
        onHide={handleHide}
      >
        <Offcanvas.Header closeButton className="fullscreen-offcanvas-header">
          <Offcanvas.Title>New Service</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="fullscreen-offcanvas-body">
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, { setValues }) => {
              await dispatch(createService({ data: values }))
                .then(() => {
                  notyf.open({
                    type: "success",
                    message: "Service created successfully",
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .catch((error) => {
                  notyf.open({
                    type: "danger",
                    message: error,
                    duration: 5000,
                    ripple: true,
                    dismissible: false,
                    position: {
                      x: "center",
                      y: "top",
                    },
                  });
                })
                .finally(() => {
                  setShowNewServiceModal(false);
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setValues,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Type</Form.Label>
                    <Form.Select
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(touched.type && errors.type)}
                    >
                      <option value="">Select Service Type</option>
                      <option value="Zone">Zone</option>
                      <option value="System">System</option>
                      <option value="Sms">SMS</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(touched.name && errors.name)}
                    />
                    {!!touched.name && (
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Group Tag</Form.Label>
                    <Form.Control
                      name="groupTag"
                      value={values.groupTag}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(touched.groupTag && errors.groupTag)}
                    />
                    {!!touched.groupTag && (
                      <Form.Control.Feedback type="invalid">
                        {errors.groupTag}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(
                        touched.description && errors.description
                      )}
                    />
                    {!!touched.description && (
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <div className="d-flex justify-content-center flex-column mt-3">
                    <Button
                      type="submit"
                      className="mb-3"
                      variant="primary"
                      style={{
                        "--dynamic-bg-color": backgroundColor,
                        "--dynamic-font-color": fontColor,
                        border: "none",
                        padding: "10px",
                      }}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="primary"
                      style={{
                        "--dynamic-bg-color": backgroundColor,
                        "--dynamic-font-color": fontColor,
                        border: "none",
                        padding: "10px",
                      }}
                      onClick={() => {
                        setValues(initialValues);
                        setShowNewServiceModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
};

export default NewServiceModal;
